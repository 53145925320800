import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import { isNullOrUndefined } from "util";
import CustomSnackbar from "./components/Bars/CustomSnackbar";
import { StatusCodeEnum, StatusCodeMessageEnum } from "./literals/Enums";
import { VariantType } from "./literals/Types";
import { IReducers } from "./redux";
import AuthActions from "./redux/AuthRedux";
import ErrorActions from "./redux/StatusRedux";
import indexRoutes from "./routes";
import { debugLog } from "./services/LogService";

interface IAppProps {
  statusCode: StatusCodeEnum | null;
  statusMessage: StatusCodeMessageEnum | null;
  statusVariant: VariantType | null;

  clearStatus: () => void;
  signOut: () => void;
}
class App extends Component<IAppProps> {

  public componentDidUpdate() {
    const { statusCode, statusMessage, signOut } = this.props;
    debugLog({name: "App", preview: "CompDidUpdate", values: { statusCode, statusMessage }});
    if (statusCode === StatusCodeEnum.UNAUTHNUM) {
      signOut();
    }
  }

  public render() {
    const { statusMessage, statusVariant } = this.props;
    const displaySnackbar = !isNullOrUndefined(statusMessage);
    debugLog({name: "App", preview: "render", statusMessage});
    return (
      <ParallaxProvider>
        <BrowserRouter>
          <Switch>
            {indexRoutes.map((prop, key) => {
                return <Route exact path={prop.path} key={key} component={prop.component} />;
            })}
            <Route component={this.Page404} />
          </Switch>
        </BrowserRouter>
        <div>
          <CustomSnackbar
            isOpen={displaySnackbar}
            message={this.props.statusMessage || "Something went wrong"}
            variant={statusVariant}
            onClose={this.onSnackbarClose}
          />
        </div>
      </ParallaxProvider>
    );
  }

  private Page404 = ({ location }: any) => (
    <div>
      <h2>Oops! Unable to find that location <code>{location.pathname}</code></h2>
      <a href="/">Return home</a>
    </div>
  )

  private onSnackbarClose = (reason: string) => {
    debugLog({name: "App", preview: "onSnackbarClose", values: {reason, props: this.props}});
    if (reason === "clickaway") {
      return;
    }
    this.props.clearStatus();
  }
}

const mapStateToProps = (state: IReducers) => ({
  statusCode: state.status.statusCode,
  statusMessage: state.status.statusMessage,
  statusVariant: state.status.variant,
});
const mapDispatchToProps = (dispatch: any) => ({
  clearStatus: () => (dispatch(ErrorActions.clearStatus())),
  signOut: () => (dispatch(AuthActions.signOut()))
});
export default connect(mapStateToProps, mapDispatchToProps)(App);

import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "clsx";
import * as H from "history";
import React from "react";
import ReactDOM from "react-dom";
import { GoogleMap } from "react-google-maps";
import Helmet from "react-helmet";
import { Fade } from "react-reveal";
import Parallax from "../../components/Cards/Banner";
import Footer from "../../components/Footer";
import GridContainer from "../../components/Grids/GridContainer";
import GridItem from "../../components/Grids/GridItem";
import Header from "../../components/Header";
import HeaderLinks from "../../components/Header/HeaderLinks";
import { debugLog } from "../../services/LogService";
import AppScreens from "../../themes/img/AppScreens.png";
import landingBg from "../../themes/img/bg8.png";
import GooglePlay from "../../themes/img/google-play-badge.png";
import withRoot from "../../WithRoot";
import HowAppWorks from "./sections/HowAppWorks";
import HowRentSpaceWorks from "./sections/HowRentSpaceWorks";
import LocateUsSection from "./sections/LocateUsSection";
import Media from "./sections/Media";
import Partners from "./sections/Partners";
import PricingSection from "./sections/Pricing";
import Testimonials from "./sections/Testimonials";
import landingPageStyle from "./styles/LandingPageStyle";

interface ILandingPageProps {
  classes?: any;
  history?: H.History;
  location?: H.Location;
  innerRef?: React.Ref<any> | React.RefObject<any>;
}
class LandingPage extends React.Component<ILandingPageProps> {
  private howItWorksRef: React.RefObject<LandingPage> = React.createRef<LandingPage>();
  private rentSpaceRef: React.RefObject<LandingPage> = React.createRef<LandingPage>();
  private locateUsRef: React.RefObject<LandingPage> = React.createRef<LandingPage>();
  private pricingRef: React.RefObject<LandingPage> = React.createRef<LandingPage>();
  private mediaRef: React.RefObject<LandingPage> = React.createRef<LandingPage>();
  private testimonialRef: React.RefObject<LandingPage> = React.createRef<LandingPage>();
  private locateUsMapRefs: { [key: string]: React.ReactInstance | GoogleMap } = {};

  public componentDidMount() {
    if (this.props.location && this.props.location.hash) {
      debugLog({class: "Landing", method: "didUpdate", props: this.props});
      this.scrollToHowItWorks();
    }
  }

  public render() {
    const { classes } = this.props;
    const imageClasses = classNames(classes.imgFluid, classes.imgRounded);
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>WannaPark | Valet parking at your favorite location</title>
          <meta property="og:title" content={"WannaPark | Valet parking at your favorite location"} />
          <meta property="og:description" content={`Tired looking for a free parking spot? We have a perfect
          solution for your day-to-day problem. Our platform provides most simple and seamless
          process to help you park your car at most buzzing locations in your city.`} />
          <meta property="og:type" content={"video:conference"} />
          <meta property="og:url" content={window.location.origin} />
        </Helmet>
        <Header
          color={"transparent"}
          brand="WannaPark"
          rightLinks={
            <HeaderLinks
              howItWorksScroll={this.scrollToHowItWorks}
              locateUsScroll={this.scrollToLocateUs}
              pricingScroll={this.scrollToPricing}
              rentSpaceScroll={this.scrollToRentSpace}
              mediaScroll={this.scrollToMedia}
              testimonialScroll={this.scrollToTestimonial}
              showTrackMyCar
              isLandingPage
            />}
          fixed
          changeColorOnScroll={{
            color: "white",
            height: 400,
          }}
        />
        <Parallax filter image={landingBg}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <h1 className={classes.title}>Parking Made Simple With Us.</h1>
                <h4>
                Tired looking for a free parking spot?
                We have a perfect solution for your day-to-day problem. Our platform provides most simple and seamless
                process to help you park your car at most buzzing locations in your city.
                </h4>
                <br />
                  <a style={{display: "table-cell"}}
                    href="https://play.google.com/store/apps/details?id=com.wannapark.client"
                    target="_blank"
                    rel="noopener noreferrer">
                      <img src={GooglePlay} alt="GoolgePlayStore" width={194} height={75} className={imageClasses} />
                    </a>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} style={{textAlign: "center"}}>
                <Fade right>
                  <img src={AppScreens} alt="..." width={308} height={345} className={imageClasses} />
                </Fade>
              </GridItem>
              {/* <GridItem xs={12} sm={12} md={6} style={{justifyContent: "center", textAlign: "center"}}>
                <Button
                  size="large"
                  buttonColor="brand"
                  href={LinksEnum.trackCarHome}
                >
                  Track My Car
                </Button>
              </GridItem> */}
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main)}>
          <div className={classes.container} id="divHowItWorks">
            <div ref={this.howItWorksRef as any}>
              <HowAppWorks />
            </div>
            <div ref={this.locateUsRef as any}>
              <LocateUsSection onMapMounted={this.onLocateUsMapMounted}/>
            </div>
            <div ref={this.pricingRef as any}>
              <PricingSection />
            </div>
            <div ref={this.rentSpaceRef as any}>
              <Partners />
            </div>
            <div ref={this.mediaRef as any}>
              <Media />
            </div>
            <div ref={this.testimonialRef as any}>
              <Testimonials />
            </div>
            <div ref={this.rentSpaceRef as any}>
              <HowRentSpaceWorks />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  private onLocateUsMapMounted = (ref: GoogleMap) => {
    this.locateUsMapRefs.map = ref;
  }

  private scrollToHowItWorks = () => {
    if (this.howItWorksRef && this.howItWorksRef.current) {
      const myDomNode = ReactDOM.findDOMNode(this.howItWorksRef.current);
      if (myDomNode) {
        (myDomNode as any).scrollIntoView();
        if (window.history && window.history.pushState) {
          window.history.pushState("", "How it works", "/#howitworks");
        }
      }
    }
  }

  private scrollToLocateUs = () => {
    if (this.locateUsRef && this.locateUsRef.current) {
      const myDomNode = ReactDOM.findDOMNode(this.locateUsRef.current);
      if (myDomNode) {
        (myDomNode as any).scrollIntoView();
        if (window.history && window.history.pushState) {
          window.history.pushState("", "Locate Us", "/#locateus");
        }
      }
    }
  }

  private scrollToPricing = () => {
    if (this.pricingRef && this.pricingRef.current) {
      const myDomNode = ReactDOM.findDOMNode(this.pricingRef.current);
      if (myDomNode) {
        (myDomNode as any).scrollIntoView();
        if (window.history && window.history.pushState) {
          window.history.pushState("", "Pricing", "/#pricing");
        }
      }
    }
  }

  private scrollToMedia = () => {
    if (this.mediaRef && this.mediaRef.current) {
      const myDomNode = ReactDOM.findDOMNode(this.mediaRef.current);
      if (myDomNode) {
        (myDomNode as any).scrollIntoView();
        if (window.history && window.history.pushState) {
          window.history.pushState("", "Media", "/#media");
        }
      }
    }
  }

  private scrollToTestimonial = () => {
    if (this.testimonialRef && this.testimonialRef.current) {
      const myDomNode = ReactDOM.findDOMNode(this.testimonialRef.current);
      if (myDomNode) {
        (myDomNode as any).scrollIntoView();
        if (window.history && window.history.pushState) {
          window.history.pushState("", "Testimonial", "/#testimonial");
        }
      }
    }
  }

  private scrollToRentSpace = () => {
    if (this.rentSpaceRef && this.rentSpaceRef.current) {
      const myDomNode = ReactDOM.findDOMNode(this.rentSpaceRef.current);
      if (myDomNode) {
        (myDomNode as any).scrollIntoView();
        if (window.history && window.history.pushState) {
          window.history.pushState("", "Rent your space", "/#rentyourspace");
        }
      }
    }
  }
}

export default withRoot(withStyles(landingPageStyle as any)(LandingPage));

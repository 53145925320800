import * as React from "react";
import { GoogleMap, GoogleMapProps, Marker, withGoogleMap, withScriptjs } from "react-google-maps";
import CarMarker from "./markers/CarMarker";

interface IMapComponentProps {
  center?: google.maps.LatLng | google.maps.LatLngLiteral;
  markerCoord?: google.maps.LatLng | google.maps.LatLngLiteral;
  markerType: "default" | "car";
  zoom?: number;

  onMapMounted: (ref: GoogleMap) => void;
}

const component = withScriptjs(withGoogleMap((props: GoogleMapProps & IMapComponentProps) => (
    <GoogleMap
      ref={props.onMapMounted}
      defaultZoom={props.zoom || 14}
      defaultOptions={props.defaultOptions}
      center={props.center}
      onClick={props.onClick}
    >
      {
        props.markerType === "car" ?
          <CarMarker key={"carMarker"} position={props.markerCoord} animation={google.maps.Animation.DROP}/>
        : <Marker key={"defaultMarker"} position={props.markerCoord} animation={google.maps.Animation.DROP} />
      }
    </GoogleMap>
  )
));

export default component;

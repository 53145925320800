import { cardSubtitle, defaultFont, grayColor, title } from "../../../themes/jss/material-kit-react";
import customCheckboxRadioSwitch from "../../../themes/jss/material-kit-react/customCheckboxRadioSwitch";
import modalStyle from "../../../themes/jss/material-kit-react/modalStyle";

const addSpaceStyle = {
  ...customCheckboxRadioSwitch,
  ...modalStyle,
  description: {
    color: grayColor + "!important",
    textAlign: "center"
  },
  group: {
    display: "inline-block"
  },
  legendLabel: {
    ...defaultFont,
    color: "#212121 !important",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "1.42857",
    top: "10px",
  },
  section: {
    padding: "70px 0"
  },
  subTitle: {
    ...cardSubtitle,
    color: "rgba(0, 0, 0, 0.56)",
    marginBottom: "12px",
    marginTop: "12px",
    minHeight: "32px",
    textAlign: "center",
    textDecoration: "none",
  },
  textArea: {
    marginLeft: "15px",
    marginRight: "15px",
  },
  textCenter: {
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "50px",
    marginTop: "30px",
    minHeight: "32px",
    textAlign: "center",
    textDecoration: "none",
  },
};

export default addSpaceStyle;

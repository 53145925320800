import { withStyles } from "@material-ui/core";
import React from "react";
import { LinksEnum } from "../../../../literals/Enums";
import withRoot from "../../../../WithRoot";
import termsAndConditionsPageStyle from "../../styles/TermsAndConditionsStyle";

interface IUserCancellationProps {
  classes?: any;
}
class UserCancellationPage extends React.Component<IUserCancellationProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4 className={classes.title}>7. Cancel a Booking</h4>
        <p className={classes.description}>
          (a) If you wish to cancel a Booking, you agree to comply with the terms of the
          <a href={LinksEnum.cancellationPolicy}> Cancellation Policy </a> published on our Site
          and as amended from time-to-time.
        </p>
        <p className={classes.description}>
          (b) You agree that if an Owner wishes to cancel a Booking they will do so by contacting you direct.
        </p>
        <p className={classes.description}>
          (c) For the avoidance of doubt, it is the Owner’s responsibility to make any refund you are entitled
          to under the <a href={LinksEnum.cancellationPolicy}> Cancellation Policy</a>.
        </p>
        <p className={classes.description}>
          (d) If your Booking is cancelled, this Agreement (in so far as it relates to that Booking)
          will automatically terminate.
        </p>
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(UserCancellationPage as any));

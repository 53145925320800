import { Card, CardContent, ListItemIcon } from "@material-ui/core";
import { green, grey } from "@material-ui/core/colors";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ReturnInitiateIcon from "@material-ui/icons/Chat";
import ParkingIcon from "@material-ui/icons/LocalParking";
import ReturnIcon from "@material-ui/icons/Redo";
import DropOffIcon from "@material-ui/icons/VpnKey";
import { withStyles } from "@material-ui/styles";
import React from "react";
import { IServiceOrder } from "../../../interfaces/ServiceOrderInterface";
import { TaskEnum, TaskStatusEnum } from "../../../literals/Enums";
import withRoot from "../../../WithRoot";
import statusSummaryStyle from "../styles/StatusSummaryStyle";

interface IStatusSummaryProps {
  classes: any;
  detail: IServiceOrder;
}
const StatusSummary: React.FC<IStatusSummaryProps> = (props: IStatusSummaryProps) => {
  const { classes, detail } = props;
  const isParked = detail.TaskType === TaskEnum.Parking || detail.TaskType === TaskEnum.Return;
  const isReturnInitiated = detail.TaskType === TaskEnum.Return;
  const isReturnStarted = detail.TaskType === TaskEnum.Return
                            && detail.TaskStatus === TaskStatusEnum.ReturnStarted;
  return (
    <div className={classes.container}>
      <Card className={classes.card} key="statusSummaryCard">
        <CardContent key="statusSummaryCardContent">
          <List disablePadding key="statusSummaryList">
            <ListItem className={classes.listItem} key={"dropOffItem"}>
              <ListItemIcon><DropOffIcon style={{color: green[700]}}/></ListItemIcon>
              <ListItemText primary={`${detail.CarRegistration} is dropped off at ${detail.DropOffName}`} />
            </ListItem>
            <ListItem className={classes.listItem} key={"parkingItem"}>
              <ListItemIcon>
                <ParkingIcon
                  style={{color: isParked ? green[700] : grey[700]}}
                />
              </ListItemIcon>
              <ListItemText primary={isParked
                  ? `Parked your car at ${detail.ParkingName}`
                  : `On the way to parking`
              } />
            </ListItem>
            {
              isParked &&
              <ListItem className={classes.listItem} key={"returnInitiatedItem"}>
                <ListItemIcon><ReturnInitiateIcon
                  style={{color: isReturnInitiated ? green[700] : grey[700]}} />
                </ListItemIcon>
                <ListItemText primary={isReturnInitiated
                  ? `We have received your Return request`
                  : `Scroll below to raise Return request for your car...`
                } />
              </ListItem>
            }
            {
              isReturnInitiated &&
              <ListItem className={classes.listItem} key={"returnEnrouteItem"}>
                <ListItemIcon><ReturnIcon
                  style={{color: isReturnStarted ? green[700] : grey[700]}} />
                </ListItemIcon>
                <ListItemText primary={isReturnStarted
                  ? `${detail.ReturnDriverName || "Valet"} is on his way to ${detail.ReturnName}`
                  : "Driver will be assigned shortly"
                } />
              </ListItem>
            }
          </List>
        </CardContent>
      </Card>
    </div>
  );
};

export default withRoot(withStyles(statusSummaryStyle as any)(StatusSummary));

import { all, spawn, takeLatest } from "redux-saga/effects";

/* ------------- Types ------------- */
import { AuthReduxTypes } from "../redux/AuthRedux";
import { GoBumprLeadReduxTypes } from "../redux/GoBumprLeadRedux";
import { RentSpaceReduxTypes } from "../redux/RentSpaceRedux";
import { TrackCarReduxTypes } from "../redux/TrackCarRedux";

/* ------------- Sagas ------------- */
import { verifyPhoneNumber } from "./AuthSaga";
import { saveGoBumprLead } from "./GoBumprLeadSaga";
import { getRentRequest, saveRentRequest } from "./RentSpaceSaga";
import { startupRehydrate } from "./StartupSaga";
import { trackCar, trackCarByUid, trackCarLocationChannel, unTrackCar } from "./TrackCarSaga";

import * as AuthService from "../services/FirebaseAuthService";
import * as RentRequestService from "../services/RentRequestService";

/* ------------- Connect Types To Sagas ------------- */

export default function * root() {
  const sagaIndex = [
    takeLatest(("persist/REHYDRATE" as any), startupRehydrate),
    takeLatest(AuthReduxTypes.CONFIRM_OTP, verifyPhoneNumber, AuthService),
    takeLatest(RentSpaceReduxTypes.GET_RENT_REQUEST, getRentRequest, RentRequestService),
    takeLatest(RentSpaceReduxTypes.SAVE_RENT_REQUEST, saveRentRequest, RentRequestService),
    takeLatest(TrackCarReduxTypes.TRACK_CAR, trackCar),
    takeLatest(TrackCarReduxTypes.TRACK_CAR_BY_UID, trackCarByUid),
    takeLatest(TrackCarReduxTypes.UN_TRACK_CAR, unTrackCar),
    takeLatest(GoBumprLeadReduxTypes.SAVE_LEAD, saveGoBumprLead, RentRequestService),
    spawn(trackCarLocationChannel)
  ];
  yield all(sagaIndex);
}

import { withStyles } from "@material-ui/styles";
import classNames from "clsx";
import React from "react";
import ModalImage from "react-modal-image";
import GridContainer from "../../../components/Grids/GridContainer";
import GridItem from "../../../components/Grids/GridItem";
import MylaporeTalk from "../../../themes/img/MylaporeTalk.jpg";
import MylaporeTimes from "../../../themes/img/MylaporeTimes.png";
import sectionStyle from "../styles/PricingStyle";

interface IMediaProps {
  classes?: any;
}
const Media: React.FC<IMediaProps> = (props: IMediaProps) => {
  const { classes } = props;
  return (
    <div className={classNames(classes.main)}>
      <div className={classes.container}>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <h2 className={classes.title}>In Media</h2>
              <h5 className={classes.title}>
                What does Media say about us
              </h5>
            </GridItem>
          </GridContainer>
          <div>
            <GridContainer style={{justifyContent: "center"}}>
              <GridItem xs={12} sm={12} md={4}>
                <div className={classes.infoArea}>
                  <div className={classes.sectionWrapper}>
                    <ModalImage
                      className={classes.imageModal}
                      small={MylaporeTalk}
                      large={MylaporeTalk}
                      alt="Mylapore Talk"
                      width={350} height={300}
                    />
                  </div>
                  <h5 className={classes.title}>
                    Mylapore Talk
                  </h5>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <div className={classes.infoArea}>
                  <div className={classes.sectionWrapper}>
                    {// tslint:disable-next-line: max-line-length
                      <a href="http://www.mylaporetimes.com/2020/01/wannaparks-valet-parking-service-becomes-popular-in-mylapore/"  target="_blank" rel="noopener noreferrer">
                        <img src={MylaporeTimes} alt="Mylapore Times"
                          style={{cursor: "pointer", maxWidth: "100%", maxHeight: "100%"}}
                        />
                      </a>
                    }
                  </div>
                  <h5 className={classes.title}>
                    Mylapore Times
                  </h5>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(sectionStyle as any)(Media);

// tslint:disable: object-literal-sort-keys
// tslint:disable: object-literal-key-quotes

import {
  dangerColor,
  defaultFont,
  primaryColor,
  successColor
} from "../../../themes/jss/material-kit-react";

const customInputStyle = {
  disabled: {
    "&:before": {
      borderColor: "transparent !important"
    }
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#8c8c8c !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: primaryColor
    }
  },
  underlineError: {
    "&:after": {
      borderColor: dangerColor
    }
  },
  underlineSuccess: {
    "&:after": {
      borderColor: successColor
    }
  },
  whiteUnderline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#FFFFFF"
    },
    "&:after": {
      borderColor: "#FFFFFF"
    }
  },
  helperTextRoot: {
    ...defaultFont,
    color: "rgba(0, 0, 0, 0.84)",
    margin: "0",
    fontSize: "0.75rem",
    fontWeight: "400",
    textAlign: "left",
    marginTop: "8px",
    minHeight: "1em",
    lineHeight: "1em"
  },
  helperTextRootError: {
    color: dangerColor + " !important"
  },
  helperTextRootSuccess: {
    color: successColor + " !important"
  },
  labelRoot: {
    ...defaultFont,
    color: "#212121 !important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "1.42857",
    top: "10px",
    "& + $underline": {
      marginTop: "0px"
    }
  },
  labelRootError: {
    color: dangerColor + " !important"
  },
  labelRootSuccess: {
    color: successColor + " !important"
  },
  formControl: {
    margin: "0 0 17px 0",
    paddingTop: "27px",
    position: "relative",
    "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
      color: "#495057"
    }
  },
  input: {
    color: "#495057",
    "&,&::placeholder": {
      fontSize: "14px",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: "400",
      lineHeight: "1.42857",
      opacity: "1"
    },
    "&::placeholder": {
      color: "#AAAAAA"
    }
  },
  whiteInput: {
    "&,&::placeholder": {
      color: "#FFFFFF",
      opacity: "1"
    }
  }
};

export default customInputStyle;

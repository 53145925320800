const billSummaryStyle = (theme: any) => ({
  card: {
    maxWidth: 420,
  },
  container: {
    boxSizing: "border-box",
    color: "#FFFFFF",
    marginBottom: "16px",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "480px",
    paddingLeft: "15px",
    paddingRight: "15px",
    width: "100%",
    zIndex: "12",
  },
  listItem: {
    padding: theme.spacing(1, 0),
  },
  title: {
    marginTop: theme.spacing(2),
  },
  total: {
    fontWeight: "700",
  },
  totalDiscount: {
    color: theme.palette.success.dark,
    fontWeight: "700",
  },
});

export default billSummaryStyle;

import { Checkbox, Dialog, DialogContent, FormControl, FormControlLabel, FormHelperText, FormLabel,
  InputAdornment, Radio, RadioGroup } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Check } from "@material-ui/icons";
import * as H from "history";
import { isNil } from "ramda";
import React from "react";
import { GoogleMap } from "react-google-maps";
import SearchBox from "react-google-maps/lib/components/places/SearchBox";
import Button from "../../../components/Button";
import CustomInput from "../../../components/CustomInput";
import GridContainer from "../../../components/Grids/GridContainer";
import GridItem from "../../../components/Grids/GridItem";
import { IMarker } from "../../../interfaces/MapMarkerInterface";
import { IRentSpaceRequest } from "../../../interfaces/RentSpaceInterface";
import { LinksEnum } from "../../../literals/Enums";
import { PropertyRightsType } from "../../../literals/Types";
import { isAddressValid, isNameValid, isOwnerTypeValid, isParkingSpaceValid,
  isPhoneNumberValid,  isRentRequestValid } from "../../../services/ValidationService";
import rentRequestStyle from "../styles/RentYourSpaceStyle";
import MapSection from "./MapSection";

interface IRentYourSpaceProps {
  classes: any;
  history?: H.History;
  isRequestSubmitted: boolean;
  isRequestUploading: boolean;

  saveRentRequest: (data: IRentSpaceRequest) => void;
}
interface IRentRequestState {
  bounds?: google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral;
  center?: google.maps.LatLng | google.maps.LatLngLiteral;
  isValidPhone: boolean;
  markerCoord?: google.maps.LatLng | google.maps.LatLngLiteral;
  places: Array<google.maps.places.PlaceResult | google.maps.GeocoderResult>;
  savingRequest: boolean;
  submitAttempted: boolean;
  requestForm: IRentSpaceRequest;
}
class RentRequestSection extends React.Component<IRentYourSpaceProps, IRentRequestState> {
  private mapRefs: { [key: string]: React.ReactInstance | GoogleMap | SearchBox } = {};

  constructor(props: IRentYourSpaceProps) {
    super(props);

    this.state = {
      center: {
        lat: 12.9760, lng: 80.2212 // Velachery Coords
      },
      isValidPhone: false,
      places: [],
      requestForm: {
        Address: null,
        Location: null,
        Name: "",
        ParkingSpaces: null,
        PhoneNumber: null,
        PropertyRights: null,
        Status: "new",
      },
      savingRequest: false,
      submitAttempted: false,
    };
  }

  public componentWillReceiveProps(newProps: IRentYourSpaceProps) {
    this.setState({
      savingRequest: newProps.isRequestUploading,
    });

    if (this.state.submitAttempted && newProps.isRequestSubmitted) {
      if (newProps.history) {
        newProps.history.push(LinksEnum.home);
      }
      // tslint:disable-next-line:max-line-length
      alert("Thank you for showing interest to partnet with us. We will get back to you within 48 hours for further information");
    } else if (this.state.submitAttempted && isRentRequestValid(this.state.requestForm) &&
               this.props.isRequestUploading && !newProps.isRequestUploading && !newProps.isRequestSubmitted) {
      // tslint:disable-next-line:max-line-length
      alert(`Sorry we have found a technical issue while taking your request.Please try submitting your request again else reach to us @ 89399 95878`);
    }
  }

  public render() {
    const { classes } = this.props;
    const invalidAddress = this.state.submitAttempted && !isAddressValid(this.state.requestForm);
    const invalidPhoneNumber = this.state.submitAttempted && !isPhoneNumberValid(this.state.requestForm);
    const invalidOwnerName = this.state.submitAttempted && !isNameValid(this.state.requestForm);
    const invalidOwnerType = this.state.submitAttempted && !isOwnerTypeValid(this.state.requestForm);
    const invalidParkingSpace = this.state.submitAttempted && !isParkingSpaceValid(this.state.requestForm);

    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem cs={12} sm={12} md={8}>
            <h2 className={classes.title}>Add your space</h2>
            <h4 className={classes.description}>
              Fill in few quick details below and then sit back while we take care of listing your space and
              find suitable drivers.
            </h4>
            <form>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h3 className={classes.subTitle}>Locate</h3>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Address of your parking space*"
                    id="locate"
                    error={invalidAddress}
                    inputProps={{
                      color: "brand",
                      value: this.state.requestForm.Address || ""
                    }}
                    helperText={`Click on the location of the parking space in the map to Auto Populate the address.`}
                    formControlProps={{
                      error: invalidAddress,
                      fullWidth: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <MapSection
                    bounds={this.state.bounds}
                    center={this.state.center}
                    markerCoord={this.state.markerCoord}
                    onClick={this.onMapClick}
                    onMapMounted={this.onMapMounted}
                    onPlacesChanged={this.onPlacesChanged}
                    onSearchBoxMounted={this.onSearchBoxMounted}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <h3 className={classes.subTitle}>Features</h3>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Number of spaces*"
                    id="ParkingSpaces"
                    error={invalidParkingSpace}
                    inputProps={{
                      color: "brand",
                      onChange: (event: any) => this.setState({
                        requestForm: {...this.state.requestForm, ParkingSpaces: parseInt(event.target.value, 10)}
                      }),
                      value: this.state.requestForm.ParkingSpaces
                              ? this.state.requestForm.ParkingSpaces.toString() : ""
                    }}
                    formControlProps={{
                      error: invalidParkingSpace,
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormLabel component="legend" className={classes.legendLabel}>Amenities</FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.onChangeAmenities("cctv")}
                        checked={
                            this.state.requestForm && !isNil(this.state.requestForm.Amenities)
                            && this.state.requestForm.Amenities.indexOf("cctv") !== -1 ? true : false
                        }
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{ checked: classes.checked }}
                      />
                    }
                    classes={{ label: classes.label }}
                    label="CCTV"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.onChangeAmenities("covered")}
                        checked={
                          this.state.requestForm && !isNil(this.state.requestForm.Amenities)
                            && this.state.requestForm.Amenities.indexOf("covered") !== -1 ? true : false
                        }
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{ checked: classes.checked }}
                      />
                    }
                    classes={{ label: classes.label }}
                    label="Covered"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.onChangeAmenities("fenced")}
                        checked={
                          this.state.requestForm && !isNil(this.state.requestForm.Amenities)
                            && this.state.requestForm.Amenities.indexOf("fenced") !== -1 ? true : false
                        }
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{ checked: classes.checked }}
                      />
                    }
                    classes={{ label: classes.label }}
                    label="Fenced"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <h3 className={classes.subTitle}>About you</h3>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Name*"
                    id="Name"
                    error={invalidOwnerName}
                    inputProps={{
                      onChange: (event: any) => this.setState({
                        requestForm: {
                          ...this.state.requestForm,
                          Name: event.target.value
                        }
                      }),
                      value: this.state.requestForm.Name || ""
                    }}
                    helperText={this.state.submitAttempted && !isNameValid(this.state.requestForm)
                                 ? `Please provide name to address you` : ""}
                    formControlProps={{
                      error: invalidOwnerName,
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Phone*"
                    id="Phone"
                    error={invalidPhoneNumber}
                    helperText={this.getPhoneNumberHelpertext()}
                    inputProps={{
                      // endAdornment: (
                      //   <InputAdornment position="end">
                      //   {!this.state.phoneVerified &&
                      //     <Button
                      //       id="btnVerifyPhoneNo"
                      //       color="primary"
                      //       disabled={!isPhoneNumberValid(this.state.requestForm)}
                      //       size="sm"
                      //       onClick={this.onVerifyPhoneNumber}
                      //     >
                      //       Verify
                      //     </Button>
                      //     || <span>Verified</span>
                      //   }
                      //   </InputAdornment>
                      // ),
                      onChange: (event: any) => this.setState({
                                                                requestForm: {
                                                                  ...this.state.requestForm,
                                                                  PhoneNumber: parseInt(event.target.value, 10)
                                                                }
                                                              }),
                      startAdornment: (<InputAdornment position="start">(+91)</InputAdornment>),
                      value: this.state.requestForm.PhoneNumber || ""
                    }}
                    formControlProps={{
                      error: invalidPhoneNumber,
                      fullWidth: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl error={invalidOwnerType}>
                    <FormLabel component="legend" className={classes.legendLabel}>I am*..</FormLabel>
                    <RadioGroup
                      aria-label="PropertyRights"
                      name="propertyRights"
                      className={classes.group}
                      value={this.state.requestForm.PropertyRights || undefined}
                      onChange={this.onChangeOwnerType}
                    >
                      <FormControlLabel
                        value="owner"
                        control={<Radio />}
                        label="Land Owner"
                        classes={{ label: classes.label }}
                      />
                      <FormControlLabel
                        value="tenant"
                        control={<Radio />}
                        label="Tenant"
                        classes={{ label: classes.label }}
                      />
                    </RadioGroup>
                    { invalidOwnerType &&
                      <FormHelperText>Please select an option</FormHelperText>
                    }
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Dialog
                    classes={{
                      paper: classes.modal,
                      root: classes.center,
                    }}
                    open={this.state.savingRequest}
                    // TransitionComponent={(props) => <Slide direction="down" {...props} />}
                    keepMounted
                    disableBackdropClick
                    disableEscapeKeyDown
                    aria-describedby="classic-modal-slide-description"
                  >
                    <DialogContent
                      id="classic-modal-slide-description"
                      className={classes.modalBody}
                    >
                      <p>
                        Please wait while we verify your details...
                      </p>
                    </DialogContent>
                  </Dialog>
                </GridItem>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={12} className={classes.textCenter}>
                    <Button buttonColor="brand" onClick={this.onSubmit}>Send Request</Button>
                  </GridItem>
                </GridContainer>
              </GridContainer>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  private getPhoneNumberHelpertext = () => {
    const text = `This is to help us reach out to you for more info.
    Please be assured that the number will not be shared to anyone.`;

    return text;
  }

  private onChangeAmenities = (selectedAmenity: "cctv" | "covered" | "fenced") => {
    const Amenities = this.state.requestForm && !isNil(this.state.requestForm.Amenities)
                        ? this.state.requestForm.Amenities : [];
    const index = this.state.requestForm && !isNil(Amenities) ? Amenities.indexOf(selectedAmenity) : -1;
    if (index < 0) {
      Amenities.push(selectedAmenity);
    } else {
      Amenities.splice(index, 1);
    }
    this.setState({ requestForm: {
      ...this.state.requestForm,
      Amenities
    }
    });
  }

  private onChangeOwnerType = (evt: React.ChangeEvent<{}>, val: string) => {
    this.setState({ requestForm: {
      ...this.state.requestForm,
      PropertyRights: val as PropertyRightsType
    } });
  }

  private onMapClick = (e: google.maps.MouseEvent | google.maps.IconMouseEvent) =>
  setTimeout(() => {
    const geocoder = new google.maps.Geocoder();
    const latLng = {lat: e.latLng.lat(), lng: e.latLng.lng()};
    geocoder.geocode({location: latLng}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        let Address = "";
        if (results.length > 0) {
          Address = results[0].formatted_address;
        }
        this.setState({center: latLng, markerCoord: latLng, places: results,
          requestForm: {
            ...this.state.requestForm,
            Address,
            Location: {latitude: e.latLng.lat(), longitude: e.latLng.lng()}
          }
        });
      }
    });
  },         100)

  private onMapMounted = (ref: GoogleMap) => {
    this.mapRefs.map = ref;
  }

  private onPlacesChanged = () => {
    const places: google.maps.places.PlaceResult[] = (this.mapRefs.searchBox as SearchBox).getPlaces();
    const bounds = new google.maps.LatLngBounds();

    places.forEach((place: google.maps.places.PlaceResult) => {
      if (place.geometry) {
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      }
    });
    const nextMarkers = places.map((place: google.maps.places.PlaceResult) => {
      if (place.geometry) {
        return { position: place.geometry.location };
      }
      return {};
    }) as IMarker[];
    const nextCenter = nextMarkers.length > 0 ? nextMarkers[0].position : this.state.center;

    this.setState({
      center: nextCenter,
      places
    });
    (this.mapRefs.map as GoogleMap).fitBounds(bounds);
  }

  private onSearchBoxMounted = (ref: SearchBox) => {
    this.mapRefs.searchBox = ref;
  }

  private onSubmit = () => {
    if (isRentRequestValid(this.state.requestForm)) {
      this.setState({submitAttempted: true, savingRequest: true});
      this.props.saveRentRequest(this.state.requestForm);
    } else {
      this.setState({submitAttempted: true, savingRequest: false});
      alert("Please fill in all mandatory details before submitting");
    }
  }
}

export default withStyles(rentRequestStyle as any)(RentRequestSection as any);

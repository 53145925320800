import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "clsx";
import * as React from "react";
import cardBodyStyle from "./styles/cardBodyStyle";

// tslint:disable-next-line:typedef
function CardBody({ ...props }) {
  const { classes, className, children, ...rest } = props;
  const cardBodyClasses = classNames({
    [classes.cardBody]: true,
    [className]: className !== undefined
  });
  return (
    <div className={cardBodyClasses} {...rest}>
      {children}
    </div>
  );
}

export default withStyles(cardBodyStyle)(CardBody);

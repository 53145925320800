import { persistCombineReducers } from "redux-persist";
import ReduxPersist from "../config/ReduxPersistConfig";
import rootSaga from "../sagas";
import { IAuthReduxState } from "./AuthRedux";
import configureStore from "./CreateStore";
import { IGoBumprLeadReduxState } from "./GoBumprLeadRedux";
import { IRentSpaceReduxState } from "./RentSpaceRedux";
import { IStatusReduxState } from "./StatusRedux";
import { ITrackCarReduxState } from "./TrackCarRedux";

/* ------------- Assemble Persit Combine Reducer ------------- */
const config = ReduxPersist.storeConfig;

export interface IReducers {
  auth: IAuthReduxState;
  campaignLead: IGoBumprLeadReduxState;
  rent: IRentSpaceReduxState;
  status: IStatusReduxState;
  trackCar: ITrackCarReduxState;
}
export const reducers = persistCombineReducers<IReducers>(config, {
  auth: require("./AuthRedux").reducer,
  campaignLead: require("./GoBumprLeadRedux").reducer,
  rent: require("./RentSpaceRedux").reducer,
  status: require("./StatusRedux").reducer,
  trackCar: require("./TrackCarRedux").reducer,
});

export default () => {
  // tslint:disable-next-line:prefer-const
  let { store, sagasManager, sagaMiddleware } = configureStore(reducers, rootSaga);

  // @ts-ignore: module.hot not available in NodeModule declaration
  if (module.hot) {
    // @ts-ignore: module.hot not available in NodeModule declaration
    module.hot.accept(() => {
      const nextRootReducer = require("./").reducers;
      store.replaceReducer(nextRootReducer);

      const newYieldedSagas = require("../sagas").default;
      sagasManager.cancel();
      sagasManager.toPromise().then(() => {
        sagasManager = sagaMiddleware.run(newYieldedSagas);
      });
    });
  }

  return store;
};

import { withStyles } from "@material-ui/core";
import React from "react";
import withRoot from "../../../../WithRoot";
import termsAndConditionsPageStyle from "../../styles/TermsAndConditionsStyle";

interface IOwnerTermsProps {
  classes?: any;
}
class OwnerTermsPage extends React.Component<IOwnerTermsProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4 className={classes.title}>1. Owner Terms and Conditions</h4>
          <p className={classes.description}>
            (a) These Terms apply to the provision by WannaPark Technologies Private Limited (“us”, “we” or
            “WannaPark.in”) of a platform to allow any organisation which owns a parking space (“Parking Space”)
            or has the legal authority or is authorised or has permission to advertise and let out a
            parking space (collectively “the Owner”). The term “you” or “yours” refers to the person accessing or
            using the Site or the Service.
          </p>
          <p className={classes.description}>
            (b) You may only advertise and let out a Parking Space using the Site if you are a company or other
            body corporate, partnership or individual acting for the purpose of, or in the course of your business,
            trade, craft or profession.
          </p>
          <p className={classes.description}>
            (c) For the purpose of this Agreement “the User” shall mean any person or business or a company or any
            other body corporate who has:
              <p className={classes.subDescription}>
              (i) at any time made a Booking (as defined in clause 3 of these Terms) of your Parking Space using
              the Service; or
              </p>
              <p className={classes.subDescription}>
              (ii) at any time made an enquiry about your Parking Space through WannaPark.in (whether or not such person
              completed a Booking); or
              </p>
              <p className={classes.subDescription}>
              (iii) has become aware of you or your address or the address or location of the Parking Space directly or
              indirectly as a result of your advert with us; or
              </p>
              <p className={classes.subDescription}>
              (iv) has made you aware of their need for parking through the Service provided by WannaPark.in.
              </p>
            </p>
            <p className={classes.description}>
              (d) These Terms do not apply to the use by the User of the Parking Space itself.
            </p>
          <p className={classes.description}>
            (e) These terms and conditions will apply at any time when you use this website ("Website") or any other
            method of accessing the Services (including via the WannaPark application). By continuing to use the Website
            or the Services you accept these terms and conditions and they will apply to the agreement between you and
            us (the "Agreement").
          </p>
          <p className={classes.description}>
            (f) We may make changes to the Terms or format of the Site, the Service provided or to the Site’s content at
            any time without notice. Any such amendments shall be effective once the revised terms have been posted on
            the Site. Your continued use of the Service or the Site constitutes your acceptance of the new terms.
            You may terminate this Agreement if you do not wish to be bound by any such amendments but by continuing
            to use the Site or Service you will be deemed to have accepted the new terms.
          </p>
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(OwnerTermsPage as any));

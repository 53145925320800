import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import * as React from "react";

const style = {
  grid: {
    marginLeft: "-15px",
    marginRight: "-15px",
    width: "auto"
  }
};

// tslint:disable-next-line:typedef
function GridContainer({ ...props }) {
  const { classes, children, className, ...rest } = props;
  return (
    <Grid container {...rest} className={classes.grid + " " + className}>
      {children}
    </Grid>
  );
}

export default withStyles(style)(GridContainer);

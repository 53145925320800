import withStyles from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import * as React from "react";

const parallaxStyle = {
  filter: {
    "&:after,&:before": {
      content: "''",
      display: "block",
      height: "100%",
      left: "0",
      position: "absolute",
      top: "0",
      width: "100%",
      zIndex: "1",
    },
    "&:before": {
      background: "rgba(0, 0, 0, 0.5)"
    },
  },
  parallax: {
    alignItems: "center",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    border: "0",
    display: "flex",
    height: "90vh",
    margin: "0",
    maxHeight: "660px",
    overflow: "hidden",
    padding: "0",
    position: "relative",
  },
  small: {
    height: "380px"
  }
};

interface IBannerProps {
  children?: React.ReactNode;
  classes?: any;
  className?: string;
  filter: boolean;
  small?: any;
  style?: any;
  image: string;
}
class Banner extends React.Component<IBannerProps> {
  constructor(props: IBannerProps) {
    super(props);
    const windowScrollTop = window.pageYOffset / 3;
    this.state = {
      transform: "translate3d(0," + windowScrollTop + "px,0)"
    };
    this.resetTransform = this.resetTransform.bind(this);
  }

  public componentDidMount() {
    const windowScrollTop = window.pageYOffset / 3;
    this.setState({
      transform: "translate3d(0," + windowScrollTop + "px,0)"
    });
    window.addEventListener("scroll", this.resetTransform);
  }

  public componentWillUnmount() {
    window.removeEventListener("scroll", this.resetTransform);
  }

  public render() {
    const {
      classes,
      filter,
      children,
      style,
      image,
      small
    } = this.props;
    const parallaxClasses = clsx({
      [classes.parallax]: true,
      [classes.filter]: filter,
      [classes.small]: small,
    });
    return (
      <div
        className={parallaxClasses}
        style={{
          ...style,
          backgroundImage: "url(" + image + ")",
          ...this.state
        }}
      >
        {children}
      </div>
    );
  }

  private resetTransform() {
    const windowScrollTop = window.pageYOffset / 3;
    this.setState({
      transform: "translate3d(0," + windowScrollTop + "px,0)"
    });
  }
}

export default withStyles(parallaxStyle as any)(Banner);

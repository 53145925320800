import * as React from "react";
import Header from "../../components/Header";
import HeaderLinks from "../../components/Header/HeaderLinks";
import withRoot from "../../WithRoot";

interface IMainLayoutProps {
  classes: any;
  headerColor?: "primary"| "info" | "success" | "warning" | "danger" | "transparent" | "white" | "rose" | "dark";
  headerScrollHeight?: number;
  showHelp: boolean;
  showOffers: boolean;
  showTrackMyCar: boolean;

  howItWorksScroll?: () => void;
  rentSpaceScroll?: () => void;
}
class MainLayout extends React.Component<IMainLayoutProps> {
  public render() {
    const { ...rest } = this.props;
    const { howItWorksScroll, rentSpaceScroll, showHelp, showOffers, showTrackMyCar } = this.props;
    return (
      <Header
        color={this.props.headerColor || "transparent"}
        brand="WannaPark"
        rightLinks={<HeaderLinks
            howItWorksScroll={howItWorksScroll}
            rentSpaceScroll={rentSpaceScroll}
            showHelp={showHelp}
            showTrackMyCar={showTrackMyCar}
            showOffers={showOffers}
            />}
        fixed
        changeColorOnScroll={{
          color: "white",
          height: this.props.headerScrollHeight || 400,
        }}
        {...rest}
      />
    );
  }
}

export default withRoot(MainLayout);

import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";
import { IServiceOrder } from "../interfaces/ServiceOrderInterface";

const { Types, Creators } = createActions({
  setIsLoading: ["isLoading"],
  setIsTracking: ["isTracking"],
  setServiceOrder: ["serviceOrder"],
  setSnapshotTracker: ["snapshotTracker"],
  setTrackingError: ["trackingError"],
  trackCar: ["orderUid"],
  trackCarByUid: ["orderUidShort"],
  unTrackCar: null,
});

export const TrackCarReduxTypes = Types;
export default Creators;

export interface ITrackCarReduxState {
  isLoading: boolean;
  isTracking: boolean;
  serviceOrder: IServiceOrder | null;
  trackingError: Error | null;

  snapshotTracker: () => void | undefined;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  isLoading: false,
  isTracking: false,
  serviceOrder: null,
  trackingError: null,
} as ITrackCarReduxState);

/* ------------- Reducers ------------- */
export const setServiceOrder = (state: Immutable.ImmutableObject<ITrackCarReduxState>,
                                { serviceOrder }: {serviceOrder: IServiceOrder | null}) => (
  state.merge({ serviceOrder })
);

export const setIsLoading = (state: Immutable.ImmutableObject<ITrackCarReduxState>,
                             { isLoading }: {isLoading: boolean}) => (
  state.merge({ isLoading })
);

export const setIsTracking = (state: Immutable.ImmutableObject<ITrackCarReduxState>,
                              { isTracking }: {isTracking: boolean}) => (
  state.merge({ isTracking })
);

export const setTrackingError = (state: Immutable.ImmutableObject<ITrackCarReduxState>,
                                 { trackingError }: {trackingError: Error | null}) => (
  state.merge({ trackingError })
);

export const setSnapshotTracker = (state: Immutable.ImmutableObject<ITrackCarReduxState>,
                                   { snapshotTracker }: {snapshotTracker: () => void | undefined}) => (
  state.merge({ snapshotTracker })
);

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<Immutable.ImmutableObject<ITrackCarReduxState>, any>(INITIAL_STATE, {
  [Types.SET_IS_LOADING]: setIsLoading,
  [Types.SET_IS_TRACKING]: setIsTracking,
  [Types.SET_SERVICE_ORDER]: setServiceOrder,
  [Types.SET_SNAPSHOT_TRACKER]: setSnapshotTracker,
  [Types.SET_TRACKING_ERROR]: setTrackingError,
});

import { GraphQLClient } from "graphql-request";
import config from "../config/AppConfig";
import { getRentRequestQuery, saveGoBumprLeadMutation, saveRentRequestMutation } from "../graphql/Queries";
import { IGoBumprLead } from "../interfaces/GoBumprPackageInterface";
import { IRentSpaceRequest } from "../interfaces/RentSpaceInterface";
import * as FirebaseAuthService from "./FirebaseAuthService";
import { debugLog } from "./LogService";

const client = new GraphQLClient(config.url);

export const GetRentRequest = async () => {
  return client.request(getRentRequestQuery);
};

export const SaveGoBumprLead = async (data: IGoBumprLead) => {
  const tokenStr = await FirebaseAuthService.getAuthToken();
  const variable = {
    data,
  };
  debugLog({k: "SaveGoBumprLead", data});
  client.setHeader("Authorization", `Bearer ${tokenStr}`);
  return client.request(saveGoBumprLeadMutation, variable);
};

export const SaveRentRequest = async (data: IRentSpaceRequest) => {
  const variable = {
    data,
  };
  debugLog({k: "SaveRentRequest", data});
  return client.request(saveRentRequestMutation, variable);
};

import { withStyles } from "@material-ui/core";
import React from "react";
import withRoot from "../../../../WithRoot";
import termsAndConditionsPageStyle from "../../styles/TermsAndConditionsStyle";

interface IRestrictionsProps {
  classes?: any;
}
class RestrictionsPage extends React.Component<IRestrictionsProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4 className={classes.title}>13. Restrictions on you</h4>
        <p className={classes.description}>
          (a) Whilst your Parking Space is advertised on the Site you shall not licence or rent
          the Parking Space in return for payment to:
          <p className={classes.subDescription}>
          (i) any User who has a Booking to use the Parking Space or who made a Booking in the
          previous 12 months through our Site (whether or not such Booking was cancelled or ); or
          </p>
          <p className={classes.subDescription}>
          (ii) any other person registered to use our Site as a User.
          </p>
        </p>
        <p className={classes.description}>
          (b) For a period of 3 months following the last date when your Parking Space is advertised
          on our Site, you shall not licence or rent the Parking Space in return for payment to:
          <p className={classes.subDescription}>
            (i) any User who made a Booking in the 12 months immediately prior to the date of the
            last Booking of your Parking Space through our Site (whether or not such Booking
            was cancelled); or
          </p>
          <p className={classes.subDescription}>
            (ii) any other person registered to use our Site as a User at any time when your
            Parking Space was advertised on our Site.
          </p>
        </p>
        <p className={classes.description}>
          (c) If you licence or rent the Parking Space in breach of this clause 13, you shall be liable to us
          for an amount equal to all Booking Fees that would have been payable to us had the Parking Space
          been licensed through our Site.
        </p>
        <p className={classes.description}>
          (d) This clause 13 shall survive termination of this Agreement.
        </p>
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(RestrictionsPage as any));

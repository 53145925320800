import { withStyles } from "@material-ui/styles";
import classNames from "clsx";
import React from "react";
import { Slide } from "react-slideshow-image";
import GridContainer from "../../../components/Grids/GridContainer";
import GridItem from "../../../components/Grids/GridItem";
import { debugLog } from "../../../services/LogService";
import sectionStyle from "../styles/PricingStyle";

interface ITestimonialsProps {
  classes?: any;
}
const Testimonials: React.FC<ITestimonialsProps> = (props: ITestimonialsProps) => {
  const { classes } = props;
  const properties = {
    arrows: true,
    duration: 5000,
    indicators: true,
    infinite: true,
    transitionDuration: 500,

    onChange: (oldIndex: any, newIndex: any) => {
      debugLog(`slide transition from ${oldIndex} to ${newIndex}`);
    }
  };
  return (
    <div className={classNames(classes.main)}>
      <div className={classes.container}>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <h2 className={classes.title}>Our Testimonials</h2>
              <h5 className={classes.title}>
                What customers say about us
              </h5>
            </GridItem>
          </GridContainer>
          <div className={classes.slidingContainer}>
            <Slide {...properties}>
              <div className={classes.sectionWrapper}>
                {
                  // tslint:disable-next-line: max-line-length
                  <iframe title="testimonial1" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Frevathy.kumar.71%2Fposts%2F2514036021978662&width=500" width="500" height="220" style={{border: "none", overflow: "hidden"}} scrolling="no" allowTransparency={true} allow="encrypted-media"></iframe>
                }
              </div>
              <div className={classes.sectionWrapper}>
                {
                  // tslint:disable-next-line: max-line-length
                  <iframe title="testimonial2" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpooventhand%2Fposts%2F10220879073347454&width=500" width="500" height="290" style={{border: "none", overflow: "hidden"}} scrolling="no" allowTransparency={true} allow="encrypted-media"></iframe>
                }
              </div>
              <div className={classes.sectionWrapper}>
                {
                  // tslint:disable-next-line: max-line-length
                  <iframe title="testimonial3" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fsundararaman.chintamani%2Fposts%2F10221483126489645&width=500" width="500" height="199" style={{border: "none", overflow: "hidden"}} scrolling="no" allowTransparency={true} allow="encrypted-media"></iframe>
                }
              </div>
            </Slide>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(sectionStyle as any)(Testimonials);

import { withStyles } from "@material-ui/core";
import React from "react";
import withRoot from "../../../../WithRoot";
import termsAndConditionsPageStyle from "../../styles/TermsAndConditionsStyle";

interface IOwnerResponsibilityProps {
  classes?: any;
}
class OwnerResponsibilityPage extends React.Component<IOwnerResponsibilityProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4 className={classes.title}>4. Owner's Responsibility</h4>
        <p className={classes.description}>
        (a) We are an advertising platform and payment collection service for the Owner
        and by making a Booking you are entering into an agreement with the Owner under which the
        Owner is bound to provide you with the Parking Space.
        </p>
        <p className={classes.description}>
        (b) Unless stated otherwise in these Terms, once we have confirmed your Booking we have no
        further obligation to you in relation to the Parking Space or your
        Booking and all responsibility lies with the Owner.
        </p>
        <p className={classes.description}>
        (c) We will have no liability to you if the Parking Space advertised by the Owner
        does not meet your requirements.  Your right of action or claim in such circumstances
        will be against the Owner.
        </p>
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(OwnerResponsibilityPage as any));

import { withStyles } from "@material-ui/core";
import React from "react";
import withRoot from "../../../../WithRoot";
import termsAndConditionsPageStyle from "../../styles/TermsAndConditionsStyle";

interface IUserGeneralProps {
  classes?: any;
}
class UserGeneralPage extends React.Component<IUserGeneralProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4 className={classes.title}>13. General</h4>
        <p className={classes.description}>
          (a) Each of the parties warrants its power to enter into the Agreement and has obtained all
          necessary approvals to do so.
        </p>
        <p className={classes.description}>
          (b) By entering into this Agreement you also agree to our Privacy Policy which is available on the Site.
        </p>
        <p className={classes.description}>
          (c) Any notice to be served on either of the parties by the other shall be sent by email.
        </p>
        <p className={classes.description}>
          (d) We will be entitled to assign or sub-contract our obligations under this Agreement.
        </p>
        <p className={classes.description}>
          (e) Neither party will be liable or responsible for any failure to perform, or delay in performance of,
          any of our obligations under this agreement by a Force Majeure Event. A Force Majeure Event means any act
          or event beyond the relevant party’s reasonable control, including without limitation strikes,
          lock-outs or other industrial action by third parties, civil commotion, riot, invasion, terrorist attack
          or threat of terrorist attack, war (whether declared or not) or threat or preparation for war, fire,
          explosion, storm, flood, earthquake, subsidence, epidemic or other natural disaster, or failure of public
          or private telecommunications networks or impossibility of the use of railways, shipping, aircraft,
          motor transport or other means of public or private transport.
        </p>
        <p className={classes.description}>
          (f) Where a Force Majeure Event affects the performance of a party’s obligations under these Terms,
          that party shall notify the other as soon as possible, and time for performance of that party’s obligations
          shall be extended for the duration of the Force Majeure Event.
        </p>
        <p className={classes.description}>
          (g) We intend to rely upon these Terms, the Privacy Policy and the Site Terms of Use and any document
          expressly referred to in them as forming the entire agreement between us. No other oral or written
          representations not contained within those documents will form part of the agreement between us.
          If you require any changes or additions to any of the documents, please make sure you ask for these
          to be put in writing. This can help avoid any problems about what you expect from us and
          what we expect from you.
        </p>
        <p className={classes.description}>
          (h) If any provision of this Agreement is prohibited by law or judged by a court to be unlawful,
          void or unenforceable, the provision shall, to the extent required, be severed from this Agreement and
          rendered ineffective as far as possible without modifying the remaining provisions of this Agreement,
          and shall not in any way affect any other circumstances of or the validity or enforcement of this Agreement.
        </p>
        <p className={classes.description}>
          (i) No delay, neglect or forbearance on the part of either party in enforcing against the other party
          any term or condition of this Agreement will either be or be deemed to be a waiver or in any way prejudice
          any right of that party under the Terms of this Agreement.
        </p>
        <p className={classes.description}>
          (j) Headings contained in this Agreement are for reference purposes only.
        </p>
        <p className={classes.description}>
          (k) We may change these Terms from time to time and you agree to examine and re-read them each time
          you make a Booking.
        </p>
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(UserGeneralPage as any));

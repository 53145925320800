import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "clsx";
import * as React from "react";
import cardHeaderStyle from "./styles/cardHeaderStyle";

// tslint:disable-next-line:typedef
function CardHeader({ ...props }) {
  const { classes, className, children, color, plain, ...rest } = props;
  const cardHeaderClasses = classNames({
    [classes.cardHeader]: true,
    [classes[color + "CardHeader"]]: color,
    [classes.cardHeaderPlain]: plain,
    [className]: className !== undefined
  });
  return (
    <div className={cardHeaderClasses} {...rest}>
      {children}
    </div>
  );
}

// CardHeader.propTypes = {
//   classes: PropTypes.object.isRequired,
//   className: PropTypes.string,
//   color: PropTypes.oneOf(['warning', 'success', 'danger', 'info', 'primary']),
//   plain: PropTypes.bool
// };

export default withStyles(cardHeaderStyle)(CardHeader);

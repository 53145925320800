
const getRentRequestQuery = `
query GetRentRequest {
  rentRequests {
    Address
    Amenities
    Location { latitude longitude }
    Name
    PropertyRights
    ParkingSpaces
    PhoneNumber
    Status
  }
}
`;

const saveGoBumprLeadMutation = `
mutation SaveGoBumprLead($data: iGoBumprLead!) {
  saveGoBumprLead(data: $data) {
    Uid
  }
}
`;

const saveRentRequestMutation = `
mutation SaveRentRequest($data: iRentRequest!) {
  saveRentRequest(data: $data) {
    Uid
  }
}
`;

export {
  getRentRequestQuery,
  saveGoBumprLeadMutation,
  saveRentRequestMutation,
};

import { defaultFont } from "../../../themes/jss/material-kit-react";
import tooltip from "../../../themes/jss/material-kit-react/tooltipsStyle";

const headerLinksStyle = (theme: any) => ({
  dropdownLink: {
    "&,&:hover,&:focus": {
      color: "inherit",
      display: "block",
      padding: "10px 20px",
      textDecoration: "none",
    }
  },
  icons: {
    height: "20px",
    marginRight: "3px",
    width: "20px",
  },
  list: {
    ...defaultFont,
    color: "inherit",
    fontSize: "14px",
    listStyle: "none",
    margin: 0,
    paddingBottom: "0",
    paddingLeft: "0",
    paddingTop: "0",
  },
  listItem: {
    float: "left",
    // tslint:disable-next-line: object-literal-sort-keys
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      "&:after": {
        backgroundColor: "#e5e5e5",
        content: '""',
        display: "block",
        height: "1px",
        marginLeft: "15px",
        width: "calc(100% - 30px)",
      },
      "width": "100%",
    }
  },
  listItemText: {
    padding: "0 !important"
  },
  marginRight5: {
    marginRight: "5px"
  },
  navLink: {
    "&:hover,&:focus": {
      background: "rgba(200, 200, 200, 0.2)",
      color: "inherit",
    },
    "borderRadius": "3px",
    "color": "inherit",
    "display": "inline-flex",
    "fontSize": "12px",
    "fontWeight": "400",
    "lineHeight": "20px",
    "margin": "0px",
    "padding": "0.9375rem",
    "position": "relative",
    "textDecoration": "none",
    "textTransform": "uppercase",
    [theme.breakpoints.down("sm")]: {
      "& > span:first-child": {
        justifyContent: "flex-start"
      },
      "marginBottom": "8px",
      "marginLeft": "15px",
      "marginTop": "8px",
      "textAlign": "left",
      "width": "calc(100% - 30px)",
    }
  },
  navLinkActive: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    color: "inherit",
  },
  notificationNavLink: {
    color: "inherit",
    display: "inline-flex",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "20px",
    margin: "0px",
    padding: "0.9375rem",
    textDecoration: "none",
    textTransform: "uppercase",
    top: "4px"
  },
  registerNavLink: {
    display: "inline-flex",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "20px",
    margin: "0px",
    position: "relative",
    textDecoration: "none",
    textTransform: "uppercase",
    top: "3px",
  },
  socialIcons: {
    fontSize: "20px !important",
    marginRight: "4px",
    position: "relative",
  },
  ...tooltip,
});

export default headerLinksStyle;

import { Container, LinearProgress, List, ListItem, ListItemText } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Call, SmsSharp } from "@material-ui/icons";
import classNames from "clsx";
import React from "react";
import { GoogleMap } from "react-google-maps";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { isNullOrUndefined } from "util";
import Button from "../../components/Button";
import CustomInput from "../../components/CustomInput";
import GridContainer from "../../components/Grids/GridContainer";
import GridItem from "../../components/Grids/GridItem";
import Maps from "../../components/Maps";
import Typography from "../../components/Typography";
import AppConfig from "../../config/AppConfig";
import { IChargesBreakdown } from "../../interfaces/PricingInterface";
import { IServiceOrder } from "../../interfaces/ServiceOrderInterface";
import { LinksEnum, TaskEnum } from "../../literals/Enums";
import { IReducers } from "../../redux";
import TrackCarActions from "../../redux/TrackCarRedux";
import { debugLog } from "../../services/LogService";
import { getChargesBreakdown } from "../../services/TransformService";
import { getTimeDifference } from "../../services/TransformTime";
import { isGeoLocation, isOrderUidShortValid, isPricing, isServiceOrderActive,
  isServiceOrderCompleted } from "../../services/ValidationService";
import withRoot from "../../WithRoot";
import BillSummary from "./sections/BillSummary";
import StatusSummary from "./sections/StatusSummary";
import trackCarStyle from "./styles/TrackCarStyle";

interface ITrackCarProps {
  bounds?: google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral;
  classes: any;
  center?: google.maps.LatLng | google.maps.LatLngLiteral;
  isTrackCarLoading: boolean;
  markerCoord?: google.maps.LatLng | google.maps.LatLngLiteral;
  match: any;
  serviceOrder: IServiceOrder | null;

  onClick: (e: google.maps.MouseEvent | google.maps.IconMouseEvent) => void;
  onMapMounted: (ref: GoogleMap) => void;
  trackCar: (orderUid: string) => void;
  trackCarByUid: (orderUidShort: string) => void;
  unTrackCar: () => void;
}
interface ITrackCarState {
  chargesBreakdown: IChargesBreakdown | null;
  orderUidShort: string | null;
  submitAttempted: boolean;
  timeElapsed: string;
}
export class TrackCar extends React.Component<ITrackCarProps, ITrackCarState> {

  private interval: NodeJS.Timeout | null = null;

  constructor(props: ITrackCarProps) {
    super(props);

    const serviceOrder = props.serviceOrder;
    const chargesBreakdown = !isNullOrUndefined(serviceOrder) && isPricing(serviceOrder.Pricing)
                              ? getChargesBreakdown(serviceOrder.Pricing, serviceOrder.DropOffStartTime,
                                                    serviceOrder.Coupon)
                              : null;
    const dtTimeElapsed = !isNullOrUndefined(serviceOrder)
                            ? getTimeDifference(serviceOrder.DropOffStartTime)
                            : { strHours: "00", strMinutes: "00"};
    const timeElapsed = dtTimeElapsed.strHours + ":" + dtTimeElapsed.strMinutes;

    this.state = {
      chargesBreakdown,
      orderUidShort: null,
      submitAttempted: false,
      timeElapsed
    };
  }

  public componentWillMount() {
    const { match } = this.props;
    debugLog({class: "TrackCar", name: "willMount", match});
    if (match && match.params && match.params.orderUid) {
      this.props.trackCarByUid(match.params.orderUid);
    }
  }

  public componentDidMount() {
    document.title = "Track your car";
    this.interval = setInterval(
      this.setTimeElapsed,
      60000
    );
  }

  public componentWillReceiveProps(newProps: ITrackCarProps) {
    const serviceOrder = newProps.serviceOrder;
    const isOrderActive = serviceOrder && isServiceOrderActive(serviceOrder);
    if (isOrderActive) {
      // tslint:disable-next-line: max-line-length
      debugLog({class: "TrackCar", name: "willRcvProps", props: this.props, isPricing: isPricing((serviceOrder || {Pricing: ""}).Pricing)});
      const chargesBreakdown = !isNullOrUndefined(serviceOrder) && isPricing(serviceOrder.Pricing)
                                ? getChargesBreakdown(serviceOrder.Pricing, serviceOrder.DropOffStartTime,
                                                      serviceOrder.Coupon)
                                : null;
      const dtTimeElapsed = !isNullOrUndefined(serviceOrder)
                              ? getTimeDifference(serviceOrder.DropOffStartTime)
                              : { strHours: "00", strMinutes: "00"};
      const timeElapsed = dtTimeElapsed.strHours + ":" + dtTimeElapsed.strMinutes;

      this.setState({
        chargesBreakdown,
        timeElapsed
      });
    }
  }

  public componentWillUnmount() {
    this.props.unTrackCar();
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  public render() {
    const options = {
      draggable: true,
      streetViewControl: false,
    } as google.maps.MapOptions;

    const center = {
      lat: 12.9760, lng: 80.2212 // Velachery Coords
    };

    const { serviceOrder, classes, isTrackCarLoading, match } = this.props;
    const { chargesBreakdown, submitAttempted, orderUidShort, timeElapsed } = this.state;
    const headerClasses = classNames(classes.container, classes.center, classes.section);
    const carCoords = !isNullOrUndefined(serviceOrder) ? serviceOrder.CarLocation : null;
    const carLatLng: google.maps.LatLngLiteral | undefined = isGeoLocation(carCoords)
                                                          ? { lat: carCoords.latitude, lng: carCoords.longitude }
                                                              : undefined;
    const parkingCoords = !isNullOrUndefined(serviceOrder) ? serviceOrder.ParkingCoord : null;
    const parkingLatLng: google.maps.LatLngLiteral | undefined = isGeoLocation(parkingCoords)
                                  ? { lat: parkingCoords.latitude, lng: parkingCoords.longitude }
                                  : undefined;
    const dropOffCoord = !isNullOrUndefined(serviceOrder) ? serviceOrder.DropOffCoord : null;
    const dropOffLatLng: google.maps.LatLngLiteral | undefined = isGeoLocation(dropOffCoord)
                                  ? { lat: dropOffCoord.latitude, lng: dropOffCoord.longitude }
                                  : undefined;
    const isOrderCompleted = serviceOrder && isServiceOrderCompleted(serviceOrder);
    const invalidOrderUidShort = (submitAttempted && !isOrderUidShortValid(orderUidShort)) ||
                                    (submitAttempted && isOrderUidShortValid(orderUidShort)
                                      && isNullOrUndefined(serviceOrder));
    const renderChgsBreakdown = !isNullOrUndefined(serviceOrder) && isOrderCompleted
                                ? serviceOrder.ChargesBreakdown
                                : !isNullOrUndefined(serviceOrder) && isPricing(serviceOrder.Pricing)
                                ? getChargesBreakdown(serviceOrder.Pricing, serviceOrder.DropOffStartTime,
                                                      serviceOrder.Coupon)
                                : chargesBreakdown;
    const isReturnInitiated = serviceOrder ? serviceOrder.TaskType === TaskEnum.Return : false;

    // tslint:disable-next-line: max-line-length
    debugLog({class: "TrackCar", name: "render", carLatLng, isPricing: serviceOrder ? isPricing(serviceOrder.Pricing) : null, renderChgsBreakdown, props: this.props, state: this.state});
    return(
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Track your car</title>
          <meta property="og:title" content={"Track your car"} />
          <meta property="og:description" content={`Tired looking for a free parking spot? We have a perfect
          solution for your day-to-day problem. Our platform provides most simple and seamless
          process to help you park your car at most buzzing locations in your city.`} />
          <meta property="og:type" content={"video:conference"} />
          <meta property="og:url" content={window.location.origin} />
        </Helmet>
      {
        isTrackCarLoading ?
          <div style={{flexGrow: 1, marginTop: 80}}>
            <LinearProgress color={"secondary"} />
          </div>
        : (!(match && match.params && match.params.orderUid) && isNullOrUndefined(serviceOrder)) ?
          <div className={classes.section}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Enter Tracking ID*"
                  id="orderUidShort"
                  error={invalidOrderUidShort}
                  inputProps={{
                    color: "brand",
                    onChange: (event: any) => this.setState({
                      orderUidShort: (event.target.value ? event.target.value.toLowerCase() : "")
                    }),
                    value: this.state.orderUidShort || ""
                  }}
                  helperText={this.state.submitAttempted && (!isOrderUidShortValid(this.state.orderUidShort)
                                || isNullOrUndefined(serviceOrder))
                                  ? `Please enter a valid tracking number` : ""}
                  formControlProps={{
                    error: invalidOrderUidShort,
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={12} className={classes.textCenter}>
                    <Button buttonColor="brand" onClick={this.onTrack} disabled={isTrackCarLoading}>Track</Button>
                  </GridItem>
                </GridContainer>
            </GridContainer>
          </div>
        : isNullOrUndefined(serviceOrder) ?
          <div className={headerClasses}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6} style={{textAlign: "center"}}>
                <Typography variant="h6">
                  Error loading this page
                </Typography>
              </GridItem>
            </GridContainer>
          </div>
        :
          <div className={classes.mainContainer}>
            {
              (serviceOrder && isOrderCompleted) ?
              <div className={headerClasses}>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={6} style={{textAlign: "center"}}>
                    <Typography variant="h6">
                      We have returned your car. Thanks for choosing us to park your car
                    </Typography>
                    <Button
                      href={LinksEnum.trackCarHome}
                      buttonColor="transparent"
                      className={classes.navLink}
                    >
                      Go Back
                    </Button>
                  </GridItem>
                </GridContainer>
              </div>
              : (carLatLng || parkingLatLng || dropOffLatLng || center) ?
              <Maps
                center={carLatLng || dropOffLatLng || parkingLatLng || center}
                defaultOptions={options}
                googleMapURL={AppConfig.googleMapApi}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `380px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                markerCoord={carLatLng || dropOffLatLng || parkingLatLng}
                markerType={"car"}
                zoom={18}
                onClick={this.props.onClick}
                onMapMounted={this.props.onMapMounted}
              />
              :
              <div className={headerClasses} style={{height: `380px`}}>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={6} style={{textAlign: "center"}}>
                    <Typography variant="h6">
                      Searching for car location...
                    </Typography>
                  </GridItem>
                </GridContainer>
              </div>
            }
            {
              (serviceOrder && !isOrderCompleted) &&
                <StatusSummary detail={serviceOrder} />
            }
            {
              !isReturnInitiated &&
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6} style={{textAlign: "center"}}>
                  <Typography variant="subtitle1" style={{fontWeight: "bold"}}>
                    Return my car
                  </Typography>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={4} style={{textAlign: "center"}}>
                      <Button
                        href={`sms:+918939995878?body=Return my car. Tracking ID: ${serviceOrder.UidShort}`}
                        buttonColor="brand"
                        className={classes.navLink}
                      >
                        <SmsSharp className={classes.leftIcon}/>
                        Send SMS
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} style={{textAlign: "center"}}>
                      <Typography variant="body1"> or </Typography>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} style={{textAlign: "center"}}>
                      <Button
                        href={`tel:+918939995878`}
                        buttonColor="brand"
                        className={classes.navLink}
                      >
                        <Call className={classes.leftIcon}/>
                        Call
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            }
            { renderChgsBreakdown &&
              <BillSummary charges={renderChgsBreakdown} />
            }
            {
              // tslint:disable: max-line-length
              <footer className={classNames(classes.footerRate, classes.footer)}>
                <Container maxWidth="md">
                  <List disablePadding>
                    <ListItem className={classes.listItem}>
                      <ListItemText
                        primary={`Time Elapsed: ${timeElapsed}`}
                        secondary={`Started on: ${serviceOrder.DropOffStartTime ? serviceOrder.DropOffStartTime.slice(11, 16) : ""}`} />
                      <Typography variant="h6">Total Charges: {(renderChgsBreakdown && renderChgsBreakdown.ToPay > 0) ? renderChgsBreakdown.ToPay : "FREE"}</Typography>
                    </ListItem>
                  </List>
                </Container>
              </footer>
            }
          </div>
          }
        </div>
    );
  }

  private onTrack = () => {
    this.setState({ submitAttempted: true });
    const { orderUidShort } = this.state;
    if (orderUidShort && isOrderUidShortValid(orderUidShort)) {
      this.props.trackCarByUid(orderUidShort);
    }
  }

  private setTimeElapsed = () => {
    const { serviceOrder } = this.props;
    if (!isNullOrUndefined(serviceOrder) && isServiceOrderActive(serviceOrder)) {
      const dtTimeElapsed = getTimeDifference(serviceOrder.DropOffStartTime, serviceOrder.ReturnEndTime);
      const timeElapsed = dtTimeElapsed.strHours + ":" + dtTimeElapsed.strMinutes;
      const chargesBreakdown =  !isNullOrUndefined(serviceOrder.Pricing)
                                  && isPricing(serviceOrder.Pricing)
                                ? getChargesBreakdown(serviceOrder.Pricing, serviceOrder.DropOffStartTime,
                                                      serviceOrder.Coupon)
                                : null;

      this.setState((prvState: ITrackCarState) => {
        if (chargesBreakdown) {
          return { chargesBreakdown, timeElapsed };
        }
        return { chargesBreakdown: prvState.chargesBreakdown, timeElapsed };
      });
    }
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    trackCar: (orderUid: string) => dispatch(TrackCarActions.trackCar(orderUid)),
    trackCarByUid: (orderUidShort: string) => dispatch(TrackCarActions.trackCarByUid(orderUidShort)),
    unTrackCar: () => dispatch(TrackCarActions.unTrackCar()),
  };
};
const mapStateToProps = (state: IReducers) => {
  return {
    isTrackCarLoading: state.trackCar.isLoading,
    serviceOrder: state.trackCar.serviceOrder,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(
  withRoot(withStyles(trackCarStyle as any)(TrackCar)));

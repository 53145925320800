import * as React from "react";
import { GoogleMap } from "react-google-maps";
import SearchBox from "react-google-maps/lib/components/places/SearchBox";
import MapComponent from "../../../components/Maps/MapWithSearch";
import AppConfig from "../../../config/AppConfig";

interface IMapSectionProps {
  bounds?: google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral;
  center?: google.maps.LatLng | google.maps.LatLngLiteral;
  markerCoord?: google.maps.LatLng | google.maps.LatLngLiteral;

  onClick: (e: google.maps.MouseEvent | google.maps.IconMouseEvent) => void;
  onMapMounted: (ref: GoogleMap) => void;
  onPlacesChanged: () => void;
  onSearchBoxMounted: (ref: SearchBox) => void;
}
export default class MapSection extends React.Component<IMapSectionProps> {

  public render() {
    const options = {
      draggable: true,
      streetViewControl: false,
    } as google.maps.MapOptions;
    const { bounds, center, markerCoord } = this.props;
    return (
      <MapComponent
        bounds={bounds}
        center={center}
        defaultOptions={options}
        isMarkerShown={true}
        googleMapURL={AppConfig.googleMapApi}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        markerCoord={markerCoord}
        onClick={this.props.onClick}
        onMapMounted={this.props.onMapMounted}
        onPlacesChanged={this.props.onPlacesChanged}
        onSearchBoxMounted={this.props.onSearchBoxMounted}
      />
    );
  }
}

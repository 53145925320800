import { withStyles } from "@material-ui/core";
import React from "react";
import withRoot from "../../../../WithRoot";
import termsAndConditionsPageStyle from "../../styles/TermsAndConditionsStyle";

interface IUserAgreementTermsProps {
  classes?: any;
}
class UserAgreementPage extends React.Component<IUserAgreementTermsProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4 className={classes.title}>1. User Terms</h4>
        <p className={classes.description}>
          (a) These terms and conditions (“the Terms”) apply to the Service provided by
          WannaPark Limited trading as www.wannapark.in (“us”, “we” or “www.wannapark.in” to you,
          the recipient of the Service (“the User, “you” or “your”).
        </p>
        <p className={classes.description}>
          (b) For the purpose of this agreement “Authorized Driver/ Valet” shall mean and include such
          individuals as may be evaluated, appointed and trained by the Authorised Vendor to provide the
          valet parking services on behalf of the Authorised Valet Parking Operator in accordance with the terms
          &amp; conditions applicable to Authorised Vehicle Operators and Applicable Law.
          and “Authorized Valet Parking Operator” shall mean and include such individuals and agencies
          which hold valid Contract Carriage Permit(s) and “Driver/Valet” shall mean an Authorized Driver/Valet,
          offering the service of valet parking to the Customers within the City of Operation from the pick up points to
          the drop points as communicated by the Customer at the time of requesting the booking on the Application.
        </p>
        <p className={classes.description}>
          (c) These Terms will apply at any time when you use the Site or Service to book a parking
          space and by continuing to use the Site or Service you accept these
          Terms and they will apply to the agreement between you and us (“the Agreement”).
        </p>
        <p className={classes.description}>
          (d) These Terms may be amended from time to time. Any amendments or new Terms will be available
          on the Site and the Terms on the Site at the time you enter into a booking for a parking space
          or use the Services will be the ones that apply.
        </p>
        <p className={classes.description}>
          (e) These Terms apply only to the provision of the Service directly by us to you namely allowing you
          to book and pay for a parking space (“Parking Space”) as advertised by the Owner. The granting of
          a licence to use a Parking Space is dealt with under the terms of a Parking Service Agreement with the Owner.
          For the avoidance of doubt our responsibility is to confirm a Booking and make payment on your behalf
          to the Owner.
        </p>
        <p className={classes.description}>
          (f) When you place a Booking you confirm you agree to agreement between you and the Owner.
        </p>
        <p className={classes.description}>
          (g) We act as an agent of the Owner in entering into the agreement.
          We are not a party  and we will not be liable to you, the Owner or any third party for any breach of the
          agreement or otherwise in relation to the Parking Space. We have no control over the conduct of a User
          or Owner and shall not be liable for any actions of the User or the Owner.
        </p>
        <p className={classes.description}>
          (h) Details of the Owner are set out on the booking received page.
        </p>
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(UserAgreementPage as any));

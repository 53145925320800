import withStyles from "@material-ui/core/styles/withStyles";
import { Chat, Payment, VerifiedUser } from "@material-ui/icons";
import classNames from "clsx";
import React from "react";
// import { ParallaxBanner } from "react-scroll-parallax";
import Button from "../../../components/Button";
import InfoArea from "../../../components/Cards/InfoArea";
import GridContainer from "../../../components/Grids/GridContainer";
import GridItem from "../../../components/Grids/GridItem";
import { LinksEnum } from "../../../literals/Enums";
// import LandingBgrnd from "../../../themes/img/HomeParking.jpg";
import sectionStyle from "../styles/SectionStyle";

interface IHowRentSpaceWorksProps {
  classes?: any;
}
class HowRentSpaceWorks extends React.Component<IHowRentSpaceWorksProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        {/* <ParallaxBanner
          className={classes.bannerBg}
          layers={[
            {
              amount: 0.6,
              image: LandingBgrnd,
            },
          ]}
        >
            <div className={classes.parallaxChildren}>
                <h2 className={classes.parallaxTitle}>
                  Make money by renting out your unused land.
                </h2>
            </div>
        </ParallaxBanner> */}
        <div className={classNames(classes.main)}>
          <div className={classes.container}>
            <div className={classes.section}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                  <h2 className={classes.title}>Rent your space</h2>
                  <h5 className={classes.description}>
                    Make easy money by renting out your parking space by 3 simple steps.
                  </h5>
                </GridItem>
              </GridContainer>
              <div>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <InfoArea
                      heading="Send us the details"
                      // tslint:disable-next-line:max-line-length
                      description={`You can add a driveway, car park, empty hotel spaces or anything else. Once you send us the details we will send you the team to verify the ownership details`}
                      icon={Chat}
                      iconColor="info"
                      vertical
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InfoArea
                      heading="Control Availability"
                      // tslint:disable-next-line:max-line-length
                      description={`Only rent out your spaces when it suits you - whether that is from 9 till 5 or all the time.`}
                      icon={VerifiedUser}
                      iconColor="danger"
                      vertical
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InfoArea
                      heading="Get Paid"
                      // tslint:disable-next-line:max-line-length
                      description={`Get regular bookings and payments straight to your bank or paytm`}
                      icon={Payment}
                      iconColor="success"
                      vertical
                    />
                  </GridItem>
                </GridContainer>
              </div>
            </div>
            <Button
              size="large"
              buttonColor="brand"
              href={LinksEnum.rentSpace}
            >
              Add your space
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(sectionStyle as any)(HowRentSpaceWorks);

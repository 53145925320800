import Button, { ButtonProps } from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "clsx";
import React from "react";
import buttonStyle from "../../themes/jss/material-kit-react/buttonStyle";

interface ICustomButtonProps {
  classes: any;
  buttonColor?:
    "primary" |
    "info" |
    "success" |
    "warning" |
    "brand" |
    "danger" |
    "rose" |
    "white" |
    "facebook" |
    "twitter" |
    "google" |
    "github" |
    "transparent";
  simple?: boolean;
  round?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  block?: boolean;
  link?: boolean;
  justIcon?: boolean;
}
const CustomButton: React.FC<ButtonProps & ICustomButtonProps> = (props) => {
  const {
    classes,
    buttonColor,
    round,
    children,
    fullWidth,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    className,
    ...rest
  } = props;
  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size || "sm"]]: size,
    [classes[buttonColor || "primary"]]: buttonColor,
    [classes.round]: round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [className || ""]: className
  });
  return (
    <Button {...rest} className={btnClasses}>
      {children}
    </Button>
  );
};

export default withStyles(buttonStyle as any)(CustomButton);

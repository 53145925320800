import { List, ListItem, SvgIcon, Tooltip, withStyles } from "@material-ui/core";
import classNames from "clsx";
import * as React from "react";
import { LinksEnum } from "../../literals/Enums";
import footerStyle from "./styles/footerStyle";

function Footer({ ...props }) {
  const { classes, hasBottomPanel, whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerBottomMargin]: hasBottomPanel,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a
                href={LinksEnum.privacyPolicy}
                className={classes.block}
              >
                Privacy Policy
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href={LinksEnum.terms}
                className={classes.block}
              >
                Terms and Conditions
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Tooltip
                id="twitter"
                title="Follow us on twitter"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
              >
                <a
                  href="https://twitter.com/WannaParkLLC"
                  rel="noopener noreferrer"
                  className={classes.navLink}
                  target="_blank"
                >
                  <SvgIcon scale="32px" style={{height: "24px", width: "24px"}}>>
                  {
                    // tslint:disable-next-line: max-line-length
                    <path d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z" />
                  }
                  </SvgIcon>
                </a>
              </Tooltip>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Tooltip
                id="facebook"
                title="Follow us on facebook"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
              >
                <a
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/WannaParkLLC"
                  className={classes.navLink}
                  target="_blank"
                >
                  <SvgIcon scale="32px" style={{height: "24px", width: "24px"}}>
                  {
                    // tslint:disable-next-line: max-line-length
                    <path d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M18,5H15.5A3.5,3.5 0 0,0 12,8.5V11H10V14H12V21H15V14H18V11H15V9A1,1 0 0,1 16,8H18V5Z"  />
                  }
                  </SvgIcon>
                </a>
              </Tooltip>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Tooltip
                id="contactus"
                title="Contact us via email"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
              >
                <a
                  rel="noopener noreferrer"
                  href={`mailto:contact@wannapark.in?Subject="I would like to say something"`}
                  className={classes.navLink}
                  target="_blank"
                >
                  <SvgIcon scale="32px" style={{height: "24px", width: "24px"}}>
                  {
                    // tslint:disable-next-line: max-line-length
                    <path d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z" />
                  }
                  </SvgIcon>
                </a>
              </Tooltip>
            </ListItem>
          </List>
        </div>
        <div className={classes.right}>
          &copy; {new Date().getFullYear()} , <a  href="/" className={aClasses}>WannaPark</a>
        </div>
      </div>
    </footer>
  );
}

export default withStyles(footerStyle as any)(Footer);

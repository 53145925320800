import React, { Component } from "react";
import { Marker, MarkerProps } from "react-google-maps";
import directionCarSvg from "../../../themes/img/directionCar.svg";

export default class CarMarker extends Component<MarkerProps> {

  public render() {
    const { ...rest } = this.props;
    const directionCarIcon = { url: directionCarSvg, scaledSize: new google.maps.Size(32, 32) };
    return (
      <Marker {...rest} options={{icon: directionCarIcon}} />
    );
  }
}

import { format, parse } from "date-fns";

export const displayDate = (datestr: string) => {
  const date = parse(datestr);

  return format(date, "DD MMM YYYY");
};

const formatTime = (t: string) => {
  // tslint:disable-next-line:prefer-const
  let [hours, minutes] = t.split(":");

  let isEvening = false;
  if (parseInt(hours, 10) > 12) {
    isEvening = true;
    hours = (parseInt(hours, 10) - 12).toString();
  }

  if (hours === "00") {
    return "Midnight";
  }

  return `${hours}:${minutes}${isEvening ? "pm" : "am"}`;
};

const getCurrentDate = () => {
  return format(new Date(), "YYYY-MM-DD");
};

const getCurrentDateTime = () => {
  return format(new Date(), "YYYY-MM-DD HH:mm:ssZZ");
};

const getTimeDifference = (startTime: string, endTime?: string) => {
  const dtNow = new Date();
  const dtStart = parse(startTime || dtNow);
  const dtEnd = parse(endTime || dtNow);

  const minute = 60 * 1000;
  const hour = minute * 60;
  const day = hour * 24;
  const month = day * 30;
  const dtDiff = ((dtEnd as any) - (dtStart as any)) as number;

  let ms = Math.abs(dtDiff);

  const months = parseInt((ms / month).toString(), 10);
  ms -= months * month;
  const strMonths =  ("0" + months.toString()).slice(-2);

  const days = parseInt((ms / day).toString(), 10);
  ms -= days * day;
  const strDays =  ("0" + days.toString()).slice(-2);

  const hours = parseInt((ms / hour).toString(), 10);
  ms -= hours * hour;
  const strHours =  ("0" + hours.toString()).slice(-2);

  const minutes = parseInt((ms / minute).toString(), 10);
  const strMinutes =  ("0" + minutes.toString()).slice(-2);

  return { months, days, hours, minutes, strMonths, strDays, strHours, strMinutes };
};

export { formatTime, getCurrentDate, getCurrentDateTime, getTimeDifference };

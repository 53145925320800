import React from "react";
import Footer from "../components/Footer";
// import GoBumprCampaign from "../containers/GoBumprCampaign";
import LandingPage from "../containers/Landing";
import Login from "../containers/Login";
import MainLayout from "../containers/MainLayout";
import RentSpace from "../containers/RentSpace";
import TermsAndConditions from "../containers/TermsAndConditions";
import OwnerAgreement from "../containers/TermsAndConditions/OwnerAgreement";
import PrivacyPolicy from "../containers/TermsAndConditions/PrivacyPolicy";
import UserAgreement from "../containers/TermsAndConditions/UserAgreement";
import TrackCar from "../containers/TrackCar";
import { LinksEnum } from "../literals/Enums";

const ownerAgreementPage = (props: any) => (
  <div>
    <MainLayout {...props} showTrackMyCar headerColor="white" headerScrollHeight={0}/>
    <OwnerAgreement {...props} />
    <Footer />
  </div>
);

const privacyPolicyPage = (props: any) => (
  <div>
    <MainLayout {...props} showTrackMyCar headerColor="white" headerScrollHeight={0}/>
    <PrivacyPolicy {...props} />
    <Footer />
  </div>
);

const rentSpacePage = (props: any) => (
  <div>
    <MainLayout {...props} showTrackMyCar headerColor="white" headerScrollHeight={300}/>
    <RentSpace {...props} />
    <Footer />
  </div>
);

const termsAndConditionsPage = (props: any) => (
  <div>
    <MainLayout {...props} showTrackMyCar headerColor="white" headerScrollHeight={0}/>
      <TermsAndConditions {...props} />
    <Footer />
  </div>
);

const TrackCarPage = (props: any) => (
  <div>
    <MainLayout {...props} showHelp headerColor="white" headerScrollHeight={0}/>
    <TrackCar {...props} />
    <Footer hasBottomPanel />
  </div>
);

const userAgreementPage = (props: any) => (
  <div>
    <MainLayout {...props} showTrackMyCar headerColor="white" headerScrollHeight={0}/>
    <UserAgreement {...props} />
    <Footer />
  </div>
);

// const goBumprCampaignPage = (props: any) => (
//   <div>
//     <MainLayout {...props} showHelp headerColor="white" headerScrollHeight={300}/>
//     <GoBumprCampaign {...props} />
//     <Footer />
//   </div>
// );

const indexRoutes = [
  { path: LinksEnum.home, name: "LandingPage", component: LandingPage },
  { path: LinksEnum.login, name: "LoginPage", component: Login },
  { path: LinksEnum.rentSpace, name: "RentSpacePage", component:  rentSpacePage},
  { path: LinksEnum.trackCarHome, name: "TrackCarPage", component: TrackCarPage},
  { path: LinksEnum.trackCar, name: "TrackCarPage", component: TrackCarPage},
  { path: LinksEnum.terms, name: "Terms", component: termsAndConditionsPage },
  { path: LinksEnum.ownerAgreement, name: "OwnerAgreement", component: ownerAgreementPage },
  { path: LinksEnum.privacyPolicy, name: "PrivacyPolicy", component: privacyPolicyPage },
  { path: LinksEnum.userAgreement, name: "UserAgreement", component: userAgreementPage },
  // { path: LinksEnum.gobumprCampaign, name: "GoBumprOffers", component: goBumprCampaignPage},
  // { path: LinksEnum.goBumprCampaignOffer, name: "GoBumprOffers", component: goBumprCampaignPage},
];

export default indexRoutes;

import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "clsx";
import * as React from "react";
import cardStyle from "./styles/cardStyle";

// tslint:disable-next-line:typedef
function Card({ ...props }) {
  const { classes, className, children, plain, carousel, ...rest } = props;
  const cardClasses = classNames({
    [classes.card]: true,
    [classes.cardPlain]: plain,
    [classes.cardCarousel]: carousel,
    [className]: className !== undefined
  });
  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  );
}

// Card.propTypes = {
//   classes: PropTypes.object.isRequired,
//   className: PropTypes.string,
//   plain: PropTypes.bool,
//   carousel: PropTypes.bool
// };

export default withStyles(cardStyle as any)(Card);

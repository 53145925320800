import { container, grayColor, title } from "../../../themes/jss/material-kit-react";

const pricingStyle = (theme: any) => ({
  cardPricing: {
    alignItems: "baseline",
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
  container: {
    color: "#FFFFFF",
    zIndex: "12",
    ...container
  },
  description: {
    color: grayColor,
    fontSize: "14px",
    marginTop: "0px",
    overflow: "hidden",
  },
  descriptionWrapper: {
    color: grayColor,
    overflow: "hidden"
  },
  heroContent: {
    padding: theme.spacing(1, 0, 1),
    textAlign: "center"
  },
  imageModal: {
    top: "85px"
  },
  infoArea: {
    margin: "0 auto",
    maxWidth: "360px",
    padding: "0px"
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  section: {
    padding: "16px 0",
    textAlign: "center"
  },
  sectionWrapper: {
    marginRight: "10px",
    marginTop: "24px",
  },
  slideContainer: {
    justifyContent: "center",
    margin: "auto",
    width: "70%"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
});

export default pricingStyle;

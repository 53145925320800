import { withStyles } from "@material-ui/core";
import React from "react";
import withRoot from "../../../../WithRoot";
import termsAndConditionsPageStyle from "../../styles/TermsAndConditionsStyle";

interface IYourObligationProps {
  classes?: any;
}
class YourObligationPage extends React.Component<IYourObligationProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4 className={classes.title}>7. Your Obligations</h4>
        <p className={classes.description}>
        (a) You must:
          <p className={classes.subDescription}>
          (i) honour all Bookings with the User;
          </p>
          <p className={classes.subDescription}>
          (ii) provide the Parking Space in accordance with the details and information set out in your Advert;
          </p>
          <p className={classes.subDescription}>
          (iii) ensure that all information about you and the Parking Space that you provide to us for inclusion
          on the Site is true and accurate in all respects and could not in any way be construed as misleading
          to the User;
          </p>
          <p className={classes.subDescription}>
          (iv) deal with the User in a professional and courteous manner and in such a way as to not cause
          any harm or damage to our reputation;
          </p>
          <p className={classes.subDescription}>
          (v) deal with all queries from the User relating to a Parking Space or booking in a prompt and
          satisfactory manner;
          </p>
          <p className={classes.subDescription}>
          (vi) comply with all applicable laws, tax requirements and rules and regulations that may apply to the
          Parking Space, including but not limited to zoning laws and laws governing rental of or licences to use
          residential and other properties;
          </p>
          <p className={classes.subDescription}>
          (vii) subject to clause [8], use your best endeavours to settle any disputes that may arise during the
          Licence Period including taking such action as is necessary to bring the dispute to a satisfactory
          conclusion as soon as practicable so that the Booking may still be fulfilled;
          </p>
          <p className={classes.subDescription}>
          (viii) maintain in effect for the period during which the Parking Space is advertised on the Site
          and for 12 months after the date of the last Booking of the Parking Space, insurance in respect
          of your obligations and liabilities to the User or otherwise,
          for such sum and for such amount as may be prudent and appropriate in all of the circumstances; and
          </p>
        </p>
        <p className={classes.description}>
          (b) You agree that you will not create any false account with WannaPark.in or use your account with us
          for any immoral or illegal activity or purpose including (without limit) malicious or
          fraudulent bookings or money laundering.
        </p>
        <p className={classes.description}>
          (c) You will be entirely responsible for any and all insurance that you may require for the purposes
          of granting any licence to use your Parking Space.
        </p>
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(YourObligationPage as any));

import { withStyles } from "@material-ui/core";
import React from "react";
import withRoot from "../../../../WithRoot";
import termsAndConditionsPageStyle from "../../styles/TermsAndConditionsStyle";

interface IUserTerminationProps {
  classes?: any;
}
class UserTerminationPage extends React.Component<IUserTerminationProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4 className={classes.title}>8. Termination and Suspension</h4>
        <p className={classes.description}>
          (a) We may suspend your access to the Site or the Service if we believe you to be
          in breach of any of the Terms of this Agreement or we suspect that you
          are about to commit a breach of any of the Terms of this Agreement.
        </p>
        <p className={classes.description}>
          (b) If we suspend your access to the Site for any reason, we may refuse to provide you
          with any Service including the right to make any further Bookings.
        </p>
        <p className={classes.description}>
          (c) We may terminate this Agreement at any time if you are in breach of any of the
          Terms of this Agreement or we reasonably believe that you are about to commit
          a breach of any of the Terms of this Agreement.
        </p>
        <p className={classes.description}>
          (d) If we terminate access you will no longer be able to use the Service or make Bookings through us,
          and all existing Bookings will be cancelled. If you have an outstanding Booking for which you have made
          pre-payment we may refund you. Any such refund is at our absolute discretion and subject
          to a charge of &#8377;1000 to cover administration costs.
        </p>
        <p className={classes.description}>
          (e) In the event of termination of this Agreement, those terms which expressly or by implication
          survive termination shall continue in full force and effect, and the Agreement shall also continue
          in relation to any existing Bookings which are not cancelled.
        </p>
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(UserTerminationPage as any));

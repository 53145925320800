import { withStyles } from "@material-ui/core";
import React from "react";
import { LinksEnum } from "../../../../literals/Enums";
import withRoot from "../../../../WithRoot";
import termsAndConditionsPageStyle from "../../styles/TermsAndConditionsStyle";

interface IUserBookingProps {
  classes?: any;
}
class UserBookingPage extends React.Component<IUserBookingProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4 className={classes.title}>2. Booking</h4>
        <p className={classes.description}>
          (a) To make a booking (“the Booking”) you must provide us with your name, address, make,
          model and car registration number and a valid email address. We may also require additional information
          from time to time. Please note that any personal information that you provide to us will
          be subject to the data privacy obligations which are set out in the
          <a href={LinksEnum.privacyPolicy}> Privacy Policy</a>. The
          <a href={LinksEnum.privacyPolicy}> Privacy Policy </a> forms part of this Agreement.
        </p>
        <p className={classes.description}>
          (b) The Booking you make is valid only for the times, dates, price and Parking Space
          specified in the Booking.
        </p>
        <p className={classes.description}>
          (c) You must supply a valid email address when making the Booking so that we can email the
          confirmation of your Booking and other information relating to your use of the Service.
          We will not be held responsible if you fail to provide a valid email address and you do not
          receive a Booking confirmation or other information from us that you might be expecting.
          If you become aware that you have supplied an invalid email address or incorrect information,
          please login to www.wannapark.in and make the necessary changes.
        </p>
        <p className={classes.description}>
          (d) Please note that the agreement relating to the use of the Parking Space is between you and
          the Owner and will be governed by the
          <a href={LinksEnum.parkingServiceAgreement}> Parking Service Agreement</a>.
          The Owner has advertised their parking space for the purpose of forming a binding agreement
          between you and the Owner and we have no liability to you in relation to the
          Parking Space or the license other than as set out in this agreement.
        </p>
        <p className={classes.description}>
          (e) Once you have accepted the terms of the
          <a href={LinksEnum.parkingServiceAgreement}> Parking Service Agreement </a> and we have
          received payment, your Booking will be confirmed by email. A Booking only becomes a binding
          agreement with the Owner once you have received confirmation from us by email.
        </p>
        <p className={classes.description}>
          (f) Payment in full is required when making a Booking.
        </p>
        <p className={classes.description}>
          (g) We may, in our absolute discretion, decline to confirm a Booking.
        </p>
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(UserBookingPage as any));

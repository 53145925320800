import { withStyles } from "@material-ui/core";
import React from "react";
import { LinksEnum } from "../../../../literals/Enums";
import withRoot from "../../../../WithRoot";
import termsAndConditionsPageStyle from "../../styles/TermsAndConditionsStyle";

interface ICancellationProps {
  classes?: any;
}
class CancellationPage extends React.Component<ICancellationProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4 className={classes.title}>4. Cancellation</h4>
        <p className={classes.description}>
          (a) If you want to cancel a Booking you must do so through the
          <a href={LinksEnum.cancellationPolicy}> cancellation policy</a> which may be updated from
          time to time ("Cancellation Policy"). To cancel a Booking you must log into your Account and
          process the cancellation through the Website in accordance with the Cancellation Policy.
          Cancellation of a Booking will take effect at the point you receive an acknowledgment of such
          cancellation from us.
        </p>
        <p className={classes.description}>
          (b) Furthermore, you agree that if a User wishes to cancel a booking they may do so through us
          and our Website and we will act as your agent in dealing with any cancellation. If we are required
          to process a cancellation we will do so in accordance with the Cancellation Policy.
        </p>
        <p className={classes.description}>
          (c) If we have received any payments in respect of a booking before a Booking Period begins and such
          booking is subsequently cancelled by you or the User, you authorise us to refund the User from any payments
          we are holding on your behalf. Where you have received payments you are required to refund the User in
          accordance with the Cancellation Policy.
        </p>
        <p className={classes.description}>
          (d) We reserve the right to cancel your account and terminate this agreement if you do not refund a
          User within the timeframes specified in the <a href={LinksEnum.cancellationPolicy}> Cancellation Policy</a>.
          You agree that you will be responsible for such refunds and that we may use any funds we are holding
          on your behalf in order to refund a User, deduct such funds from the debit or credit card you have
          supplied to us or WannaPark may settle the refund owed to the User directly with the User and then
          recover this money from you afterwards.
        </p>
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(CancellationPage as any));

import { withStyles } from "@material-ui/styles";
import classNames from "clsx";
import React from "react";
import GridContainer from "../../../components/Grids/GridContainer";
import GridItem from "../../../components/Grids/GridItem";
import RASILogo from "../../../themes/img/RasiLogo.png";
import SangeethaLogo from "../../../themes/img/SangeethaLogo.png";
import sectionStyle from "../styles/PricingStyle";

interface IPartnersProps {
  classes?: any;
}
const Partners: React.FC<IPartnersProps> = (props: IPartnersProps) => {
  const { classes } = props;
  return (
    <div className={classNames(classes.main)}>
      <div className={classes.container}>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <h2 className={classes.title}>Official Partner</h2>
              <h5 className={classes.title}>
                Parking charges are free for customers of our official partners
              </h5>
            </GridItem>
          </GridContainer>
          <div>
            <GridContainer style={{justifyContent: "center"}}>
              <GridItem xs={12} sm={12} md={4}>
                <div className={classes.infoArea}>
                  <div className={classes.sectionWrapper}>
                    <img src={RASILogo} alt="RASI" width={100} height={100}/>
                  </div>
                  <h5 className={classes.title}>
                    Kapaleeshwarar Sannathi street, Mylapore
                  </h5>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <div className={classes.infoArea}>
                  <div className={classes.sectionWrapper}>
                    <img src={SangeethaLogo} alt="Sangeetha" width={200} height={100}/>
                  </div>
                  <h5 className={classes.title}>
                    South Mada Street, Mylapore
                  </h5>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(sectionStyle as any)(Partners);

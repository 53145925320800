import { withStyles } from "@material-ui/core";
import React from "react";
import withRoot from "../../../../WithRoot";
import termsAndConditionsPageStyle from "../../styles/TermsAndConditionsStyle";

interface IUserGoverningProps {
  classes?: any;
}
class UserGoverningPage extends React.Component<IUserGoverningProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4 className={classes.title}>14. Governing Law and Jurisdiction</h4>
        <p className={classes.description}>
          (a) Any dispute, controversy or claim arising out of or relating to this Terms or the validity,
          interpretation, breach or termination thereof (“Dispute”), including claims seeking redress or
          asserting rights under the Applicable Law shall be amicably settled through mutual consultation
          and escalation at such offices of WannaPark as WannaPark may designate. If the Dispute is not settled
          amicably as aforesaid within a period of 14 (Fourteen) calendar days, the matter would be referred
          to arbitration in accordance with the provisions of the Arbitration and Conciliation Act, 1996.
          Arbitration shall be presided over by a sole arbitrator mutually appointed by Us and the Customer.
        </p>
        <p className={classes.description}>
          (b) The arbitration proceedings shall be conducted in the English language. The venue for the
          arbitration proceedings shall be New Delhi.
        </p>
        <p className={classes.description}>
          (c) This Terms shall be governed by and construed in accordance with the laws of India and,
          subject to this Clause 29, We and You agree and undertake that any controversy or claim arising out
          of or relating to these Terms will be adjudicated exclusively before a competent court in Chennai,
          TamilNadu, India only.
        </p>
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(UserGoverningPage as any));

import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";
import { IRentSpaceRequest } from "../interfaces/RentSpaceInterface";

const { Types, Creators } = createActions({
  getRentRequest: null,
  saveRentRequest: ["data"],
  setIsFetching: ["isFetching"],
  setIsSaveSuccessful: ["isSaveSuccessful"],
  setIsSaving: ["isSaving"],
  setRentRequests: ["requests"]
});

export const RentSpaceReduxTypes = Types;
export default Creators;

export interface IRentSpaceReduxState {
  isFetching: boolean;
  isSaveSuccessful: boolean;
  isSaving: boolean;
  rentRequests: IRentSpaceRequest[] | null;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  isFetching: false,
  isSaveSuccessful: false,
  isSaving: false,
  rentRequests: null,
} as IRentSpaceReduxState);

/* ------------- Reducers ------------- */
export const setIsFetching = (state: Immutable.ImmutableObject<IRentSpaceReduxState>,
                              { isFetching }: {isFetching: boolean}) => (
state.merge({ isFetching })
);

export const setIsSaveSuccessful = (state: Immutable.ImmutableObject<IRentSpaceReduxState>,
                                    { isSaveSuccessful }: {isSaveSuccessful: boolean}) => (
  state.merge({ isSaveSuccessful })
);

export const setIsSaving = (state: Immutable.ImmutableObject<IRentSpaceReduxState>,
                            { isSaving }: {isSaving: boolean}) => (
  state.merge({ isSaving })
);

export const setRentRequests = (state: Immutable.ImmutableObject<IRentSpaceReduxState>,
                                { requests }: {requests: IRentSpaceRequest[]}) => (
  state.merge({ rentRequests: requests })
);

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<Immutable.ImmutableObject<IRentSpaceReduxState>, any>(INITIAL_STATE, {
  [Types.SET_IS_FETCHING]: setIsFetching,
  [Types.SET_IS_SAVE_SUCCESSFUL]: setIsSaveSuccessful,
  [Types.SET_IS_SAVING]: setIsSaving,
  [Types.SET_RENT_REQUESTS]: setRentRequests,
});

import { withStyles } from "@material-ui/core";
import React from "react";
import withRoot from "../../../../WithRoot";
import termsAndConditionsPageStyle from "../../styles/TermsAndConditionsStyle";

interface IUserComplaintsProps {
  classes?: any;
}
class UserComplaintsPage extends React.Component<IUserComplaintsProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4 className={classes.title}>6. Complaints and Disputes</h4>
        <p className={classes.description}>
          (a) You agree that if in the event of any dispute, claim or issue arising concerning
          the Parking Space or otherwise with the Owner before, during or after the Licence Period
          (a “Dispute”) you will notify the Owner immediately and that you will attempt to resolve
          such Dispute directly with the Owner.
        </p>
        <p className={classes.description}>
          (b) You have no right to involve or call upon WannaPark.in to intervene in any Dispute. WannaPark.in
          is not responsible for resolving any Dispute.
        </p>
        <p className={classes.description}>
          (c) Despite sub-clause (b) above, you shall notify us promptly of any Dispute.
        </p>
        <p className={classes.description}>
          (d) Despite sub-clause (b) above, we may (in our absolute discretion) agree to forward or issue an
          invoice to the Owner on your behalf in respect of any sums you claim are due to you from the Owner
          (including, but not limited to, the costs of finding alternative parking where a Parking Space
          was unavailable). Where we agree to issue or forward an invoice on your behalf, we are not responsible for the
          collection of the sums due under the invoice, and shall have no liability to you in respect of
          the non-payment of the invoice by the Owner. In particular, we shall not be obliged to pay any sums to
          you out of monies held by us on behalf of the Owner or to deduct any sums from the balance of the
          Owner’s account on www.wannapark.in. You shall remain solely responsible for collecting any
          sums due under the invoice.
        </p>
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(UserComplaintsPage as any));

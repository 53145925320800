import firebase from "firebase/app";
import { isNullOrUndefined } from "util";
import firebaseApp from "../config/FirebaseConfig";
import { debugLog } from "./LogService";

export const checkAuthState = () => {
  return new Promise((resolve, reject) => {
    const unSubscribe = firebaseApp.auth().onAuthStateChanged((user: firebase.User | null) => {
      if (unSubscribe) { unSubscribe(); }
      if (user) {
        debugLog({k: "checkAuthState_Service", user});
        resolve(user);
      } else {
        reject("UnAuthorized");
      }
    });
  });
};

export const confirmOtp = (otpVerifier: firebase.auth.ConfirmationResult, code: string) => {
  return otpVerifier.confirm(code);
};

export const getAuthToken = (): Promise<string | null> => {
  const currentUser = firebaseApp.auth().currentUser;
  if (!isNullOrUndefined(currentUser)) {
    return currentUser.getIdToken(true);
  } else {
    return Promise.resolve(null);
  }
};

export const signInWithPhoneNumber = (phoneNumber: string, captchaCB: () => void) => {
  const recaptchaVerifier = new firebase.auth.RecaptchaVerifier("btnVerifyPhoneNo", {
    callback: () => {
      // reCAPTCHA solved, allow signInWithPhoneNumber.
      captchaCB();
    },
    size: "invisible",
  });
  return firebaseApp.auth().signInWithPhoneNumber(phoneNumber, recaptchaVerifier);
};

export const signOut = () => {
  return firebase.auth().signOut();
};

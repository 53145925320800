import { withStyles } from "@material-ui/core";
import React from "react";
import withRoot from "../../../../WithRoot";
import termsAndConditionsPageStyle from "../../styles/TermsAndConditionsStyle";

interface IParkingSpaceProps {
  classes?: any;
}
class ParkingSpacePage extends React.Component<IParkingSpaceProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4 className={classes.title}>5. The Parking Space</h4>
        <p className={classes.description}>
        (a) You warrant and agree that:
          <p className={classes.subDescription}>
          (i) the details of the Parking Space listed on the Site are true complete and accurate;
          </p>
          <p className={classes.subDescription}>
          (ii) the Parking Space may be accessed easily by the User and is not obstructed at the start of
          or during the Licence Period;
          </p>
          <p className={classes.subDescription}>
          (iii) the Parking Space is clean, tidy and clear of rubbish at the start of the Licence Period;
          </p>
          <p className={classes.subDescription}>
          (iv) you will act with courtesy towards the User and assist and cooperate with the User and,
          if applicable, assist in locating the Parking Space; and
          </p>
          <p className={classes.subDescription}>
          (v) you will indemnify and hold harmless the User against all loss, liability, damages,
          costs and expenses arising from your negligence in connection with the Parking Space, except to
          the extent that such loss, liability, damages, costs or expenses are caused by the negligent acts
          or omissions of the User.
          </p>
        </p>
        <p className={classes.description}>
          (b) You represent and warrant that you own the Parking Space or have the legal authority or you
          are authorised or have permission to allow third parties to use the Parking Space and, where necessary,
          you have permission from your landlord (or other persons who control any building of which the
          Parking Space is a part) to do so. If you are in any doubt you should check the terms of your
          lease (or sub-lease), freehold title, mortgage, deed of trust, or any other documents of record
          to ensure that you are able to grant a licence to the User to use the Parking Space. You further agree
          that you are responsible for ensuring that any person who assists you with placing an advert on the Site
          complies with these Terms.
        </p>
        <p className={classes.description}>
          (c) We will not be liable to you, the User or any other third party (such as a landlord or any other
          persons who control any building of which the Parking Space is a part or management company) if you do
          not have the necessary authority to allow third parties to use the Parking Space.
        </p>
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(ParkingSpacePage as any));

import { put } from "redux-saga/effects";
import { IReducers } from "../redux";
import RentActions from "../redux/RentSpaceRedux";
import { debugLog } from "../services/LogService";

export function * startupRehydrate({ payload }: { payload: IReducers }) {
  debugLog({class: "StartupSaga", name: "startupRehydrate", payload});
  yield put(RentActions.setIsSaving(false));
  yield put(RentActions.setIsSaveSuccessful(false));
}

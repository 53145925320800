import { green, grey, red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

const rawTheme = createMuiTheme({
  palette: {
    error: {
      dark: red[700],
      light: red[50],
      main: red[500],
    },
    primary: {
      dark: "#c7c7c7", // '#c9bc1f',
      light: "#ffffff", // '#ffff8b',
      main: "#fafafa", // '#ffee58',
    },
    secondary: {
      dark: "#1e1e1f",
      light: "#69696a",
      main: "#3C4858",
    },
  },
  typography: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300, // Work Sans
    fontWeightMedium: 700, // Roboto Condensed
    fontWeightRegular: 400, // Work Sans
  },
});

const fontHeader = {
  color: rawTheme.palette.text.secondary,
  fontFamily: `"Roboto Slab", "Times New Roman", serif`,
  fontWeight: rawTheme.typography.fontWeightMedium,
};

const theme = {
  ...rawTheme,
  container: {
    "@media (min-width: 1200px)": {
      maxWidth: "1340px"
    },
    "@media (min-width: 576px)": {
      maxWidth: "540px"
    },
    "@media (min-width: 768px)": {
      maxWidth: "720px"
    },
    "@media (min-width: 992px)": {
      maxWidth: "960px"
    },
    "paddingLeft": "15px",
    "paddingRight": "15px",
    "width": "100%",
  },
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      default: rawTheme.palette.common.white,
      placeholder: grey[200],
    },
    success: {
      dark: green[700],
      xLight: green[50],
    },
    warning: {
      dark: "#ffb25e",
      main: "#ffc071",
    },
  },
  typography: {
    ...rawTheme.typography,
    body1: {
      ...rawTheme.typography.body2,
      fontSize: 16,
      fontWeight: rawTheme.typography.fontWeightRegular,
    },
    body2: {
      ...rawTheme.typography.body1,
      fontSize: 14,
    },
    button: {
      fontFamily: `"Roboto Condensed", "Arial", serif`,
    },
    caption: {
      fontSize: 12,
      textTransform: "uppercase",
    },
    fontHeader,
    h1: {
      ...rawTheme.typography.h1,
      ...fontHeader,
      fontSize: 60,
      letterSpacing: 0,
    },
    h2: {
      ...rawTheme.typography.h2,
      ...fontHeader,
      fontSize: 48,
    },
    h3: {
      ...rawTheme.typography.h3,
      ...fontHeader,
      fontSize: 42,
    },
    h4: {
      ...rawTheme.typography.h4,
      ...fontHeader,
      color: "#3C4858",
      fontSize: 36,
    },
    h5: {
      ...rawTheme.typography.h5,
      fontSize: 20,
      fontWeight: rawTheme.typography.fontWeightLight,
    },
    h6: {
      ...rawTheme.typography.h6,
      fontSize: 18,
      fontWeight: rawTheme.typography.fontWeightMedium,
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      fontSize: 18,
    },
  },
};

export default theme;

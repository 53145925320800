import { container } from "../../../themes/jss/material-kit-react";
import customCheckboxRadioSwitch from "../../../themes/jss/material-kit-react/customCheckboxRadioSwitch";
import imagesStyle from "../../../themes/jss/material-kit-react/imagesStyles";

const signupPageStyle = {
  cardFooter: {
    border: "0",
    borderRadius: "6px",
    justifyContent: "center !important",
    paddingTop: "0rem",
  },
  cardHeader: {
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "-100px",
    textAlign: "center",
    width: "auto",
    // padding: "20px 0",
    // marginBottom: "15px"
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)"
  },
  container: {
    ...container,
    color: "#FFFFFF",
    paddingTop: "20vh",
    position: "relative",
    zIndex: "2",
  },
  divider: {
    marginBottom: "0px",
    marginTop: "30px",
    textAlign: "center"
  },
  form: {
    margin: "0"
  },
  inputIconsColor: {
    color: "#495057"
  },
  pageHeader: {
    "& footer": {
      bottom: "0",
      position: "absolute",
      width: "100%",
    },
    "& footer li a,& footer li a:hover,& footer li a:active": {
      color: "#FFFFFF"
    },
    "&:before": {
      background: "rgba(0, 0, 0, 0.5)"
    },
    "&:before,&:after": {
      content: '""',
      display: "block",
      height: "100%",
      left: "0",
      position: "absolute",
      top: "0",
      width: "100%",
      zIndex: "1",
    },
    "alignItems": "center",
    "border": "0",
    "display": "inherit",
    "height": "auto",
    "margin": "0",
    "minHeight": "100vh",
    "padding": "0",
    "position": "relative",
  },
  socialIcons: {
    fontSize: "20px",
    height: "100%",
    left: "0",
    lineHeight: "41px",
    marginTop: "0",
    maxWidth: "24px",
    top: "0",
    transform: "none",
    width: "100%",
  },
  socialLine: {
    marginTop: "1rem",
    padding: "0",
    textAlign: "center",
  },
  ...customCheckboxRadioSwitch,
  ...imagesStyle
};

export default signupPageStyle;

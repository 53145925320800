import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "clsx";
import * as React from "react";
import cardFooterStyle from "./styles/cardFooterStyle";

// tslint:disable-next-line:typedef
function CardFooter({ ...props }) {
  const { classes, className, children, ...rest } = props;
  const cardFooterClasses = classNames({
    [classes.cardFooter]: true,
    [className]: className !== undefined
  });
  return (
    <div className={cardFooterClasses} {...rest}>
      {children}
    </div>
  );
}

// CardFooter.propTypes = {
//   classes: PropTypes.object.isRequired,
//   className: PropTypes.string
// };

export default withStyles(cardFooterStyle)(CardFooter);

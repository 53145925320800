import { withStyles } from "@material-ui/core";
import React from "react";
import withRoot from "../../../../WithRoot";
import termsAndConditionsPageStyle from "../../styles/TermsAndConditionsStyle";

interface IYourLiabilityProps {
  classes?: any;
}
class YourLiabilityPage extends React.Component<IYourLiabilityProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4 className={classes.title}>11. Your Liability</h4>
        <p className={classes.description}>
        (a) You agree to defend, indemnify and hold harmless WannaPark.in, its affiliates, and their
        respective officers, directors, managers, employees and agents (“WannaPark.in Indemnitees”) from and
        against any and all claims, damages, obligations, losses, liabilities, costs,
        and expenses (including but not limited to legal and other professional fees) arising from:
          <p className={classes.subDescription}>
            (i) your actions or omissions (or alleged actions or omissions) in relation to the Service,
            the Site, the Parking Space or the Booking;
          </p>
          <p className={classes.subDescription}>
            (ii) your breach or alleged breach of any term of this Agreement;
          </p>
          <p className={classes.subDescription}>
            (iii) breach of the warranties you give in clauses 5(b) and 6(a);
          </p>
          <p className={classes.subDescription}>
            (iv) your breach or alleged breach of any third-party right, including without limitation
            any copyright, property, publicity or privacy right;
          </p>
          <p className={classes.subDescription}>
            (v) any claim that any content uploaded by you to the Site caused damage to a third party;
          </p>
          <p className={classes.subDescription}>
            (vi) your breach or alleged breach of any laws or regulations; and
          </p>
          <p className={classes.subDescription}>
            (vii) your breach or alleged breach of the Parking Service Agreement.
          </p>
        </p>
        <p className={classes.description}>
          (b) This clause 11 will survive the termination of this Agreement.
        </p>
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(YourLiabilityPage as any));

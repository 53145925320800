const config = {
  appUrl: "https://www.wannapark.in",
  googleApiKey: "AIzaSyCpxUvmuyMtScH3w5GPBvZ9yKUXrE6n60Y",
  googleDirectionsApi: "https://maps.googleapis.com/maps/api/directions/json",
  // tslint:disable-next-line: max-line-length
  googleMapApi: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBFMdhpg-6Vala6yG37TNqP1ZmK-w76xLs&v=3&libraries=geometry,places",
  maxZoom: 17,
  // url: "http://localhost:5000/wannapark-5ae61/us-central1/webclientApi/graphql", // local-prod
  // url: "https://us-central1-wannapark-6971c.cloudfunctions.net/webclientApi/graphql" // server-prod
  url: "https://us-central1-wannapark-5ae61.cloudfunctions.net/webclientApi/graphql" // server-prod
};

export default config;

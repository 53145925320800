import { withStyles } from "@material-ui/core";
import React from "react";
import withRoot from "../../../../WithRoot";
import termsAndConditionsPageStyle from "../../styles/TermsAndConditionsStyle";

interface IUserRestrictionProps {
  classes?: any;
}
class UserRestrictionPage extends React.Component<IUserRestrictionProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4 className={classes.title}>9. Restrictions on you</h4>
        <p className={classes.description}>
        (a) You shall not, whilst you are registered to use our Site or for a period of 3 months after the
        date upon which cease to be registered, contact any Owner directly to agree, or attempt to agree,
        to licence or rent any parking space currently advertised on our Site without using the booking
        facility provided on our Site.
        </p>
        <p className={classes.description}>
        (b) This clause 9 shall survive termination of this Agreement.
        </p>
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(UserRestrictionPage as any));

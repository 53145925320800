import { container, title } from "../../../themes/jss/material-kit-react";

const rentSpacePageStyle = {
  center: {
    textAlign: "center",
  },
  container: {
    color: "#FFFFFF",
    zIndex: "12",
    ...container
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    margin: "-60px 30px 0px",
  },
  subtitle: {
    fontSize: "1.313rem",
    margin: "10px auto 0",
    maxWidth: "500px",
  },
  title: {
    ...title,
    color: "#FFFFFF",
    display: "inline-block",
    marginTop: "30px",
    minHeight: "32px",
    position: "relative",
    textDecoration: "none"
  },
};

export default rentSpacePageStyle;

import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import ReduxPersist from "../config/ReduxPersistConfig";
import RehydrationService from "../services/RehydrationService";

// creates the store
export default (rootReducer: any, rootSaga: any) => {
  /* ------------- Redux Configuration ------------- */
  const middleware = [];
  const enhancers = [];

  /* ------------- Saga Middleware ------------- */
  const sagaMiddleware = createSagaMiddleware();
  middleware.push(sagaMiddleware);

  /* ------------- Assemble Middleware ------------- */

  enhancers.push(applyMiddleware(...middleware));

  // if Reactotron is enabled (default for __DEV__), we'll create the store through Reactotron
  const store = createStore(rootReducer, compose(...enhancers));

  // configure persistStore and check reducer version number
  if (ReduxPersist.active) {
    RehydrationService.updateReducers(store);
  }
  // kick off root saga
  const sagasManager = sagaMiddleware.run(rootSaga);

  return {
    sagaMiddleware,
    sagasManager,
    store,
  };
};

import { withStyles } from "@material-ui/core";
import classNames from "clsx";
import React from "react";
// import GeneralAgreement from "./GeneralAgreement";
import ReactMarkdown from "react-markdown";
import withRoot from "../../WithRoot";
import termsAndConditionsPageStyle from "./styles/TermsAndConditionsStyle";
import TermsAndConditions from "./Terms";

interface ITermsAndConditionsProps {
  classes?: any;
}
class TermsAndConditionsPage extends React.Component<ITermsAndConditionsProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classNames(classes.main, classes.mainRaised)}>
        {/* <GeneralAgreement /> */}
          <ReactMarkdown source={TermsAndConditions} escapeHtml={false} />
        <br />
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(TermsAndConditionsPage as any));

// import { Hidden } from "@material-ui/core";
import { Hidden } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import GridContainer from "../../../components/Grids/GridContainer";
import GridItem from "../../../components/Grids/GridItem";
// import { Fade }  from 'react-reveal';
// import DropOffScreen from 'src/assets/img/DropOffScreen.png';
// import ReturnScreen from 'src/assets/img/ReturnScreen.png';
// import TrackCarScreen from 'src/assets/img/TrackCarScreen.png';
import HowItWorksPoster from "../../../themes/img/HowItWorksPoster.png";
import HowItWorksVideo from "../../../themes/videos/HowItWorks.mp4";
import HowItWorksVideoSmall from "../../../themes/videos/HowItWorksSmall.mp4";
// import HowItWorksBetaPoster from "../../../themes/img/HowItWorksBetaPoster.png";
// import HowItWorksBetaRelease from "../../../themes/videos/HowItWorksBetaRelease.mp4";
import sectionStyle from "../styles/SectionStyle";

interface IHowItWorkSectionsProps {
  classes?: any;
}
class HowItWorksSection extends React.Component<IHowItWorkSectionsProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h2 className={classes.title}>Here's how it works</h2>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <div>
                <Hidden mdDown>
                  <video
                    className={classes.parallaxVideo}
                    width={"auto"}
                    height={480}
                    autoPlay
                    loop
                    playsInline
                    poster={HowItWorksPoster}
                    // poster={HowItWorksBetaPoster}
                    preload="auto"
                    muted
                  >
                    <source src={HowItWorksVideoSmall} type="video/mp4" />
                    {/* <source src={HowItWorksBetaRelease} type="video/mp4" /> */}
                  </video>
                </Hidden>
                <Hidden mdUp>
                  <video
                    className={classes.parallaxVideo}
                    width={300}
                    height={"auto"}
                    autoPlay
                    loop
                    playsInline
                    preload="auto"
                    poster={HowItWorksPoster}
                    muted
                  >
                    <source src={HowItWorksVideo} type="video/mp4" />
                  </video>
                </Hidden>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        {/* <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={5}>
              <h2 className={classes.title}>Convenient Drop Off</h2>
              <h5 className={classes.description}>
                Drive to your destination and confirm your drop to one of our experienced valets will be available
                at convenient drop off spots close to your favorite destination.
              </h5>
            </GridItem>
            <GridItem xs={12} sm={12} md={7}>
              <Fade bottom>
                <img src={DropOffScreen} alt="..." width={130} height={345} className={imageClasses} />
              </Fade>
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={7}>
              <Fade left>
                <img src={TrackCarScreen} alt="..." width={130} height={345} className={imageClasses} />
              </Fade>
            </GridItem>
            <GridItem xs={12} sm={12} md={5}>
              <h2 className={classes.title}>Safe and Secured</h2>
              <h5 className={classes.description}>
                Our trained valets will park your car in one of our secure lots and to top it you
                can also track your car from the app.
              </h5>
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={5}>
              <h2 className={classes.title}>Faster Returns</h2>
              <h5 className={classes.description}>
              When you’re ready to leave, tell us where you want your car returned within our service zone
              and we’ll deliver it! Schedule your return in advance to shorten your wait time..
              </h5>
            </GridItem>
            <GridItem xs={12} sm={12} md={7}>
              <Fade right>
                <img src={ReturnScreen} alt="..." width={130} height={345} className={imageClasses} />
              </Fade>
            </GridItem>
          </GridContainer>
        </div> */}
      </div>
    );
  }
}

export default withStyles(sectionStyle as any)(HowItWorksSection);

import { Typography, withStyles } from "@material-ui/core";
import classNames from "clsx";
import React from "react";
import { LinksEnum } from "../../literals/Enums";
import withRoot from "../../WithRoot";
import OwnerResponsibility from "./Sections/UserAgreement/OwnerResponsibility";
import ParkingSpaceLicence from "./Sections/UserAgreement/ParkingSpaceLicence";
import UserAgreementTerms from "./Sections/UserAgreement/UserAgreementTerms";
import UserBooking from "./Sections/UserAgreement/UserBooking";
import UserCancellation from "./Sections/UserAgreement/UserCancellation";
import UserComplaints from "./Sections/UserAgreement/UserComplaints";
import UserDisclaimers from "./Sections/UserAgreement/UserDisclaimers";
import UserGeneral from "./Sections/UserAgreement/UserGeneral";
import UserGoverningLaw from "./Sections/UserAgreement/UserGoverningLaw";
import UserIndemnity from "./Sections/UserAgreement/UserIndemnity";
import UserLiability from "./Sections/UserAgreement/UserLiability";
import UserRegistration from "./Sections/UserAgreement/UserRegistration";
import UserRestriction from "./Sections/UserAgreement/UserRestriction";
import UserTermination from "./Sections/UserAgreement/UserTermination";
import termsAndConditionsPageStyle from "./styles/TermsAndConditionsStyle";

interface IUserAgreementProps {
  classes?: any;
}
class UserAgreementPage extends React.Component<IUserAgreementProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <Typography className={classes.mainTitle} variant="h5" component="h3">
            User Agreement
          </Typography>
          <Typography component="p">
            This Agreement sets out the terms and conditions that apply to the service provided directly
            by WannaPark Technologies Private Limited (trading as www.wannapark.in) to you namely
            allowing you to book and pay for parking spaces (“the Service”) from Parking
            Space Owners (“the Owner”). The granting of a licence to use parking spaces advertised
            by an Owner who has advertised their Parking Spaces on the Website (“the Site”)
            is dealt with under the terms of the
            <a href={LinksEnum.ownerAgreement}> Owner agreement </a> and is
            between you and the Owner to which www.wannapark.in is not a party. This agreement should be read in
            conjunction with the <a href={LinksEnum.terms}> Terms and Conditions </a>,
            other Agreements and Policies on this Site.
          </Typography>
          <UserAgreementTerms />
          <UserBooking />
          <UserRegistration />
          <OwnerResponsibility />
          <ParkingSpaceLicence />
          <UserComplaints />
          <UserCancellation />
          <UserTermination />
          <UserRestriction />
          <UserDisclaimers />
          <UserIndemnity />
          <UserLiability />
          <UserGeneral />
          <UserGoverningLaw />
          <br />
        </div>
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(UserAgreementPage as any));

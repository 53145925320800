import { FormHelperText } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "clsx";
import * as React from "react";
import customInputStyle from "./styles/CustomInputStyle";

interface ICustomInputProps {
  classes: any;
  labelText?: string;
  labelProps?: any;
  id?: string;
  inputProps?: any;
  formControlProps?: any;
  inputRootCustomClasses?: string;
  error?: boolean;
  success?: boolean;
  white?: boolean;
  helperText?: string;
}
const CustomInput: React.FC<ICustomInputProps> = (props: ICustomInputProps) => {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    helperText
  } = props;

  const helperTextClasses = classNames({
    [" " + classes.helperTextRootError]: error,
    [" " + classes.helperTextRootSuccess]: success && !error
  });
  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white
  });
  const marginTop = classNames({
    [inputRootCustomClasses || ""]: inputRootCustomClasses !== undefined
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white
  });
  let formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + " " + labelClasses}
          htmlFor={id}
          error
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Input
        classes={{
          disabled: classes.disabled,
          input: inputClasses,
          root: marginTop,
          underline: underlineClasses
        }}
        id={id}
        {...inputProps}
      />
      <FormHelperText
        id={`${id}-error-text`}
        className={classes.helperTextRoot + " " + helperTextClasses}
      >
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default withStyles(customInputStyle as any)(CustomInput);

/*!

 =========================================================
 * Material Kit React - v1.3.0 based on Material Kit - v2.0.2
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-react
 * Copyright 2018 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

 =========================================================

  * The above copyright notice and this permission notice shall be included in all copies or
    substantial portions of the Software.

 */

// ##############################
// // // Variables - Styles that are used on more than one component
// #############################

const drawerWidth = 260;

const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
};

const conatinerFluid = {
  paddingLeft: "15px",
  paddingRight: "15px",
  width: "100%"
};
const container = {
  ...conatinerFluid,
  "@media (min-width: 576px)": {
    maxWidth: "540px"
  },
  "@media (min-width: 768px)": {
    maxWidth: "720px"
  },
  "@media (min-width: 992px)": {
    maxWidth: "960px"
  },
  // tslint:disable-next-line: object-literal-sort-keys
  "@media (min-width: 1200px)": {
    maxWidth: "1340px"
  }
};

const boxShadow = {
  boxShadow:
    "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
};

const card = {
  background: "#fff",
  borderRadius: "3px",
  boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
  color: "rgba(0, 0, 0, 0.87)",
  display: "inline-block",
  margin: "25px 0",
  position: "relative",
  width: "100%",
};

const defaultFont = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: "300",
  lineHeight: "1.5em"
};

const primaryColor = "#2196f3";
const warningColor = "#ff9800";
const dangerColor = "#f44336";
const successColor = "#4caf50";
const infoColor = "#00acc1";
const roseColor = "#e91e63";
const grayColor = "#424242"; // "#999999";
const brandColor = "#ffeb3b";
const brandTextColor = "#212121";

const primaryBoxShadow = {
  boxShadow:
    // tslint:disable-next-line: max-line-length
    "0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)"
};
const infoBoxShadow = {
  boxShadow:
  // tslint:disable-next-line: max-line-length
    "0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2)"
};
const successBoxShadow = {
  boxShadow:
  // tslint:disable-next-line: max-line-length
    "0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)"
};
const warningBoxShadow = {
  boxShadow:
  // tslint:disable-next-line: max-line-length
    "0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)"
};
const dangerBoxShadow = {
  boxShadow:
  // tslint:disable-next-line: max-line-length
    "0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)"
};
const roseBoxShadow = {
  boxShadow:
    "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)"
};

const warningCardHeader = {
  background: "linear-gradient(60deg, #ffa726, #fb8c00)",
  color: "#fff",
  ...warningBoxShadow
};
const successCardHeader = {
  background: "linear-gradient(60deg, #66bb6a, #43a047)",
  color: "#fff",
  ...successBoxShadow
};
const dangerCardHeader = {
  background: "linear-gradient(60deg, #ef5350, #e53935)",
  color: "#fff",
  ...dangerBoxShadow
};
const infoCardHeader = {
  background: "linear-gradient(60deg, #26c6da, #00acc1)",
  color: "#fff",
  ...infoBoxShadow
};
const primaryCardHeader = {
  background: "linear-gradient(60deg, #ab47bc, #8e24aa)",
  color: "#fff",
  ...primaryBoxShadow
};
const roseCardHeader = {
  background: "linear-gradient(60deg, #ec407a, #d81b60)",
  color: "#fff",
  ...roseBoxShadow
};
const cardActions = {
  borderTop: "1px solid #eeeeee",
  height: "auto",
  margin: "0 20px 10px",
  paddingTop: "10px",
  ...defaultFont
};

const cardHeader = {
  borderRadius: "3px",
  margin: "-30px 15px 0",
  padding: "15px"
};

const defaultBoxShadow = {
  border: "0",
  borderRadius: "3px",
  boxShadow:
    "0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  padding: "10px 0",
  transition: "all 150ms ease 0s"
};

const title = {
  color: "#3C4858",
  fontFamily: `"Roboto Slab", "Times New Roman", serif`,
  fontWeight: "700",
  margin: "1.75rem 0 0.875rem",
  textDecoration: "none",
};

const cardTitle = {
  ...title,
  marginTop: ".625rem"
};

const cardLink = {
  "& + $cardLink": {
    marginLeft: "1.25rem"
  }
};

const cardSubtitle = {
  marginBottom: "0",
  marginTop: "-.375rem"
};

export {
  drawerWidth,
  transition,
  container,
  conatinerFluid,
  boxShadow,
  card,
  defaultFont,
  brandColor,
  brandTextColor,
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  primaryBoxShadow,
  infoBoxShadow,
  successBoxShadow,
  warningBoxShadow,
  dangerBoxShadow,
  roseBoxShadow,
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  cardActions,
  cardHeader,
  defaultBoxShadow,
  title,
  cardTitle,
  cardLink,
  cardSubtitle
};

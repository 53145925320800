import { withStyles } from "@material-ui/core";
import React from "react";
import withRoot from "../../../../WithRoot";
import termsAndConditionsPageStyle from "../../styles/TermsAndConditionsStyle";

interface ITerminationProps {
  classes?: any;
}
class TerminationPage extends React.Component<ITerminationProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4 className={classes.title}>9. Termination</h4>
        <p className={classes.description}>
        (a) Either party may terminate this Agreement at any time by giving notice to the other.
        If you wish to terminate this Agreement you must contact us by email notifying us that you wish
        to terminate the agreement. Upon termination you agree to honour any outstanding Bookings.
        From the date of termination we will not confirm or accept any new bookings for the Parking Space
        but the Agreement will continue in respect of those outstanding Bookings (subject to clause 9(e)).
        </p>
        <p className={classes.description}>
        (b) In the event that this Agreement is terminated while any fees or charges whatsoever are
        outstanding to us then you must pay all outstanding fees or charges to us immediately without
        set-off or deduction.
        </p>
        <p className={classes.description}>
        (c) Without limitation to clause 9(a) we may terminate this Agreement immediately if:
          <p className={classes.subDescription}>
            (i) you are in material breach of any of the terms of this Agreement; or
          </p>
          <p className={classes.subDescription}>
            (ii) you do anything to put our goodwill or reputation at risk; or
          </p>
          <p className={classes.subDescription}>
            (iii) we have any reason to believe that you are not authorised to grant a licence
            to use the Parking Space; or
          </p>
          <p className={classes.subDescription}>
            (iv) you cancel a booking other than as permitted by this Agreement;
          </p>
          <p className={classes.subDescription}>
            (v) you refuse to co-operate with us in respect of this Agreement.
          </p>
        </p>
        <p className={classes.description}>
        (d) In the event of termination of this Agreement, those terms which expressly or by implication
        survive termination shall continue in full force and effect, and the Agreement shall also continue
        in relation to existing Bookings.
        </p>
        <p className={classes.description}>
        (e) In the event that:
          <p className={classes.subDescription}>
            (i) this Agreement is terminated by you and existing Bookings cannot proceed under clause 9(a)
            (e.g. because you sell the property to which the Parking Space is attached); or
          </p>
          <p className={classes.subDescription}>
            (ii) this Agreement is terminated by us under clause 10(c) while there are existing Bookings;
            then we may cancel any such Bookings on your behalf, and you will be fully liable to us for
            all associated costs, charges, damage and liability which we may incur as a result (including any
            refunds we make to User who have a Booking for the Parking Space) or in connection with such
            Bookings or their cancellation and you shall indemnify us in full on a continuing basis in respect
            of any such sums.
          </p>
        </p>
        <p className={classes.description}>
          (f) If any Bookings are cancelled under clause 9(e), clause 4(b) shall apply as
          if you had cancelled a Booking.
        </p>
        <p className={classes.description}>
          (g) This clause 10 shall survive the termination of this Agreement.
        </p>
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(TerminationPage as any));

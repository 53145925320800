import { withStyles } from "@material-ui/core";
import React from "react";
import withRoot from "../../../../WithRoot";
import termsAndConditionsPageStyle from "../../styles/TermsAndConditionsStyle";

interface IUserRegistrationProps {
  classes?: any;
}
class UserRegistrationPage extends React.Component<IUserRegistrationProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4 className={classes.title}>3. Registration</h4>
        <p className={classes.description}>
          (a) In order to make a Booking you must register an Account as set out here www.wannapark.in/registration.
        </p>
        <p className={classes.description}>
          (b) Once you have created an Account you will need to provide us with the registration number of the
          vehicle if you are an individual and making a Booking for self and any other relevant additional
          information we may need from you.
        </p>
        <p className={classes.description}>
          (c) if you are an individual representing a business or any organisation then relevant information such as
          number of spaces, valet service and any other additional services needs to be mentioned.
        </p>
        <p className={classes.description}>
          (d) Booking confirmations and other relevant information in relation to the Services will be sent to the
          email address you give us (should you provide us with an email address), or at the time you make the Booking
          in the format you made the Booking (e.g. if you make a booking at a kiosk, through the kiosk or if you make
          a booking using our automated phone system, over the phone) (“Booking Confirmation”).
        </p>
        <p className={classes.description}>
          (e) Please make sure this is right as we will not be responsible if this email address is incorrect and
          you do not receive a Booking Confirmation or other information from us that you might be expecting.
          If you notice that your email address is incorrect please contact us to correct this.
        </p>
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(UserRegistrationPage as any));

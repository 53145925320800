import firebase from "firebase";

const prodConfig = {
  apiKey: "AIzaSyCaFMf-T-Az9TU_Y7ECuKLl_mdrEaeUm3I",
  appId: "1:480135295019:web:9082577e068ef8a7",
  authDomain: "wannapark-5ae61.firebaseapp.com",
  databaseURL: "https://wannapark-5ae61.firebaseio.com",
  projectId: "wannapark-5ae61",
};

if (!firebase.apps.length) {
  firebase.initializeApp(prodConfig);
}

export default firebase;

import withStyles from "@material-ui/core/styles/withStyles";
import { SvgIconComponent } from "@material-ui/icons";
import classNames from "clsx";
import * as React from "react";
import { dangerColor, grayColor, infoColor, primaryColor, roseColor, successColor, title,
    warningColor } from "../../themes/jss/material-kit-react";

const infoStyle = {
  danger: {
    color: dangerColor
  },
  description: {
    color: grayColor,
    fontSize: "14px",
    marginTop: "0px",
    overflow: "hidden",
  },
  descriptionWrapper: {
    color: grayColor,
    overflow: "hidden"
  },
  gray: {
    color: grayColor
  },
  icon: {
    height: "36px",
    width: "36px",
  },
  iconVertical: {
    height: "61px",
    width: "61px",
  },
  iconWrapper: {
    float: "left",
    marginRight: "10px",
    marginTop: "24px",
  },
  iconWrapperVertical: {
    float: "none"
  },
  info: {
    color: infoColor
  },
  infoArea: {
    margin: "0 auto",
    maxWidth: "360px",
    padding: "0px"
  },
  primary: {
    color: primaryColor
  },
  rose: {
    color: roseColor
  },
  success: {
    color: successColor
  },
  title,
  warning: {
    color: warningColor
  },
};

interface IInfoAreaProps {
  classes: any;
  description: string;
  heading: string;
  icon: SvgIconComponent;
  iconColor:
    "primary" |
    "warning" |
    "danger" |
    "success" |
    "info" |
    "rose" |
    "gray";
  vertical: boolean;
}
const InfoArea: React.FC<IInfoAreaProps> = (props: IInfoAreaProps) => {
  const { classes, heading, description, iconColor, vertical } = props;
  const iconWrapper = classNames({
    [classes.iconWrapper]: true,
    [classes[iconColor]]: true,
    [classes.iconWrapperVertical]: vertical
  });
  const iconClasses = classNames({
    [classes.icon]: true,
    [classes.iconVertical]: vertical
  });
  return (
    <div className={classes.infoArea}>
      <div className={iconWrapper}>
        <props.icon className={iconClasses} />
      </div>
      <div className={classes.descriptionWrapper}>
        <h4 className={classes.title}>{heading}</h4>
        <p className={classes.description}>{description}</p>
      </div>
    </div>
  );
};

InfoArea.defaultProps = {
  iconColor: "gray"
};

export default withStyles(infoStyle as any)(InfoArea);

import { withStyles } from "@material-ui/core";
import React from "react";
import withRoot from "../../../../WithRoot";
import termsAndConditionsPageStyle from "../../styles/TermsAndConditionsStyle";

interface IOurLiabilityProps {
  classes?: any;
}
class OurLiabilityPage extends React.Component<IOurLiabilityProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4 className={classes.title}>12. Our Liability</h4>
        <p className={classes.description}>
          (a) Subject to sub-clause (c) below, in no circumstances whatsoever shall WannaPark.in
          be liable to you for any:
          <p className={classes.subDescription}>
            (i) lost profits;
          </p>
          <p className={classes.subDescription}>
            (ii) loss of data;
          </p>
          <p className={classes.subDescription}>
            (iii) any claim or demand against you by the Motorist;
          </p>
          <p className={classes.subDescription}>
            (iv) any loss arising from or connected with the actions of the Motorist, including any
            damage to the Parking Space or your property caused by the Motorist;
          </p>
          <p className={classes.subDescription}>
            (v) any consequential, special or indirect loss;
          </p>
          <p className={classes.subDescription}>
            (vi) errors, mistakes, or inaccuracies of the Site or Service;
          </p>
          <p className={classes.subDescription}>
            (vii) personal injury or property damage of any nature whatsoever resulting from your access
            to and use of the Service or any Booking with the Motorist;
          </p>
          <p className={classes.subDescription}>
            (viii) any unauthorised access to or use of our secure servers and/or any and all personal,
            institutional, technical or other information stored therein;
          </p>
          <p className={classes.subDescription}>
            (ix) any interruption or cessation of transmission to or from the Site;
          </p>
          <p className={classes.subDescription}>
            (x) any bugs, viruses, Trojan horses, or the like, which may be transmitted to or
            through the Site by any third party..
          </p>
        </p>
        <p className={classes.description}>
          (b) Our maximum liability to you in respect of any claim under or in connection with this Agreement,
          howsoever arising, shall be limited to the aggregate of all Booking Fees received by us in respect
          of Bookings of all of your Parking Spaces made through our Service in the 12 months immediately
          prior to the date upon which the act, error or omission giving rise to the claim occurred or
          (as the case may be) first occurred..
        </p>
        <p className={classes.description}>
          (c) Nothing in this agreement limits or excludes our liability for death or personal injury arising
          as a result of our negligence or the negligence of our employees, agents or self-employed contractors
          or for fraud or fraudulent misrepresentation, or for any other liability which cannot be excluded
          as a matter of law.
        </p>
        <p className={classes.description}>
          (d) This clause 12 shall survive the termination of this Agreement.
        </p>
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(OurLiabilityPage as any));

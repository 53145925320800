import { persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import ReduxPersist from "../config/ReduxPersistConfig";
import { debugLog } from "./LogService";

const updateReducers = (store: object) => {
  const reducerVersion = ReduxPersist.reducerVersion;
  const parkingVersion = ReduxPersist.parkingVersion;
  // const startup = () => store.dispatch(StartupActions.startup())

  // Check to ensure latest reducer version
  storage.getItem("reducerVersion").then((localVersion) => {
    if (localVersion !== reducerVersion) {
      debugLog({
        important: true,
        name: "PURGE",
        preview: "Reducer Version Change Detected",
        value: {
          "New Version:": reducerVersion,
          "Old Version:": localVersion,
        },
      });
      // Purge store
      persistStore(store, undefined).purge();
      storage.setItem("reducerVersion", reducerVersion);
    } else {
      persistStore(store, undefined);
    }
  }).catch(() => {
    persistStore(store, undefined);
    storage.setItem("reducerVersion", reducerVersion);
    storage.setItem("parkingVersion", parkingVersion);
  });
};

export default { updateReducers };

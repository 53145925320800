import { Checkbox, Dialog, DialogContent, FormControlLabel } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
import { Check } from "@material-ui/icons";
import KeyIcon from "@material-ui/icons/VpnKey";
import classNames from "clsx";
import * as H from "history";
import React from "react";
import { connect } from "react-redux";
import { isNullOrUndefined } from "util";
import Button from "../../components/Button";
import Card from "../../components/Cards/LoginCard";
import CardBody from "../../components/Cards/LoginCard/CardBody";
import CardFooter from "../../components/Cards/LoginCard/CardFooter";
import CardHeader from "../../components/Cards/LoginCard/CardHeader";
import CustomInput from "../../components/CustomInput";
import Footer from "../../components/Footer";
import GridContainer from "../../components/Grids/GridContainer";
import GridItem from "../../components/Grids/GridItem";
import { IUser } from "../../interfaces/FirebaseUserInterface";
import { LinksEnum } from "../../literals/Enums";
import { IReducers } from "../../redux";
import AuthActions, { IAuthError } from "../../redux/AuthRedux";
import * as firebaseService from "../../services/FirebaseAuthService";
import { debugLog } from "../../services/LogService";
import { isStringValid } from "../../services/ValidationService";
import image from "../../themes/img/loginFullBg.jpg";
import * as logo from "../../themes/img/logoHeader.png";
import withRoot from "../../WithRoot";
import LoginScreenStyle from "./styles/LoginScreenStyle";

interface ILoginPageProps {
  authError: IAuthError | null;
  classes: any;
  history?: H.History;
  isAuthenticating: boolean;
  location?: H.Location;
  user: IUser | null;

  confirmOtp: (otpVerifier: firebase.auth.ConfirmationResult, otp: string) => void;
  facebookSignIn: () => void;
  googleSignIn: () => void;
  signOut: () => void;
}
interface ILoginPageState {
  cardAnimation: string;
  isAuthenticating: boolean;
  isTermsAccepted: boolean;
  isValidPhone: boolean;
  isVerifyPhoneAttempted: boolean;
  otp: string | null;
  phoneNumber: number | null;
  phoneVerified: boolean;
  signInAttempted: boolean;
}
class LoginPage extends React.Component<ILoginPageProps, ILoginPageState> {
  private otpVerifier: firebase.auth.ConfirmationResult | null = null;

  constructor(props: ILoginPageProps) {
    super(props);

    this.state = {
      cardAnimation: "cardHidden",
      isAuthenticating: false,
      isTermsAccepted: false,
      isValidPhone: false,
      isVerifyPhoneAttempted: false,
      otp: null,
      phoneNumber: null,
      phoneVerified: false,
      signInAttempted: false
    };
  }

  public componentWillMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      () => {
        this.setState({ cardAnimation: "" });
      },
      200
    );

    this.redirectToPreviousState();
  }

  public componentWillReceiveProps(newProps: ILoginPageProps) {
    const user = !isNullOrUndefined(newProps.user) ? newProps.user : null;
    const phoneNumber = user && !isNullOrUndefined(user.phoneNumber)
                          ? parseInt(user.phoneNumber.replace("+91", ""), 10) : null;
    const phoneVerified = !isNullOrUndefined(user) && !isNullOrUndefined(user.phoneNumber) &&
                            user.phoneNumber.toString().length > 9;

    this.setState({
      isAuthenticating: newProps.isAuthenticating,
      phoneNumber,
      phoneVerified,
    });

    debugLog({k: "loginCmpRecProps", user, state: this.state, newProps});

    if (this.state.isValidPhone && !newProps.isAuthenticating && !isNullOrUndefined(newProps.authError)) {
      alert("Invalid OTP. Please re-type your OTP or try another number");
    } else if (this.state.isValidPhone && !newProps.isAuthenticating && isNullOrUndefined(newProps.authError)
                && newProps.history) {
      this.redirectToPreviousState();
    } else if (!newProps.isAuthenticating && isNullOrUndefined(newProps.authError) &&
               !isNullOrUndefined(user) && newProps.history) {
      this.redirectToPreviousState();
    }
  }

  public render() {
    const { classes } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgCardTop
    );
    const phoneNumber = this.state.phoneNumber;
    const invalidPhoneNumber = this.state.isVerifyPhoneAttempted && (isNullOrUndefined(phoneNumber)
                                || isNaN(phoneNumber) || phoneNumber.toString().length < 10);
    const otp = this.state.otp;
    const invalidOTP = this.state.signInAttempted && (isNullOrUndefined(otp) || !isStringValid(otp));
    const label = (
                    <span className={classes.label}>I have read and agree to the&nbsp;
                      <a
                        href="/terms_and_conditions"
                        target="_blank"
                      >
                        Terms and Conditions
                      </a>
                    </span>
                  );
    return (
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundPosition: "top center",
          backgroundSize: "cover",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[this.state.cardAnimation]}>
                <form className={classes.form}>
                  <CardHeader color="white" className={classes.cardHeader}>
                    <img src={logo as any} className={imageClasses} alt="WannaPark"/>
                    <h4>WannaPark</h4>
                  </CardHeader>
                  <CardBody>
                    { this.state.isValidPhone ?
                      <div>
                        <CustomInput
                          labelText="OTP"
                          id="OTP"
                          error={invalidOTP}
                          helperText={`Please check your '${phoneNumber}' for OTP`}
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <KeyIcon className={classes.inputIconsColor} />
                              </InputAdornment>
                            ),
                            onChange: (event: any) => this.setState({otp: event.target.value}),
                            type: "password",
                            value: this.state.otp || "",
                          }}
                          formControlProps={{
                            error: invalidOTP,
                            fullWidth: true
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              tabIndex={-1}
                              onClick={() => this.setState({isTermsAccepted: !this.state.isTermsAccepted})}
                              checked={this.state.isTermsAccepted}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{ checked: classes.checked }}
                            />
                          }
                          classes={{ label: classes.label }}
                          label={label}
                        />
                      </div>
                      :
                      <div className={classes.socialLine}>
                        <CustomInput
                          labelText="Phone*"
                          id="Phone"
                          error={invalidPhoneNumber}
                          inputProps={{
                            onChange: (event: any) => this.setState({ phoneNumber: parseInt(event.target.value, 10) }),
                            readOnly: this.state.phoneVerified,
                            startAdornment: (<InputAdornment position="start">(+91)</InputAdornment>),
                            type: "number",
                            value: phoneNumber || ""
                          }}
                          formControlProps={{
                            error: invalidPhoneNumber,
                            fullWidth: true
                          }}
                        />
                      </div>
                    }
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                  {(this.state.isValidPhone && !this.state.phoneVerified) ?
                    <div className={classes.socialLine}>
                      <Button
                        round
                        color="primary"
                        size="large"
                        onClick={this.onConfirmOTP}
                        disabled={!this.state.isTermsAccepted}
                      >
                        Get started
                      </Button>
                      <Button round color="secondary" size="large" onClick={this.onCancel}>
                        Cancel
                      </Button>
                    </div>
                    :
                    <Button
                      round
                      color="primary"
                      size="large"
                      id="btnVerifyPhoneNo"
                      onClick={this.onVerifyPhoneNumber}
                    >
                      Verify
                    </Button>
                  }
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Dialog
                classes={{
                  paper: classes.modal,
                  root: classes.center,
                }}
                open={this.state.isAuthenticating}
                keepMounted
                disableBackdropClick
                disableEscapeKeyDown
                aria-describedby="classic-modal-slide-description"
              >
                <DialogContent
                  id="classic-modal-slide-description"
                  className={classes.modalBody}
                >
                  <p>
                    Please wait while we verify your details...
                  </p>
                </DialogContent>
              </Dialog>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    );
  }

  private onCancel = () => {
    // this.props.signOut();
    this.setState({
      isAuthenticating: false,
      isValidPhone: false,
      isVerifyPhoneAttempted: false,
      phoneNumber: null
    });
  }

  private onConfirmOTP = () => {
    if (this.otpVerifier && this.state.otp && this.state.otp.length) {
      this.props.confirmOtp(this.otpVerifier, this.state.otp);
      this.setState({signInAttempted: true});
    } else {
      this.setState({ isAuthenticating: false, signInAttempted: true });
    }
  }

  private onValidPhoneNumber = () => this.setState({isValidPhone: true});

  private onVerifyPhoneNumber = () => {
    this.setState({isVerifyPhoneAttempted: true});
    if (!isNullOrUndefined(this.state.phoneNumber)) {
      const phoneNo = `+91 ${this.state.phoneNumber.toString()}`;
      this.setState({
                      isAuthenticating: true
                    },
                    () => {
                            firebaseService.signInWithPhoneNumber(phoneNo, this.onValidPhoneNumber)
                              .then((result: firebase.auth.ConfirmationResult) => {
                                this.otpVerifier = result;
                                this.setState({ isAuthenticating: false });
                              })
                              .catch(() => {
                                alert(`Error while verifying your phone number.
                                Please check the number you entered or try another number.`);
                              });
                          }
                    );
    }
  }

  private redirectToPreviousState = () => {
    if (!isNullOrUndefined(this.props.user) && this.props.history) {
      let redirectLink = LinksEnum.home.toString();
      if (this.props.location && this.props.location.state) {
        const { from } = this.props.location.state;
        redirectLink = from.pathname + from.hash;
      }
      this.props.history.push(redirectLink);
    }
  }
}

const mapStateToProps = (state: IReducers) => ({
  authError: state.auth.authError,
  isAuthenticating: state.auth.isAuthenticating,
  user: state.auth.user
});
const mapDispatchToProps = (dispatch: any) => ({
  confirmOtp: (otpVerifier: firebase.auth.ConfirmationResult, otp: string) =>
                  (dispatch(AuthActions.confirmOtp(otpVerifier, otp))),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withRoot(withStyles(LoginScreenStyle as any)(LoginPage as any)));

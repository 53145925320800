import { Card, CardContent } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/styles";
import React from "react";
import Typography from "../../../components/Typography";
import { IChargesBreakdown } from "../../../interfaces/PricingInterface";
import { unicodeToChar } from "../../../services/TransformService";
import withRoot from "../../../WithRoot";
import billSummaryStyle from "../styles/BillSummaryStyle";

interface IBillSummaryProps {
  classes: any;
  charges: IChargesBreakdown;
}
const BillSummary: React.FC<IBillSummaryProps> = (props: IBillSummaryProps) => {
  const { classes, charges } = props;
  return (
    <div className={classes.container}>
      <Card className={classes.card} key="billSummaryCard">
        <CardContent key="billSummaryCardContent">
          <Typography variant="h6" gutterBottom>
            Bill summary
          </Typography>
          <List disablePadding key="billSummaryList">
            <ListItem className={classes.listItem} key={"parkingCharges"}>
              <ListItemText primary={"Parking Charges"} secondary={`for ${charges.ParkingCharges.duration} hour(s)`} />
              <Typography variant="body2">
                {
                  charges.ParkingCharges.fare > 0
                  ? unicodeToChar("\\u20B9") + " " + charges.ParkingCharges.fare
                  : "FREE"
                }
              </Typography>
            </ListItem>
            <ListItem className={classes.listItem} key={"valetCharges"}>
              <ListItemText primary={"Valet Charges"}/>
              <Typography variant="body2">
              {
                charges.ValetFee > 0
                ? unicodeToChar("\\u20B9") + " " + charges.ValetFee
                : "FREE"
              }
              </Typography>
            </ListItem>
            {/* <ListItem className={classes.listItem} key={"gst"}>
              <ListItemText primary={"GST"} />
              <Typography variant="body2">
                {unicodeToChar("\\u20B9")} &nbsp; {charges.GST}
              </Typography>
            </ListItem> */}
            <ListItem className={classes.listItem} key={"itemTotal"}>
              <ListItemText primary={"Item Total"} />
              <Typography variant="body2">
                {unicodeToChar("\\u20B9")} &nbsp; {charges.TotalCharges}
              </Typography>
            </ListItem>
            {
              charges.TotalDiscount > 0 &&
              <ListItem className={classes.listItem} key={"totalDiscount"}>
                <ListItemText primary={"Total Discount"} />
                <Typography variant="subtitle1" className={classes.totalDiscount}>
                  - {unicodeToChar("\\u20B9")} &nbsp; {charges.TotalDiscount}
                </Typography>
              </ListItem>
            }
            <ListItem className={classes.listItem} key={"toPay"}>
              <ListItemText primary={"To Pay"} />
              <Typography variant="subtitle1" className={classes.total}>
                {
                  charges.ToPay > 0
                  ? unicodeToChar("\\u20B9") + " " + charges.ToPay
                  : "FREE"
                }
              </Typography>
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </div>
  );
};

export default withRoot(withStyles(billSummaryStyle as any)(BillSummary));

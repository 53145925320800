import { container, title } from "../../../themes/jss/material-kit-react";
import imagesStyle from "../../../themes/jss/material-kit-react/imagesStyles";

const sectionStyle = {
  ...imagesStyle,
  bannerBg: {
    background: "#fff"
  },
  center: {
    textAlign: "center",
  },
  container: {
    color: "#FFFFFF",
    zIndex: "12",
    ...container
  },
  description: {
    color: "#999"
  },
  parallaxChildren: {
    "&:after,&:before": {
      content: "''",
      display: "block",
      height: "100%",
      left: "0",
      position: "absolute",
      top: "0",
      width: "100%",
      zIndex: "1",
    },
    "&:before": {
      background: "rgba(0, 0, 0, 0.5)"
    },
    "alignItems": "center",
    "bottom": 0,
    "display": "flex",
    "flexFlow": "row wrap",
    "justifyContent": "center",
    "left": 0,
    "position": "absolute",
    "right": 0,
    "top": 0,
  },
  parallaxTitle: {
    ...title,
    color: "#fff",
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    zIndex: 2,
  },
  parallaxVideo: {
    // height: "100%",
    objectFit: "cover",
    // width: "100%",
  },
  section: {
    padding: "16px 0",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
};

export default sectionStyle;

import { call, put } from "redux-saga/effects";
import { isNull } from "util";
import { StatusCodeEnum, StatusCodeMessageEnum } from "../literals/Enums";
import AuthActions from "../redux/AuthRedux";
import StatusActions from "../redux/StatusRedux";
import { debugLog } from "../services/LogService";
import { TransformFireUserToIUser } from "../services/TransformFireUserToIUser";
import { isAuthError } from "../services/ValidationService";

export function * checkAuthState(service: any) {
  yield put(AuthActions.setIsAuthenticating(true));
  yield put(AuthActions.clearAuthError());
  try {
    const user = yield call(service.checkAuthState);
    if (user) {
      debugLog({k: "checkAuthState_Saga", user});
      yield put(AuthActions.setUser(TransformFireUserToIUser(user)));
      debugLog({k: "checkAuthState_Saga_2", user, token: user.getIdToken()});
      yield user.getIdToken();
      yield put(AuthActions.setIsAuthenticating(false));
    }
  } catch (ex) {
    debugLog({k: "checkAuthStateSaga_Exception", class: "AuthSaga", ex, message: ex && ex.message});
    if (isAuthError(ex)) {
      yield put(AuthActions.setAuthError({code: ex.code, message: ex.message}));
    }
    yield put(AuthActions.setIsAuthenticating(false));
  }
}

export function * signOut(service: any) {
  try {
    yield call(service.signOut);
    yield put(AuthActions.clearAuthError());
    yield put(AuthActions.setUser(null));
  } catch (ex) {
    debugLog({k: "signOutSaga_Exception", class: "AuthSaga", ex, message: ex && ex.message});
    if (isAuthError(ex)) {
      yield put(AuthActions.setAuthError({code: ex.code, message: ex.message}));
    }
  }
}

export function * signUp(service: any, payload: any) {
  yield put(AuthActions.setIsAuthenticating(true));
  yield put(AuthActions.clearAuthError());
  try {
    const response = yield call(service.signUpWithEmailAndPassword, payload.email, payload.password);
    if (response.user) {
      yield put(AuthActions.setUser(TransformFireUserToIUser(response.user)));
    } else {
      yield put(StatusActions.setError(StatusCodeEnum.UNAUTHNUM, StatusCodeMessageEnum.UNAUTHORIZED));
    }
    yield put(AuthActions.setIsAuthenticating(false));
  } catch (ex) {
    debugLog({k: "signUpSaga_Exception", class: "AuthSaga", ex, message: ex && ex.message});
    if (isAuthError(ex)) {
      yield put(AuthActions.setAuthError({code: ex.code, message: ex.message}));
    }
    if (isNull(ex)) {
      yield put(AuthActions.setUser(null));
    }
    yield put(AuthActions.setIsAuthenticating(false));
  }
}

export function * verifyPhoneNumber(service: any, payload: any) {
  yield put(AuthActions.setIsAuthenticating(true));
  yield put(AuthActions.clearAuthError());
  try {
    if (payload.otpVerifier) {
      const response = yield call(service.confirmOtp, payload.otpVerifier, payload.code);
      if (response) {
        yield put(AuthActions.setUser(TransformFireUserToIUser(response.user)));
        yield put(AuthActions.setIsAuthenticating(false));
      }
    } else {
      debugLog({k: "verifyPhoneNumber_otpVerifier", class: "AuthSaga", message: "Invalid OTP Verifier"});
      yield put(AuthActions.setAuthError({code: "0", message: "Invalid OTP Verifier"}));
      yield put(AuthActions.setIsAuthenticating(false));
    }
  } catch (ex) {
    debugLog({k: "verifyPhoneNumber_Exception", class: "AuthSaga", ex, message: ex.message});
    if (isNull(ex)) {
      yield put(AuthActions.setUser(null));
    } else if (isAuthError(ex)) {
      yield put(AuthActions.setAuthError({code: ex.code, message: ex.message}));
    }
    yield put(AuthActions.setIsAuthenticating(false));
  }
}

import firebase from "firebase/app";
import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";
import { IUser } from "../interfaces/FirebaseUserInterface";
import { AuthErrorMessageEnum } from "../literals/Enums";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  checkAuthState: null,
  clearAuthError: null,
  confirmOtp: ["otpVerifier", "code"],
  setAuthError: ["error"],
  setIsAuthenticating: ["isAuthenticating"],
  setToken: ["token"],
  setUser: ["user"],
  signIn: ["provider", "email", "password"],
  signOut: null,
});

export const AuthReduxTypes = Types;
export default Creators;

export interface IAuthError {
  code: string;
  message: string;
  messageDisplay: string;
}

export interface IAuthReduxState {
  authError: IAuthError | null;
  isAuthenticating: boolean;
  otpVerifier: firebase.auth.ConfirmationResult | null;
  token: string | null;
  user: IUser | null;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable<IAuthReduxState>({
  authError: null,
  isAuthenticating: false,
  otpVerifier: null,
  token: null,
  user: null,
} as IAuthReduxState);

/* ------------- Reducers ------------- */
export const clearAuthError = (state: Immutable.ImmutableObject<IAuthReduxState>) => {
  return(
    state.merge({authError: null})
  );
};

export const setAuthError = (state: Immutable.ImmutableObject<IAuthReduxState>,
                             { authError }: {authError: IAuthError | null}) => {
  const messageDisplay = (authError && authError.code) ? (AuthErrorMessageEnum as any)[authError.code]
                          : "Technical error occurred!";
  return(
    state.merge({authError: {...authError, messageDisplay}})
  );
};

export const setIsAuthenticating = (state: Immutable.ImmutableObject<IAuthReduxState>,
                                    { isAuthenticating }: {isAuthenticating: boolean}) => (
  state.merge({ isAuthenticating })
);

export const setToken = (state: Immutable.ImmutableObject<IAuthReduxState>,
                         { token }: {token: string}) => (
  state.merge({ token })
);

export const setUser = (state: Immutable.ImmutableObject<IAuthReduxState>,
                        { user }: {user: IUser}) => (
  state.merge({ user })
);

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer<Immutable.ImmutableObject<IAuthReduxState>, any>(INITIAL_STATE, {
  [Types.CLEAR_AUTH_ERROR]: clearAuthError,
  [Types.SET_AUTH_ERROR]: setAuthError,
  [Types.SET_IS_AUTHENTICATING]: setIsAuthenticating,
  [Types.SET_TOKEN]: setToken,
  [Types.SET_USER]: setUser,
});

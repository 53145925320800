import firebase from "firebase/app";
import { IUser } from "../interfaces/FirebaseUserInterface";

export function TransformFireUserToIUser(src: firebase.User): IUser {
  return {
    displayName: src.displayName,
    email: src.email,
    emailVerified: src.emailVerified,
    isAnonymous: src.isAnonymous,
    metadata: src.metadata,
    phoneNumber: src.phoneNumber,
    photoURL: src.photoURL,
    providerData: src.providerData,
    providerId: src.providerId,
    uid: src.uid,
  };
}

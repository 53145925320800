import { call, put } from "redux-saga/effects";
import { isNullOrUndefined } from "util";
import RentActions from "../redux/RentSpaceRedux";
import { debugLog } from "../services/LogService";
import { isRentRequestValid } from "../services/ValidationService";

export function * getRentRequest(api: any, payload: any) {
  try {
    yield put(RentActions.setIsFetching(true));
    const response = yield call(api.GetRentRequest, payload.data);
    if (response) {
      debugLog({rentResponse: response.rentRequests});
      yield put(RentActions.setRentRequests(response.rentRequests));
    }
    yield put(RentActions.setIsFetching(false));
  } catch (ex) {
    debugLog({ k: "getRentRequest", class: "RentSaga", ex, message: ex.message});
    yield put(RentActions.setIsFetching(false));
  }
}

export function * saveRentRequest(api: any, payload: any) {
  try {
    debugLog({ k: "saveRentRequest", class: "RentSaga", payload});
    yield put(RentActions.setIsSaving(true));
    yield put(RentActions.setIsSaveSuccessful(false));
    if (isNullOrUndefined(payload) || !isRentRequestValid(payload.data)) {
      yield put(RentActions.setIsSaving(false));
      throw new Error("Rent Request Data is not valid");
    }
    const response = yield call(api.SaveRentRequest, payload.data);
    debugLog({k: "saveRentRequest", response});
    if (response && !isNullOrUndefined(response.saveRentRequest)) {
      yield put(RentActions.setIsSaveSuccessful(true));
    }
    yield put(RentActions.setIsSaving(false));
  } catch (ex) {
    debugLog({ k: "saveRentRequest", class: "RentSaga", ex, message: ex.message});
    yield put(RentActions.setIsSaveSuccessful(false));
    yield put(RentActions.setIsSaving(false));
  }
}

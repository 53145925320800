import { container, title } from "../../../themes/jss/material-kit-react";

const trackCarStyle = (theme: any) => ({
  center: {
    textAlign: "center",
  },
  container: {
    zIndex: "12",
    ...container
  },
  footer: {
    backgroundColor: "white",
    marginTop: "auto",
    padding: theme.spacing(2),
  },
  footerRate: {
    bottom: 0,
    left: "auto",
    margin: 0,
    position: "fixed",
    top: "auto",
    width: "100%",
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  listItem: {
    padding: theme.spacing(1, 0),
  },
  mainContainer: {
    background: "#FFFFFF",
    marginTop: "80px",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    margin: "-60px 30px 0px",
  },
  section: {
    flexGrow: 1,
    marginLeft: 15,
    marginRight: 15,
    marginTop: 85,
    padding: "70px 0"
  },
  subtitle: {
    fontSize: "1.313rem",
    margin: "10px auto 0",
    maxWidth: "500px",
  },
  textCenter: {
    textAlign: "center"
  },
  title: {
    ...title,
    color: "#FFFFFF",
    display: "inline-block",
    marginTop: "30px",
    minHeight: "32px",
    position: "relative",
    textDecoration: "none"
  },
});

export default trackCarStyle;

import { call, put } from "redux-saga/effects";
import { isNullOrUndefined } from "util";
import { SaveStatusEnum, StatusCodeEnum } from "../literals/Enums";
import LeadActions from "../redux/GoBumprLeadRedux";
import StatusActions from "../redux/StatusRedux";
import { debugLog } from "../services/LogService";

export function * saveGoBumprLead(api: any, payload: any) {
  try {
    debugLog({ k: "saveGoBumprLead", class: "GoBumprLeadSaga", payload});
    yield put(LeadActions.setIsSaving(true));
    yield put(LeadActions.setIsSaveSuccessful(false));
    const response = yield call(api.SaveGoBumprLead, payload.data);
    debugLog({k: "saveGoBumprLead", response});
    if (response && !isNullOrUndefined(response.saveGoBumprLead)) {
      yield put(LeadActions.setIsSaveSuccessful(true));
      yield put(StatusActions.setStatus(SaveStatusEnum.success, "Request raised successfully", "success"));
    }
    yield put(LeadActions.setIsSaving(false));
  } catch (ex) {
    debugLog({ k: "saveGoBumprLead", class: "GoBumprLeadSaga", ex, message: ex.message});
    yield put(LeadActions.setIsSaveSuccessful(false));
    yield put(LeadActions.setIsSaving(false));
    yield put(StatusActions.setError((ex && ex.response) ? (ex.response.status || StatusCodeEnum.ERROR)
                                        : StatusCodeEnum.ERROR, "Oops! Unable to send request due to technical issue"));
  }
}

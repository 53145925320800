import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";
import { StatusCodeEnum, StatusCodeMessageEnum } from "../literals/Enums";
import { VariantType } from "../literals/Types";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  clearStatus: null,
  setError: ["statusCode", "statusMessage"],
  setStatus: ["statusCode", "statusMessage", "variant"],
});

export const StatusReduxTypes = Types;
export default Creators;

export interface IStatusReduxState {
  statusCode: StatusCodeEnum | null;
  statusMessage: StatusCodeMessageEnum | null;
  variant: VariantType | null;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable<IStatusReduxState>({
  statusCode: null,
  statusMessage: null,
  variant: null,
} as IStatusReduxState);

/* ------------- Reducers ------------- */
export const clearStatus = (state: Immutable.ImmutableObject<IStatusReduxState>) => (
  state.merge({ statusCode: null, statusMessage: null, variant: null })
);

export const setError = (state: Immutable.ImmutableObject<IStatusReduxState>,
                         { statusCode, statusMessage }: {statusCode: StatusCodeEnum,
                            statusMessage: StatusCodeMessageEnum }) => (
  state.merge({ statusCode, statusMessage, variant: "error" })
);

export const setStatus = (state: Immutable.ImmutableObject<IStatusReduxState>,
                          { statusCode, statusMessage, variant }: {statusCode: StatusCodeEnum,
                            statusMessage: StatusCodeMessageEnum, variant: VariantType}) => (
  state.merge({ statusCode, statusMessage, variant })
);

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer<Immutable.ImmutableObject<IStatusReduxState>, any>(INITIAL_STATE, {
  [Types.CLEAR_STATUS as string]: clearStatus,
  [Types.SET_ERROR as string]: setError,
  [Types.SET_STATUS as string]: setStatus,
});

import { Typography, withStyles } from "@material-ui/core";
import classNames from "clsx";
import React from "react";
import withRoot from "../../WithRoot";
import termsAndConditionsPageStyle from "./styles/TermsAndConditionsStyle";

interface IUserAgreementProps {
  classes?: any;
}
class UserAgreementPage extends React.Component<IUserAgreementProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <Typography className={classes.mainTitle} variant="h5" component="h3">
            Privacy Policy
          </Typography>
          <div className={classes.section}>
            <h4 className={classes.title}>1. Definitions</h4>
            <p className={classes.description}>
              For the purpose of this Privacy Policy (hereinafter referred to as the “Policy”),
              wherever the context so requires,
            </p>
            <p className={classes.description}>
              (a) The term ‘Company’ shall mean ‘WannaPark Technologies Pvt. Ltd ’ a Private Limited Company
              limited by shares and registered under the Companies Act, 2013 and having its registered
              office at Chennai
            </p>
            <p className={classes.description}>
              (b) The term ‘website’ shall mean www.wannapark.in.
            </p>
            <p className={classes.description}>
              (b) The term ‘app’ shall mean WannaPark android application.
            </p>
            <p className={classes.description}>
              (c) The term ‘You’ &amp; ‘User’ shall mean any legal person or entity accessing or using the services
              provided on this Website, who is competent to enter into binding contracts, as per the provisions
              of the Indian Contract Act, 1872;
            </p>
            <p className={classes.description}>
              (d) The terms ‘We’, ‘Us’ &amp; ‘Our’ shall mean the Website and/or App and/or the Company, as
              the context so requires.
            </p>
          </div>
          <div className={classes.section}>
            <h4 className={classes.title}>2. General</h4>
            <p className={classes.description}>
              (a) We are committed to safeguarding your privacy and ensuring that you continue to trust us with your
              personal data. When you interact with us you may share personal information with us which allows
              identification of you as an individual. This is known as personal data.
            </p>
            <p className={classes.description}>
              (b) This document is an electronic record in terms of Information Technology Act, 2000 and rules
              thereunder as applicable and the amended provisions pertaining to electronic records in various
              statutes as amended by the Information Technology Act, 2000. This electronic record is generated
              by a computer system and does not require any physical or digital signatures.
            </p>
            <p className={classes.description}>
              (c) This document is published in accordance with the provisions of Rule 3 (1) of the Information
              Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations,
              privacy policy and Terms of Use for access or usage of WannaPark.in
            </p>
            <p className={classes.description}>
            (d) This website and the wannapark app is owned and operated by the Company.
            </p>
          </div>
          <div className={classes.section}>
            <h4 className={classes.title}>3. Scope and Acceptance of this Privacy Policy</h4>
            <p className={classes.description}>
              (a) This Policy applies to the personal data that we collect about you for the purposes
              of providing you with our services.
            </p>
            <p className={classes.description}>
              (b) By using this website or by giving us your personal data, you accept the practices described
              in this Policy. If you do not agree to this Privacy Policy, please do not use this website or give
              us any personal data.
            </p>
            <p className={classes.description}>
              (c) We reserve the right to change this Policy without prior notice. We encourage you to regularly
              review this policy to ensure that you are aware of any changes and how your personal data may be used.
            </p>
          </div>
          <div className={classes.section}>
            <h4 className={classes.title}>4. Data collected by Us</h4>
            <p className={classes.description}>
              We may collect data about you from a variety of sources, including through:
            </p>
            <p className={classes.description}>
              (a) Online and electronic interactions with us, including via the website, mobile applications,
              text messaging programs or through our pages on third party social networks.
            </p>
            <p className={classes.description}>
              (b) Your interaction with online targeted content (such as advertisements) that we or service
              providers on our behalf provide to you via third party websites and/or applications.
            </p>
            <h4 className={classes.title}>4.1	Data that You provide Us directly</h4>
            <p className={classes.description}>
              This is the data that you provide us with your consent for a specified purpose including:
            </p>
            <p className={classes.description}>
              (a) Personal contact information, including any information allowing us to contact you
              in person (e.g. name, home or e-mail address and phone number);
            </p>
            <p className={classes.description}>
              (b) Demographic information, including date of birth, age, gender, location;
            </p>
            <p className={classes.description}>
              (c) Payment information, including any information used to make purchases
              (e.g. credit card number, expiration date, billing address);
            </p>
            <p className={classes.description}>
              (d) Account login information including any information that is required for you to establish
              a user account with us. (e.g. login ID/email, user name, password and security question/answer);
            </p>
            <p className={classes.description}>
              (e) Consumer feedback, including information that you share with us about your experience in using
              our services (e.g. your comments and suggestions, testimonials and other feedback)
            </p>
            <h4 className={classes.title}>4.2	Information collection when You visit Our website</h4>
            <p className={classes.description}>
              We use cookies and other tracking technology which collect certain kinds of information when
              you interact with the website. We use cookies that store your preferences and give us data about
              your browsing behaviour, such as the pages you visited on the website, the amount of time you spend
              on the website etc. This information helps us understand the interests of our customers and also helps
              us improve our website.
            </p>
            <h4 className={classes.title}>4.3	Information collection from THIRD PARTY</h4>
            <p className={classes.description}>
              The app does use third party services that may collect information used to identify you.

              Link to privacy policy of third party service providers used by the app

              <a href="https://firebase.google.com/policies/analytics">Firebase Analytics</a>
              <a href="http://try.crashlytics.com/terms/privacy-policy.pdf">Crashlytics</a>

              We want to inform you that whenever you use our Service, in a case of an error in the app we collect
              data and information (through third party products) on your phone called Log Data. This Log Data may
              include information such as your device Internet Protocol (“IP”) address, device name, operating
              system version, the configuration of the app when utilizing our Service, the time and date of your
              use of the Service, and other statistics.
            </p>
          </div>
          <div className={classes.section}>
            <h4 className={classes.title}>5. Divulging/Sharing of Personal Information</h4>
            <p className={classes.description}>
            (a) We may share your personal information with other corporate entities and affiliates to help
            detect and prevent identity theft, fraud and other potentially illegal acts; correlate related or multiple
            accounts to prevent abuse of our services; and to facilitate joint or co-branded services, where such
            services are provided by more than one corporate entity.
            </p>
            <p className={classes.description}>
            (b) We may disclose personal information if required to do so by law or if we in good faith believe
            that such disclosure is reasonably necessary to respond to subpoenas, court-orders, or other legal
            processes. We may also disclose your personal information to law enforcement offices, third party
            rights owners, or other third parties if we believe that such disclosure is reasonably necessary to
            enforce the ‘Terms of Use’ or Policy; respond to claims that an advertisement, posting or other content
            violates the rights of a third party; or protect the rights, property or personal safety of its users,
            or the general public.
            </p>
            <p className={classes.description}>
            (c) We and our affiliates may share / sell some or all of your personal information with other
            business entities in the event that we merge or are acquired by such business entity, or in the
            event of re-organization, amalgamation, or restructuring of our business. Such business entity or
            new entity will continue to be bound be the Terms of Use and Privacy Policy, as may be amended
            from time to time.
            </p>
          </div>
          <div className={classes.section}>
            <h4 className={classes.title}>6. Security</h4>
            <p className={classes.description}>
            Transactions on the Website &amp; App are secure and protected. Any information entered by
            the User when transacting on the Website is encrypted to protect the User against unintentional
            disclosure to third parties. The User’scredit and debit card information is not received,
            stored by or retained by the Company / Website / App
            in any manner. This information is supplied by the User directly to the relevant payment gateway which is
            authorized to handle the information provided, and is compliant with the regulations and requirements of
            various banks and institutions and payment franchisees that it is associated with.
            </p>
          </div>
          {/* <div className={classes.section}>
            <h4 className={classes.title}>7. Third Party Advertisements / Promotions</h4>
            <p className={classes.description}>
            We use third-party advertising companies to serve ads to the users of the Website. These companies
            may use information relating to the User’s visits to the Website and other websites in order to provide
            customised advertisements to the User. Furthermore, the Website may contain links to other websites
            that may collect personally identifiable information about the User. The Company/Website is not responsible
            for the privacy practices or the content of any of the aforementioned linked websites, and the User
            expressly acknowledges the same and agrees that any and all risks associated will be borne entirely
            by the User.
            </p>
          </div> */}
          <div className={classes.section}>
            <h4 className={classes.title}>7. Grievance Officer</h4>
            <p className={classes.description}>
              In accordance with Information Technology Act 2000 and rules made there under, the name and contact
              details of the Grievance Officer are provided below:
            </p>
            <p className={classes.description}>
              WannaPark Support
            </p>
            <p className={classes.description}>
              contact@support.in
            </p>
            {/* <p className={classes.description}>
              [Phone No. and email id]
            </p> */}
          </div>
          <div className={classes.section}>
            <h4 className={classes.title}>8. Data Security &amp; Retention</h4>
            <h4 className={classes.title}>8.1	Data Security</h4>
            <p className={classes.description}>
            In order to keep your personal data secure, we have implemented a number of security measures including:
            </p>
            <p className={classes.description}>
            (a) We store your data in secure operating environments accessible only to our employees, agents and
            affiliates on a need to know basis.
            </p>
            <p className={classes.description}>
            (b) We use SHA-256 encryption to provide protection for sensitive financial information, such as credit card
            information sent over the Internet.
            </p>
            <p className={classes.description}>
            (c) Prior authentication for account access - We require our registered users to verify their identity
            (e.g. phone number and otp) before they can access or make changes to their account. This is aimed to
            prevent unauthorized accesses.
            </p>
            <p className={classes.description}>
            (d) Please note that these protections do not apply to personal data you choose to share in public areas
            such as on community websites.
            </p>
            <h4 className={classes.title}>8.2	Retention</h4>
            <p className={classes.description}>/p>
            (a) We will only retain your personal data for as long as it is necessary for the stated purpose,
            taking into account also our need to answer queries or resolve problems, provide improved and new
            services, and comply with legal requirements under applicable laws. This means that we may retain
            your personal data for a reasonable period after your last interaction with us. When the personal
            data that we collect is no longer required in this way, we destroy or delete it in a secure manner.
            </p>
          </div>
          <div className={classes.section}>
            <h4 className={classes.title}>9. Applicable Laws &amp; Dispute Resolution</h4>
            <p className={classes.description}>
            Any controversy or claim arising out of or relating to this policy shall be decided by Arbitration
            in accordance with laws of India. The Arbitral Tribunal shall consist of one arbitrator who will be
            appointed by the Company. Any such controversy or claim shall be arbitrated on an individual basis,
            and shall not be consolidated in any arbitration with any claim or controversy of any other party.
            Any other dispute or disagreement of a legal nature will also be decided in accordance with the laws
            of India, and the Courts at Chennai, TamilNadu shall have exclusive jurisdiction in all such cases.
            </p>
        </div>
        <br />
      </div>
    </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(UserAgreementPage as any));

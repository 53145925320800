import * as React from "react";
import { GoogleMap, GoogleMapProps, Marker, withGoogleMap, withScriptjs } from "react-google-maps";
import SearchBox from "react-google-maps/lib/components/places/SearchBox";

interface IMapComponentProps {
  bounds?: google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral;
  containerElement: React.ReactElement<any>;
  googleMapURL: string;
  isMarkerShown: boolean;
  loadingElement: React.ReactElement<any>;
  mapElement: React.ReactElement<any>;
  markerCoord?: google.maps.LatLng | google.maps.LatLngLiteral;

  onMapMounted: (ref: GoogleMap) => void;
  onPlacesChanged: () => void;
  onSearchBoxMounted: (ref: SearchBox) => void;
}

const component = withScriptjs(withGoogleMap((props: GoogleMapProps & IMapComponentProps) => (
    <GoogleMap
      ref={props.onMapMounted}
      defaultZoom={14}
      defaultOptions={props.defaultOptions}
      center={props.center}
      onClick={props.onClick}
    >
      <Marker key={"marker_center"} position={props.markerCoord} animation={google.maps.Animation.DROP}/>
      <SearchBox
        ref={props.onSearchBoxMounted}
        bounds={props.bounds}
        controlPosition={google.maps.ControlPosition.LEFT_TOP}
        onPlacesChanged={props.onPlacesChanged}
      >
        <input
          type="text"
          placeholder="Search your location"
          style={{
            border: `1px solid transparent`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            boxSizing: `border-box`,
            fontSize: `14px`,
            height: `45px`,
            marginLeft: `12px`,
            marginTop: `12px`,
            outline: `none`,
            padding: `0 12px`,
            textOverflow: `ellipses`,
            width: `300px`
          }}
        />
      </SearchBox>
    </GoogleMap>
  )
));

export default component;

import storage from "redux-persist/lib/storage";
import immutablePersistenceReconciler from "../services/ImmutablePersistenceReconciler";
import immutablePersistenceTransform from "../services/ImmutablePersistenceTransform";

// More info here:  https://shift.infinite.red/shipping-persistant-reducers-7341691232b1
const REDUX_PERSIST = {
  active: true,
  parkingVersion: "1.0.10",
  reducerVersion: "1.0.0",
  storeConfig: {
    key: "root",
    // blacklist: ['nav'],   // reducer keys that you do NOT want stored to persistence here
    stateReconciler: immutablePersistenceReconciler,
    storage,
    transforms: [immutablePersistenceTransform],
    whitelist: ["rent"], // Optionally, just specify the keys you DO want stored to
    // persistence. An empty array means 'don't store any reducers' -> infinitered/ignite#409
  }
};

export default REDUX_PERSIST;

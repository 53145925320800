import Immutable from "seamless-immutable";
import { debugLog } from "./LogService";

export const seamlessImmutableReconciler = (inboundState: any, originalState: any, reducedState: any,
                                            { mergeDeep = false }: { mergeDeep: boolean }) => {
  const newState = { ...reducedState };
    // only rehydrate if inboundState exists and is an object
  if (inboundState && typeof inboundState === "object") {
    Object.keys(inboundState).forEach((key) => {
      // ignore _persist data
      if (key === "_persist") { return; }
      // if reducer modifies substate, skip auto rehydration
      if (originalState[key] !== reducedState[key]) {
        // eslint-disable-next-line no-console
        debugLog(`redux-persist/stateReconciler: sub state for key ${key} modified, skipping.`);
        return;
      }
      if (isPlainEnoughObject(reducedState[key])) {
        // if object is plain enough shallow merge the new values (hence "Level2")
        newState[key] = { ...newState[key], ...inboundState[key] };
        return;
      } else if (isImmutable(reducedState[key]) && newState[key]) {
          // if this is a seamless-immutable state slice, use its own merge function
        newState[key] = newState[key].merge(inboundState[key], {
          deep: mergeDeep
        });
        return;
      }
        // otherwise hard set
      newState[key] = inboundState[key];
    });
  }

  return newState;
};

const isPlainEnoughObject = (o: any) => (
    o !== null && !Array.isArray(o) && typeof o === "object" && !isImmutable(o)
);

const isImmutable = (a: any) => Immutable.isImmutable(a);

export default seamlessImmutableReconciler;

const imagesStyles = {
  imgCard: {
    borderRadius: "calc(.25rem - 1px)",
    width: "100%",
  },
  imgCardBottom: {
    borderBottomLeftRadius: "calc(.25rem - 1px)",
    borderBottomRightRadius: "calc(.25rem - 1px)",
    width: "100%",
  },
  imgCardOverlay: {
    bottom: "0",
    left: "0",
    padding: "1.25rem",
    position: "absolute",
    right: "0",
    top: "0",
  },
  imgCardTop: {
    borderTopLeftRadius: "calc(.25rem - 1px)",
    borderTopRightRadius: "calc(.25rem - 1px)",
    width: "50%",
  },
  imgFluid: {
    height: "auto",
    maxWidth: "100%",
  },
  imgGallery: {
    marginBottom: "2.142rem",
    width: "100%",
  },
  imgRaised: {
    boxShadow:
      "0 15px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  imgRounded: {
    borderRadius: "6px !important"
  },
  imgRoundedCircle: {
    borderRadius: "50% !important"
  },
};

export default imagesStyles;

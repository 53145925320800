// tslint:disable: object-literal-sort-keys

import { container, grayColor } from "../../../themes/jss/material-kit-react";

const footerStyle = (theme: any) => ({
  a: {
    backgroundColor: "transparent",
    color: grayColor,
    textDecoration: "none",
  },
  block: {
    borderRadius: "3px",
    color: "inherit",
    display: "block",
    fontSize: "12px",
    fontWeight: "500",
    padding: "0.9375rem",
    position: "relative",
    textDecoration: "none",
    textTransform: "uppercase",
  },
  container,
  footer: {
    display: "flex",
    padding: "0.9375rem 0",
    position: "relative !important",
    textAlign: "center",
    zIndex: "2",
  },
  footerBottomMargin: {
    marginBottom: "75px"
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF"
    }
  },
  icon: {
    height: "18px",
    position: "relative",
    top: "3px",
    width: "18px",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  left: {
    display: "block",
    float: "left!important",
  },
  list: {
    marginBottom: "0",
    marginTop: "0",
    padding: "0",
  },
  navLink: {
    "color": "inherit",
    "position": "relative",
    "padding": "0.9375rem",
    "fontWeight": "400",
    "fontSize": "12px",
    "textTransform": "uppercase",
    "borderRadius": "3px",
    "lineHeight": "20px",
    "textDecoration": "none",
    "margin": "0px",
    "display": "inline-flex",
    "&:hover,&:focus": {
      background: "rgba(200, 200, 200, 0.2)",
      color: "inherit",
    },
    [theme.breakpoints.down("sm")]: {
      "width": "calc(100% - 30px)",
      "marginLeft": "15px",
      "marginBottom": "8px",
      "marginTop": "8px",
      "textAlign": "left",
      "& > span:first-child": {
        justifyContent: "flex-start"
      }
    }
  },
  right: {
    float: "right!important",
    margin: "0",
    padding: "15px 0",
  },
  socialIcons: {
    position: "relative",
    fontSize: "20px !important",
    marginRight: "4px"
  },
});
export default footerStyle;

import { IconButton, Snackbar, SnackbarContent } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import { withStyles } from "@material-ui/styles";
import React from "react";
import { VariantType } from "../../literals/Types";
import withRoot from "../../WithRoot";
import styles from "./styles/CustomSnackbarStyle";

const variantIcon = {
  error: ErrorIcon,
  info: InfoIcon,
  success: CheckCircleIcon,
  warning: WarningIcon,
};

interface ICustomSnackbarProps {
  autoHideDuration?: number;
  classes: any;
  isOpen: boolean;
  message: string;
  variant: VariantType | null;

  onClose: (reason: string) => void;
}
class CustomSnackbar extends React.Component<ICustomSnackbarProps> {

  public render() {
    const { classes, autoHideDuration, message, onClose, isOpen, variant, ...other } = this.props;
    const Icon = variantIcon[variant || "info"];

    return (
      <Snackbar
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        open={isOpen}
        autoHideDuration={autoHideDuration || 6000}
        onClose={(_: any, reason: string) => onClose(reason)}
      >
        <SnackbarContent
          className={classes[variant || "info"]}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes.message}>
              <Icon className={classes.icon} />
              {message}
            </span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={() => onClose("timeout")}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>,
          ]}
          {...other}
        />
      </Snackbar>
    );
  }
}

export default withRoot(withStyles(styles)(CustomSnackbar));

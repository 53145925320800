import { withStyles } from "@material-ui/core";
import React from "react";
import withRoot from "../../../../WithRoot";
import termsAndConditionsPageStyle from "../../styles/TermsAndConditionsStyle";

interface IBookingPaymentsProps {
  classes?: any;
}
class BookingPaymentsPage extends React.Component<IBookingPaymentsProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4 className={classes.title}>3. Booking, Payment and Charges</h4>
        <h4 className={classes.title}>3.1 Booking</h4>
        <p className={classes.description}>
          (i) Once your Advert has been uploaded to the Site your Parking Space is
          available for booking (“the Booking”) for the date(s) and time(s) that you have made it available for
          on your calendar. Based on the availability information provided by you on your calendar the Site
          will tailor the Advert for your Parking Space. It is your responsibility to keep your calendar updated.
          Should you not update your calendar to make the Parking Space unavailable for a particular date(s) or
          time(s) then you may be liable to pay the reasonable costs of alternative arrangements for the User
          who makes a Booking for such date(s) and time(s).
        </p>
        <p className={classes.description}>
          (ii) When a Booking is made and payment is received from the User you authorise us to accept the
          Booking for the available date(s) and time(s) without further reference to you.
        </p>
        <p className={classes.description}>
          (iii) The Website will allow you to list the Parking Space in two ways:
          either you can list the Parking Space for Instant Bookings or you can list your
          Parking Space for Booking by Agreement.
        </p>
        <h4 className={classes.title}>(a) Instant Booking</h4>
        <p className={classes.subDescription}>
          (i) You may make your Parking Space available for instant booking.
          Where you elect to use instant booking your Parking Space will be treated as
          available unless you inform us otherwise. It is your responsibility to ensure
          that you inform us of any dates your Parking Space will not be available for booking.
          If the availability changes at any time you must notify us as soon as practicable.
          Based on the availability information you have provided to us we will tailor the Website
          listing for your Parking Space. <b>If you have not informed us that your Parking Space is unavailable
          for a particular date or time then you may be liable to pay the reasonable costs of alternative
          arrangements for a User who makes a booking for such date and time (up to a maximum of 	&#8377; 1000).
          Where this happens we may either deduct money directly from your WannaPark account or pay the
          reasonable costs of alternative arrangements to a User ourselves and recover this money from you
          afterwards.</b>
        </p>
        <p className={classes.subDescription}>
          (ii) Where instant booking is used, you agree that we will manage the entire booking process and
          you authorise us to accept a booking for an available date without further reference to you.
          We will take payment from the User on your behalf at the time of Booking as set out below
        </p>
        <h4 className={classes.title}>(b) Booking by Agreement</h4>
        <p className={classes.subDescription}>
          (i) If you have a Parking Space where booking is by agreement then the Website will allow Users
          to enquire about the availability of your Parking Space (each an “Enquiry”). Any Enquiries about
          prospective bookings will be sent to you automatically by email and/or by text message depending
          on the preferences as set out in your Account. Each Enquiry will include (where the information
          is available) the proposed date(s) or periods of the booking. You agree to confirm with us as
          soon as practicable whether or not the booking is accepted. You can confirm a booking by signing
          into the Website and approving the relevant Enquiry or by sending a text message to us if you have
          chosen to accept SMS notifications.
        </p>
        <p className={classes.subDescription}>
          (ii) Parking Space Owners have 72 hours to reply to an Enquiry before it lapses. You acknowledge that
          during and after this 72 hour period after the Enquiry is sent by the User, we may suggest alternative
          Parking Spaces to the Driver who has made the Enquiry.
        </p>
        <p className={classes.subDescription}>
          (iii) If the booking is accepted by you, we will notify the User who can then book the Parking Space for
          the agreed times. Upon the Driver confirming the Booking (and making payment in accordance with 3.2 below)
          you will enter into an agreement with the User. We will provide the User with the contact details you
          have supplied to us without further reference to you, and we will take payment from the User on your behalf
          at the time of Booking as set out below.
        </p>
        <h4 className={classes.title}>3.2 Payment</h4>
        <p className={classes.description}>
          (i) Once a Booking is made and payment is received from the User, the User will be deemed to have agreed
          as a condition of using the Service.
        </p>
        <p className={classes.description}>
          (ii) WannaPark.in’s policy is to collect the full fees owed by the User for the Parking Space (“the Fees”)
          at the time a Booking is made and you authorise us to accept and hold such payment on your behalf.
        </p>
        <p className={classes.description}>
          (iii) WannaPark.in will charge a booking fee of 20% on top of the price you specify for the Parking Space
          for each Booking made (the “Booking Fee”). This will be automatically added to the Fees paid by the User
          and deducted from the payments due to you.
        </p>
        <p className={classes.description}>
          (iv) Once payment is received from the User the Booking will be deemed to have been accepted and you will
          have entered into a binding agreement with the User to allow the User to occupy the Parking Space
          during the date(s) and time(s) (“the Licence Period”) set out in the confirming email of the Booking
          (“the Confirmation”). We will confirm the Booking to you as soon as practicable.
        </p>
        <p className={classes.description}>
          (v) When the Licence Period has started we will make the Fees (less the Booking Fee) available to you by
          releasing these into your account at <a href="/"> www.wannapark.in</a> Fees will not be released
          for future Bookings or for days still pending on current bookings. WannaPark.in will endeavour
          to ensure that the funds are available to you in accordance with this Agreement, the Terms and Conditions and
          but do not guarantee that they will be available to you to withdraw in circumstances which
          are unforeseen or beyond our control.
        </p>
        <p className={classes.description}>
          (vi) You understand and agree that you are solely responsible for paying all tax and other
          charges due in respect of the Fees.
        </p>
        <p className={classes.description}>
          (vii) If the User overstays in the Parking Space and you wish and are entitled to charge the
          User further sums in respect of the overstay, we may (in our absolute discretion) agree to
          forward or issue an invoice to the User on your behalf and credit any payment received to your account at
          www.wannapark.in through the collection and payment system. Where we agree to issue or forward an invoice
          on your behalf, we are not responsible for the collection of the sums due under the invoice, and shall
          have no liability to you in respect of the non-payment of the invoice by the User. You shall remain
          solely responsible for collecting any sums due from the User.
        </p>
        <h5 className={classes.title}> WannaPark Payments </h5>
        <ol>
          <li className={classes.subDescription}>
            For short and medium-term use (any Booking Period of 6 weeks or less) when we request payment
            from a User we will request the full amount due in respect of the Booking at the time of booking.
          </li>
          <li className={classes.subDescription}>
            For long-term use (any Booking Period of more than 6 weeks) we will require that the User pays
            for the first month of the Booking Period at the time of booking. We will collect each subsequent
            month's payment in advance on the same day of the month for each subsequent month
            (or the remaining amount due for the Booking Period where less than a month is left) until the
            Booking Period is complete.
          </li>
          <li className={classes.subDescription}>
            You authorize us to accept and hold such payments on your behalf. We will forward the initial payment
            received from the User to you, within 48 hours after the start of the Booking Period or within 48 hours
            after the period covered by a subsequent payment in the case of a long-term booking.
          </li>
        </ol>
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(BookingPaymentsPage as any));

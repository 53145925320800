import { withStyles } from "@material-ui/core";
import classNames from "clsx";
import * as React from "react";
import { GoogleMap } from "react-google-maps";
import GridContainer from "../../../components/Grids/GridContainer";
import GridItem from "../../../components/Grids/GridItem";
import Maps from "../../../components/Maps";
import AppConfig from "../../../config/AppConfig";
import LocateUsSectionStyle from "../styles/SectionStyle";

interface ILocateUsSectionProps {
  classes: any;

  onMapMounted: (ref: GoogleMap) => void;
}
class LocateUsSection extends React.Component<ILocateUsSectionProps> {

  public render() {
    const options = {
      draggable: false,
      streetViewControl: false,
    } as google.maps.MapOptions;
    const { classes } = this.props;
    return (
      <div className={classNames(classes.main)}>
      <div className={classes.container}>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <h2 className={classes.title}>Locate Us</h2>
              <h5 className={classes.description}>
                We will be waiting for you opposite to Rasi Silks Emporium in East Mada Street till 8 pm.
              </h5>
            </GridItem>
          </GridContainer>
          <div>
            <GridContainer style={{justifyContent: "center"}}>
              <GridItem xs={12} sm={12} md={6}>
                <Maps
                  center={{lat: 13.033340, lng: 80.271225}}
                  defaultOptions={options}
                  googleMapURL={AppConfig.googleMapApi}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `240px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  markerCoord={{lat: 13.033340, lng: 80.271225}}
                  markerType={"default"}
                  zoom={18}
                  onMapMounted={this.props.onMapMounted}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

export default withStyles(LocateUsSectionStyle as any)(LocateUsSection);

import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import * as React from "react";

const style = {
  grid: {
    flexBasis: "auto",
    minHeight: "1px",
    paddingLeft: "15px",
    paddingRight: "15px",
    position: "relative",
    width: "100%",
  }
};

// tslint:disable-next-line:typedef
function GridItem({ ...props }) {
  const { classes, children, className, ...rest } = props;
  return (
    <Grid item {...rest} className={classes.grid + " " + className}>
      {children}
    </Grid>
  );
}

export default withStyles(style as any)(GridItem);

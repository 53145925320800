import { withStyles } from "@material-ui/core";
import React from "react";
import { LinksEnum } from "../../../../literals/Enums";
import withRoot from "../../../../WithRoot";
import termsAndConditionsPageStyle from "../../styles/TermsAndConditionsStyle";

interface IListingServicesProps {
  classes?: any;
}
class ListingServicesPage extends React.Component<IListingServicesProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4 className={classes.title}>2. The Service and Listing</h4>
        <p className={classes.description}>
          (a) In order to take Bookings for your Parking Spaces you must
          register an Account as set out here www.wannapark.in/registration.
        </p>
        <p className={classes.description}>
          (b) We may advertise your Parking Space on our Website and WannaPark application and
          provide information to Drivers and prospective Drivers who may wish to use the Parking Space.
        </p>
        <p className={classes.description}>
          (c) By placing an advert for a Parking Space (an “Advert”) on the Site,
          You appoint us as your agent for the purposes of forming binding agreements
          between you and the Driver in relation to Bookings. You grant a licence to the
          User to use the Parking Space to which a Booking relates for the period of
          that Booking (“Booking Period”). You also appoint us to collect your fees
          in relation to the Booking from the Driver..
        </p>
        <p className={classes.description}>
          (d) You agree to provide all relevant information about the Parking Space including:
          <p className={classes.subDescription}>
          (i) the address of the Parking Space;
          (ii) any restrictions on the types of vehicles for which the Parking Space is suitable; and
          (iii) any other information or restrictions (e.g. height restrictions) which apply to the Parking
          Space which the Motorist should be aware of before booking the Parking Space.
          </p>
        </p>
        <p className={classes.description}>
          (e) You are also required to provide us with information before we will list the Advert for the
          Parking Space which may include your name, address and telephone number.
        </p>
        <p className={classes.description}>
          (f) You must provide us with any other information relating to you or the Parking Space as we
          may reasonably request at any time and we may also ask you to clarify any information you have provided.
        </p>
        <p className={classes.description}>
          (g) You are responsible for the accuracy of all information in your Advert and any information you provide
          to us in relation to a booking. You may be liable to the User if the
          information you provide is inaccurate, incomplete or misleading in any way.
        </p>
        <p className={classes.description}>
          (h) In addition to advertising the Parking Space, we will be entitled to send promotional emails
          to the Users and provide such information about your Parking Space as we may deem appropriate
          to promote <a href={LinksEnum.home} title="WannaPark"> www.wannapark.in</a> or your Parking Space.
        </p>
        <p className={classes.description}>
          (i) You may not incorporate any additional terms other than the restrictions
          clearly included in your Advert. You agree not to propose to the User any additional terms
          or amendments to the Parking Service Agreement when a booking has been confirmed. The User is
          not obliged to accept any further terms once we have accepted a Booking on your behalf.
        </p>
        <p className={classes.description}>
          (j) Any additional restrictions included in your Advert are a contract between you and
          the User. We are not a party to that agreement and we will not be liable to you,
          the User or any third party for any breach of the agreement
          or otherwise in relation to the Parking Space or its use by the User.
          We have no control over the conduct of the User and are not liable for any actions of the User.
        </p>
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(ListingServicesPage as any));

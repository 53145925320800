import { withStyles } from "@material-ui/core/styles";
import MuiTypography, { TypographyProps } from "@material-ui/core/Typography";
import { capitalize } from "@material-ui/core/utils";
import React from "react";

const styles = (theme: any) => ({
  markedH2Center: {
    backgroundColor: theme.palette.secondary.main,
    display: "block",
    height: 4,
    margin: `${theme.spacing(1)}px auto 0`,
    width: 73,
  },
  markedH3Center: {
    backgroundColor: theme.palette.secondary.main,
    display: "block",
    height: 4,
    margin: `${theme.spacing(1)}px auto 0`,
    width: 55,
  },
  markedH4Center: {
    backgroundColor: theme.palette.secondary.main,
    display: "block",
    height: 4,
    margin: `${theme.spacing(1)}px auto 0`,
    width: 55,
  },
  markedH6Left: {
    background: "currentColor",
    display: "block",
    height: 2,
    marginTop: theme.spacing(0.5),
    width: 28,
  },
});

const variantMapping = {
  h1: "h1",
  h2: "h1",
  h3: "h1",
  h4: "h1",
  h5: "h3",
  h6: "h2",
  subtitle1: "h3",
};

interface ITypographyProps {
  classes: any;
  component?: string;
  marked?: string;
}
const Typography: React.FC<ITypographyProps & TypographyProps> = (props: ITypographyProps & TypographyProps) => {
  const { children, classes, marked, variant, ...other } = props;

  return (
    <MuiTypography variantMapping={variantMapping} variant={variant} {...other}>
      {children}
      {marked ? (
        <span className={classes[`marked${capitalize(variant || "") + capitalize(marked)}`]} />
      ) : null}
    </MuiTypography>
  );
};

export default withStyles(styles)(Typography);

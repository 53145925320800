import Badge from "@material-ui/core/Badge";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import withStyles from "@material-ui/core/styles/withStyles";
import * as React from "react";
import Button from "../../components/Button";
import { LinksEnum } from "../../literals/Enums";
import headerLinksStyle from "./styles/HeaderLinkStyle";

function HeaderLinks({ ...props }) {
  const { classes, howItWorksScroll, locateUsScroll, pricingScroll, rentSpaceScroll, showHelp,
          showOffers, showTrackMyCar, isLandingPage, mediaScroll, testimonialScroll } = props;
  return (
    <List className={classes.list}>
      {
        !isLandingPage &&
        <ListItem className={classes.listItem}>
          <Button
            buttonColor="transparent"
            className={classes.navLink}
            href={LinksEnum.home}
          >
            Home
          </Button>
        </ListItem>
      }
      {howItWorksScroll &&
        <ListItem className={classes.listItem}>
          <Button
            buttonColor="transparent"
            className={classes.navLink}
            onClick={howItWorksScroll}
          >
            How it works
          </Button>
        </ListItem>
      }
      {
        locateUsScroll &&
        <ListItem className={classes.listItem}>
          <Button
            onClick={locateUsScroll}
            buttonColor="transparent"
            className={classes.navLink}
          >
            Locate Us
          </Button>
        </ListItem>
      }
      {
        pricingScroll &&
        <ListItem className={classes.listItem}>
          <Button
            onClick={pricingScroll}
            buttonColor="transparent"
            className={classes.navLink}
          >
            Pricing
          </Button>
        </ListItem>
      }
      {
        mediaScroll &&
        <ListItem className={classes.listItem}>
          <Button
            onClick={mediaScroll}
            buttonColor="transparent"
            className={classes.navLink}
          >
            Media
          </Button>
        </ListItem>
      }
      {
        testimonialScroll &&
        <ListItem className={classes.listItem}>
          <Button
            onClick={testimonialScroll}
            buttonColor="transparent"
            className={classes.navLink}
          >
            Testimonial
          </Button>
        </ListItem>
      }
      {
        rentSpaceScroll &&
        <ListItem className={classes.listItem}>
          <Button
            onClick={rentSpaceScroll}
            buttonColor="transparent"
            className={classes.navLink}
          >
            Rent Your Space
          </Button>
        </ListItem>
      }
      {
        showTrackMyCar &&
        <ListItem className={classes.listItem}>
          <Button
            href={LinksEnum.trackCarHome}
            buttonColor="transparent"
            className={classes.navLink}
          >
            Track My Car
          </Button>
        </ListItem>
      }
      {
        showHelp &&
        <ListItem className={classes.listItem}>
          <Button
            href={`tel:+918939995878`}
            buttonColor="transparent"
            className={classes.navLink}
          >
            Call Support
          </Button>
        </ListItem>
      }
      {
        showOffers &&
        <ListItem className={classes.listItem}>
          <Badge color="secondary" badgeContent={"New"}>
            <Button
              href={LinksEnum.gobumprCampaign}
              buttonColor="transparent"
              className={classes.navLink}
            >
              GoBumpr Offers
            </Button>
          </Badge>
        </ListItem>
      }
    </List>
  );
}

export default withStyles(headerLinksStyle as any)(HeaderLinks);

import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "clsx";
import * as H from "history";
import * as React from "react";
import ReactDOM from "react-dom";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import Button from "../../components/Button";
import Parallax from "../../components/Cards/Banner";
import GridContainer from "../../components/Grids/GridContainer";
import GridItem from "../../components/Grids/GridItem";
import { IRentSpaceRequest } from "../../interfaces/RentSpaceInterface";
import { LinksEnum } from "../../literals/Enums";
import { IReducers } from "../../redux";
import RentActions from "../../redux/RentSpaceRedux";
import rentSpaceBG from "../../themes/img/HomeParking.jpg";
import withRoot from "../../WithRoot";
import RentYourSpace from "./sections/RentYourSpace";
import rentSpaceStyle from "./styles/RentSpaceStyle";

interface IRentSpaceProps {
  classes?: any;
  history?: H.History;
  location?: H.Location;
  innerRef?: React.Ref<any> | React.RefObject<any>;
  isRequestSubmitted: boolean;
  isRequestUploading: boolean;

  saveRentRequest: (data: IRentSpaceRequest) => void;
}
class RentSpacePage extends React.Component<IRentSpaceProps> {
  private addSpaceRef: React.RefObject<RentSpacePage> = React.createRef<RentSpacePage>();

  public componentDidMount() {
    if (this.props.location && this.props.location.hash) {
      this.scrollToRentSpace();
    }
  }

  public render() {
    const { classes } = this.props;
    const headerClasses = classNames(classes.container, classes.center);
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>WannaPark | Make money by renting out your unused land</title>
          <meta property="og:title" content={"WannaPark | Make money by renting out your unused land"} />
          <meta property="og:description" content={`Do you have empty land space? We have a perfect
          solution to make money out of it. Our platform provides most simple and seamless
          process to help you lent your land for other car users in your city.`} />
          <meta property="og:type" content={"video:conference"} />
          <meta property="og:url" content={window.location.origin} />
        </Helmet>
        <Parallax filter small image={rentSpaceBG}>
          <div className={headerClasses}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h2 className={classes.title}>Make money by renting out your unused land.</h2>
                <br />
                <br />
                <Button
                  buttonColor="brand"
                  size="large"
                  onClick={this.scrollToRentSpace}
                >
                  Add your space
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main)}>
          <div className={classes.container}>
            <div ref={this.addSpaceRef as any}>
              <RentYourSpace
                isRequestSubmitted={this.props.isRequestSubmitted}
                isRequestUploading={this.props.isRequestUploading}
                saveRentRequest={this.props.saveRentRequest}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  private scrollToRentSpace = () => {
    if (this.addSpaceRef && this.addSpaceRef.current) {
      const myDomNode = ReactDOM.findDOMNode(this.addSpaceRef.current);
      if (myDomNode) {
        (myDomNode as any).scrollIntoView();
        if (window.history && window.history.pushState) {
          window.history.pushState("", "WannaPark | Rent your empty space",
                                   LinksEnum.rentSpace + "/#rentParkingRequest");
        }
      }
    }
  }
}

const mapStateToProps = (state: IReducers) => {
  return {
    isRequestSubmitted: state.rent.isSaveSuccessful,
    isRequestUploading: state.rent.isSaving,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    saveRentRequest: (data: IRentSpaceRequest) => dispatch(RentActions.saveRentRequest(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(
  withRoot(withStyles(rentSpaceStyle as any)(RentSpacePage as any)));

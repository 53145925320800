import { withStyles } from "@material-ui/core";
import React from "react";
import withRoot from "../../../../WithRoot";
import termsAndConditionsPageStyle from "../../styles/TermsAndConditionsStyle";

interface IParkingSpaceProps {
  classes?: any;
}
class ParkingSpaceLicencePage extends React.Component<IParkingSpaceProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4 className={classes.title}>5. Licence of Parking Space and Your Responsibilities</h4>
        <p className={classes.description}>
          (a) You must only use the Parking Space on the times and dates specified in the Booking
          (the “Licence Period”). If you use the Parking Space at any other time you may be liable to the Owner.
        </p>
        <p className={classes.description}>
          (b) You will pay any penalty payable by you under the Parking Space Agreement.
        </p>
        <p className={classes.description}>
          (c) You must use the vehicle the details of which you have notified to us in the Booking. If
          you use a different vehicle you may be refused entry to the Parking Space and unable to park.
          This is not applicable when you are using our "Valet" service.
        </p>
        <p className={classes.description}>
          (d) If you have any concerns or queries about the Parking Space during the Licence Period
          you must contact the Owner directly.
        </p>
        <p className={classes.description}>
          (e) Upon arrival at the Parking Space at the start of the Licence Period, you should inspect the Parking
          Space and ensure you are satisfied that it meets the description on the Site. If you are not satisfied
          you must contact the Owner immediately. Any dispute between you and the Owner relating
          to the Parking Space should be dealt with at the start of the License Period and without our involvement.
        </p>
        <p className={classes.description}>
          (f) You must:
          <p className={classes.subDescription}>
            (i) agree to observe and act in accordance with the Parking Space Agreement;
          </p>
          <p className={classes.subDescription}>
            (ii) comply with all parking restrictions relating to the Parking Space;
          </p>
          <p className={classes.subDescription}>
            (iii) not to sublet the Parking Space nor assign or transfer the Parking Space Agreement;
          </p>
          <p className={classes.subDescription}>
            (iv) not use the Parking Space or deal with the Owner in any way which could be deemed
            to be harmful to our business or reputation or do anything which might adversely affect
            our relationship with the Owner;
          </p>
          <p className={classes.subDescription}>
            (v) not attempt to contact the Owner directly until a Booking has been confirmed;
          </p>
          <p className={classes.subDescription}>
            (vi) not attempt to book any Parking Space advertised on the Site other than through us; and
          </p>
          <p className={classes.subDescription}>
            (vii) not use the Site in any way which may detrimentally affect our reputation or the use and
            enjoyment of the Site or our Service by any other user.
          </p>
        </p>
        <p className={classes.description}>
          (g) You agree that you will not create any false account or use your account for any immoral or illegal
          activity or purpose including (without limit) malicious or fraudulent bookings or money laundering.
        </p>
        <p className={classes.description}>
        (h) You warrant that:
          <p className={classes.subDescription}>
            (i) you have the power and authority to enter into this Agreement and the Parking Space Agreement
            with the Owner for the use of the Parking Space;
          </p>
          <p className={classes.subDescription}>
            (ii) you have a valid driving licence, vehicle registration and hold effective insurance for all risks
            you deem appropriate to cover your vehicle whilst it occupies
            the Parking Space; and
          </p>
          <p className={classes.subDescription}>
            (iii) any road tax is fully paid and that the vehicle is not subject to a Statutory Off Road
            Notification (SORN).
          </p>
        </p>
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(ParkingSpaceLicencePage as any));

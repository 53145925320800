import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import classNames from "clsx";
import React from "react";
import GridContainer from "../../../components/Grids/GridContainer";
import GridItem from "../../../components/Grids/GridItem";
import pricingStyle from "../styles/PricingStyle";

interface IPricingProps {
  classes?: any;
}
const Pricing: React.FC<IPricingProps> = (props: IPricingProps) => {
  const { classes } = props;
  return (
    <div className={classNames(classes.main)}>
      <div className={classes.container}>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <h2 className={classes.title}>Pricing</h2>
              <h5 className={classes.description}>
                Below is the pricing we are charging @ Kapaleeshwarar temple, Mylapore.
              </h5>
            </GridItem>
          </GridContainer>
          <div>
            <GridContainer style={{justifyContent: "center"}}>
              <GridItem xs={12} sm={12} md={4}>
                <div className={classes.infoArea}>
                  <div className={classes.sectionWrapper}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      ₹ 50
                    </Typography>
                    <Typography component="h6" variant="h6" color="textSecondary">
                      first hour
                    </Typography>
                  </div>
                  <div className={classes.descriptionWrapper}>
                    <div className={classes.description} style={{flexDirection: "row"}}>
                      {/* <Typography variant="body1" color="textPrimary">
                        Valet charges ₹30 + Parking charges ₹20
                      </Typography> */}
                    </div>
                    <h4 className={classes.title}>₹30 for every additional hour</h4>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(pricingStyle as any)(Pricing);

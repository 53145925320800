import { findIndex } from "ramda";
import { isNullOrUndefined } from "util";
import { ICoupon } from "../interfaces/CouponInterface";
import { IChargesBreakdown, IPricing } from "../interfaces/PricingInterface";
import { getTimeDifference } from "./TransformTime";
import { isNumberNotZero } from "./ValidationService";

const getToPay = (totalCharges: number, totalChargesTax: number, discount: number): number => {
  if (isNumberNotZero(totalCharges) && isNumberNotZero(discount)) {
    if (discount > totalCharges) {
      return 0.0;
    } else {
      return Math.ceil(totalChargesTax - discount);
    }
  } else {
    return totalChargesTax;
  }
};

export function unicodeToChar(text: string) {
  return text.replace(/\\u[\dA-F]{4}/gi,
                      (match) => {
                              return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16));
                      });
}

export const getChargesBreakdown = (pricing: IPricing, startTime: string,
                                    coupon?: ICoupon | null ): IChargesBreakdown | null => {
  if (!isNullOrUndefined(pricing)) {
    const rateCards = pricing.ParkingCharges;
    let discountDuration = 0;
    let discountAmount = 0;
    let discountPercent = 0;
    let maxDiscount = 0;
    if (coupon) {
      discountDuration = Math.floor(coupon.DiscountHours);
      discountDuration = discountDuration < 0 ? 0 : discountDuration;
      discountAmount = isNumberNotZero(coupon.DiscountAmount) ? coupon.DiscountAmount : 0;
      discountPercent = isNumberNotZero(coupon.DiscountPercent) ? coupon.DiscountPercent : 0;
      maxDiscount = isNumberNotZero(coupon.MaxDiscountAmount) ? coupon.MaxDiscountAmount : 0;
    }
    const { hours, minutes } = getTimeDifference(startTime);
    let duration = minutes > 5 ? hours + 1 : (hours || 1);
    duration = (duration - discountDuration) < 0 ? 0 : (duration - discountDuration);
    let index = !isNullOrUndefined(duration)
                       ? findIndex((rate) => (rate.duration >= duration), rateCards)
                       : 0;
    if (index === -1 && !isNullOrUndefined(duration)) {
        index = rateCards.length - 1;
        if (index > 0) {
            const prevRateCard = rateCards[index - 1];
            const maxHourRateCard = rateCards[index];
            const diffPrice = maxHourRateCard.fare - prevRateCard.fare;
            const actualFrequency = maxHourRateCard.duration - prevRateCard.duration;
            const diffDuration = duration - maxHourRateCard.duration;
            let frequency = actualFrequency;
            let frequencyDuration = 1;
            while (frequency < diffDuration) {
                frequency = frequency + actualFrequency;
                frequencyDuration = frequencyDuration + 1;
            }
            const price = (maxHourRateCard.fare + (diffPrice * frequencyDuration));
            // const priceformat = unicodeToChar("\\u" + currencyCode) + price;
            // const time = duration + (duration === 1 ? " hour" : " hours");
            const parkingCharges = { fare: price, duration };
            const totalCharges = price + pricing.ValetFee;
            const tax = totalCharges * pricing.GSTPercentage / 100;
            const totalChargesTax = totalCharges + tax;
            let totalDiscount = 0;
            if (discountAmount > 0) {
              totalDiscount = (totalCharges - discountAmount);
              if (maxDiscount > 0 && totalDiscount > maxDiscount) {
                totalDiscount = maxDiscount;
              }
            }
            if (discountPercent > 0) {
              if (discountPercent === 100 && maxDiscount === 0) {
                totalDiscount = totalChargesTax;
              } else {
                totalDiscount = Math.floor(totalCharges * (discountPercent / 100));
                if (maxDiscount > 0 && totalDiscount > maxDiscount) {
                  totalDiscount = maxDiscount;
                }
              }
            }
            return {
              GST: tax,
              ParkingCharges: parkingCharges,
              ToPay: getToPay(totalCharges, totalChargesTax, totalDiscount),
              TotalCharges: totalChargesTax,
              TotalDiscount: totalDiscount,
              ValetFee: pricing.ValetFee,
            } as IChargesBreakdown;
        } else {
            const maxHourRateCard = rateCards[index];
            const diffDuration = duration - maxHourRateCard.duration;
            const price = (maxHourRateCard.fare +
                (maxHourRateCard.fare * Math.ceil(diffDuration / maxHourRateCard.duration)));
            // const priceformat = unicodeToChar("\\u" + currencyCode) + price;
            // const time = duration + (duration === 1 ? " hour" : " hours");
            const parkingCharges = { fare: price, duration };
            const totalCharges = price + pricing.ValetFee;
            const tax = totalCharges * pricing.GSTPercentage / 100;
            const totalChargesTax = totalCharges + tax;

            let totalDiscount = 0;
            if (discountAmount > 0) {
              totalDiscount = (totalCharges - discountAmount);
              if (maxDiscount > 0 && totalDiscount > maxDiscount) {
                totalDiscount = maxDiscount;
              }
            }
            if (discountPercent > 0) {
              if (discountPercent === 100 && maxDiscount === 0) {
                totalDiscount = totalChargesTax;
              } else {
                totalDiscount = Math.floor(totalCharges * (discountPercent / 100));
                if (maxDiscount > 0 && totalDiscount > maxDiscount) {
                  totalDiscount = maxDiscount;
                }
              }
            }
            return {
              GST: tax,
              ParkingCharges: parkingCharges,
              ToPay: getToPay(totalCharges, totalChargesTax, totalDiscount),
              TotalCharges: totalChargesTax,
              TotalDiscount: totalDiscount,
              ValetFee: pricing.ValetFee,
            } as IChargesBreakdown;
        }
    } else {
        const price = rateCards[index].fare;
        // const priceformat = unicodeToChar("\\u" + currencyCode) + price;
        // const time = rateCards[index].duration + (rateCards[index].duration === 1 ? " hour" : " hours");
        const parkingCharges = { fare: price, duration };
        const totalCharges = price + pricing.ValetFee;
        const tax = totalCharges * pricing.GSTPercentage / 100;
        const totalChargesTax = totalCharges + tax;

        let totalDiscount = 0;
        if (discountAmount > 0) {
          totalDiscount = (totalCharges - discountAmount);
          if (maxDiscount > 0 && totalDiscount > maxDiscount) {
            totalDiscount = maxDiscount;
          }
        }
        if (discountPercent > 0) {
          if (discountPercent === 100 && maxDiscount === 0) {
            totalDiscount = totalChargesTax;
          } else {
            totalDiscount = Math.floor(totalCharges * (discountPercent / 100));
            if (maxDiscount > 0 && totalDiscount > maxDiscount) {
              totalDiscount = maxDiscount;
            }
          }
        }
        return {
          GST: tax,
          ParkingCharges: parkingCharges,
          ToPay: getToPay(totalCharges, totalChargesTax, totalDiscount),
          TotalCharges: totalChargesTax,
          TotalDiscount: totalDiscount,
          ValetFee: pricing.ValetFee,
        } as IChargesBreakdown;
    }
  }
  return null;
};

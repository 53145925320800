import { withStyles } from "@material-ui/core";
import React from "react";
import withRoot from "../../../../WithRoot";
import termsAndConditionsPageStyle from "../../styles/TermsAndConditionsStyle";

interface IComplaintsDisputesProps {
  classes?: any;
}
class ComplaintsDisputesPage extends React.Component<IComplaintsDisputesProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4 className={classes.title}>8. Complaints and Disputes</h4>
        <p className={classes.description}>
        (a) You agree that if in the event of any dispute, claim or issue arising concerning the
        Parking Space or otherwise with the User before, during or after the Licence Period
        (a “Dispute”) you will notify the User immediately and that you will attempt to resolve
        such Dispute directly with the User.
        </p>
        <p className={classes.description}>
        (b) You will notify us promptly of any Dispute, and provide us with such information
        as we may request regarding the Dispute.
        </p>
        <p className={classes.description}>
        (c) We are not responsible for resolving any Dispute.
        </p>
        <p className={classes.description}>
        (d) Notwithstanding sub-clause (c) above, we may, in our absolute discretion, take such steps as
        we may consider appropriate to seek to resolve a Dispute. In order to protect our interests,
        you shall comply with all lawful instructions which we may give to you from time-to-time in order
        to seek to resolve a Dispute, including (without limitation) paying any sums which we
        consider properly due to the User.
        </p>
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(ComplaintsDisputesPage as any));

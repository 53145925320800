import { withStyles } from "@material-ui/core";
import React from "react";
import withRoot from "../../../../WithRoot";
import termsAndConditionsPageStyle from "../../styles/TermsAndConditionsStyle";

interface IUserDisclaimersProps {
  classes?: any;
}
class UserDisclaimersPage extends React.Component<IUserDisclaimersProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4 className={classes.title}>10. Disclaimers</h4>
        <p className={classes.description}>
          (a) If you choose to use the Site and Service, you do so at your sole risk. You acknowledge
          and agree that we do not have an obligation to pre-screen or conduct background checks on
          any Parking Space Owner.
        </p>
        <p className={classes.description}>
          (b) We make no warranty that the Site or Service will meet your requirements or be available
          on an uninterrupted, secure, or error-free basis.
        </p>
        <p className={classes.description}>
          (c) We make no warranty regarding the accuracy, timeliness, truthfulness, completeness or reliability
          of any content obtained through the Site.
        </p>
        <p className={classes.description}>
          (d) We shall use reasonable skill and care when providing the Service.
        </p>
        <p className={classes.description}>
          (e) No advice or information, whether oral or written, obtained by us or through the Site or Services
          will create any warranty not expressly made herein. Any views or opinions expressed in any email
          correspondence sent from WannaPark.in are those of the sender and not WannaPark.in.
        </p>
        <p className={classes.description}>
          (f) You are solely responsible for all of your communications and interactions with other users
          of the Site or Service and with other persons with whom you communicate or interact as a result
          of your use of the Site or Service, including, but not limited to, any Parking Space Owner.
          You understand that we do not make any attempt to verify the statements of users of the Site or Service
          or to review or visit any Parking Spaces. Further, we make no representations or warranties as to the
          conduct of users of the Site or Service or their compatibility with any current or future users of the
          Site, or Service. You agree to take reasonable precautions in all communications and interactions
          with other users of the Site or Service and with other persons with whom you communicate or interact as
          a result of your use of the Site or Service, including, but not limited to, a Parking Space Owner,
          particularly if you decide to meet in person.
        </p>
        <p className={classes.description}>
          (g) We do not check whether the Parking Space Owner maintains insurance in respect of your use the
          Parking Space. If you require confirmation that the Owner maintains suitable insurance you must check
          this directly with the Owner.
        </p>
        <p className={classes.description}>
          (h) Your correspondence or business dealings with, or participation in promotions of, third party
          advertisers found on or through the Application, including payment and delivery of related goods
          or services, and any other terms, conditions, warranties or representations associated with such
          dealings, are solely between You and such third party advertiser. We shall not be responsible
          or liable for any loss or damage of any sort incurred as the result of any such dealings or
          as the result of the presence of such third party advertisers on the Application.
        </p>
        <p className={classes.description}>
          (i) WannaPark shall not be responsible or liable for any loss or damage, howsoever caused or
          suffered by the User arising out of the use of parking service offered by Driver/Valet/Vendor
          or due to the failure of Driver/Valet/Vendor to provide Services to the User for any reason
          whatsoever including but not limited to the User's non-compliance with the Services' recorded
          voice instructions, malfunction, partial or total failure of any network terminal, data processing
          system, computer tele-transmission or telecommunications system or other circumstances whether or
          not beyond the control of WannaPark or any person or any organization involved in the above mentioned systems.
        </p>
        <p className={classes.description}>
          (j) You acknowledge that while WannaPark employs certain quality control measures, WannaPark does not,
          and cannot exercise control over the quality or safety of the transportation that is made use of by
          You through the Services.
        </p>
        <p className={classes.description}>
          (k) WannaPark will not be liable for any damages of any kind arising from the use of the service
          offered by the Driver/Valet including, but not limited to direct, indirect, incidental, punitive,
          and consequential damages.
        </p>
        <p className={classes.description}>
          (l) WannaPark will take all reasonable measures to ensure that Driver/Valet should fulfill his
          duty properly but there may be exceptions and if any User misses the meeting or movie show or event
          or interview or flight or bus, WannaPark will not be held liable for any such compensation. The User
          is advised to make alternate arrangement in case the Valet/Driver/Parking available due to any reason.
        </p>
        <p className={classes.description}>
          (m) The Valet parking services, and any claim resulting from the same, while requested through the
          Application, remains the responsibility of the Driver/Valet s and WannaPark shall not be liable for
          any conduct of the Driver/Valet s. WannaPark encourages You to notify any complaints that You may
          have against the Valet and Parking Owner that You may have hired/rented using the WannaPark’s platform
        </p>
        <p className={classes.description}>
          (n) WannaPark is not responsible for the conduct, whether online or offline, of any other user of
          the Application or Services. You are solely responsible for Your interactions with other users,
          whether Drivers/Valets. By using the Application and the Services, You agree to accept such
          risks and acknowledge that WannaPark is not responsible for the acts or omissions of other users.
        </p>
        <p className={classes.description}>
          (o) The User acknowledges that in the event the User does not avail the Service(s) in the Vehicle
          allocated to him/her by WannaPark and/or the User exchanges the space allocated to him/her with
          a space allocated to another User in another Vehicle, the User shall do so at its own and sole risk.
          WannaPark shall not bear any liability arising out of or in connection with such acts on the part
          of the Users.
        </p>
        <p className={classes.description}>
          (p) The location data made available on the Application is basic is nature, and meant to provide
          guidance to Drivers/Valets only. You acknowledge that it is not intended to be relied upon in
          situations where precise location information is required, or where erroneous, inaccurate or
          incomplete data may lead to death, personal injury, property or environmental damage.
          WannaPark does not guarantee the availability, accuracy, completeness, reliability, or
          timeliness of location data displayed.
        </p>
        <p className={classes.description}>
          (q) We do not authorize anyone to make a warranty on Our behalf and You may not rely on
          any statement of warranty as a warranty by Us.
        </p>
        <p className={classes.description}>
          (r) WannaPark and its representatives, officers, employees, agents and contractors shall not
          be liable for any loss, damage, claim, expense, cost (including legal costs) or liability arising directly or
          indirectly from Your use or non-use of the Service or the Application, or Your reliance upon the Service
          or the information contained upon the Application (whether arising from WannaPark or any other
          person's negligence or otherwise).
        </p>
        <p className={classes.description}>
          (s) We do not warrant that Your use of the Application or Services will be accurate, complete,
          reliable, current, secure, uninterrupted, always available, or error-free, or will meet
          Your requirements, that any defects in the Application will be corrected, or that the Application
          is free of viruses or other harmful components. We disclaim liability for, and no warranty is made
          with respect to, connectivity and availability of the Application or Services.
        </p>
        <p className={classes.description}>
          (t) This clause 10 shall survive termination of this Agreement.
        </p>
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(UserDisclaimersPage as any));

import { isArray, isNullOrUndefined, isNumber } from "util";
import { IGeoLocation } from "../interfaces/GeoLocationInterface";
import { IPricing } from "../interfaces/PricingInterface";
import { IRateCard } from "../interfaces/RateInterface";
import { IRentSpaceRequest } from "../interfaces/RentSpaceInterface";
import { IServiceOrder } from "../interfaces/ServiceOrderInterface";
import { StatusEnum } from "../literals/Enums";
import { StatusType } from "../literals/Types";
import { IAuthError } from "../redux/AuthRedux";

export const isAddressValid = (request: IRentSpaceRequest) => (
  request && !isNullOrUndefined(request.Address) && request.Address !== ""
);

export const isAmenityValid = (request: IRentSpaceRequest) => (
  isNullOrUndefined(request.Amenities) || request.Amenities.indexOf("cctv") > -1
    || request.Amenities.indexOf("covered") > -1 || request.Amenities.indexOf("fenced") > -1
);

export function isArrayEmpty(obj: any | undefined): boolean {
  return isNullOrUndefined(obj) || !isArray(obj) || obj.length <= 0;
}

export function isAuthError(obj: any | undefined): obj is IAuthError {
  return !isNullOrUndefined(obj) && isStringValid(obj.code) && isStringValid(obj.message);
}

export const isGeoLocation = (obj: any | undefined): obj is IGeoLocation => (
  obj && !isNullOrUndefined(obj) && isNumber(obj.latitude)
    && isNumber(obj.longitude)
    && (isNullOrUndefined(obj.heading) || isNumber(obj.heading))
);

export const isLocationValid = (request: any | undefined): boolean => (
  request && !isNullOrUndefined(request.Location) && isNumber(request.Location.latitude)
    && isNumber(request.Location.longitude)
);

export const isNameValid = (request: IRentSpaceRequest) => (
  request && !isNullOrUndefined(request.Name) && request.Name !== ""
);

export const isNameValid1 = (name?: string | null) => (
  !isNullOrUndefined(name) && !isNullOrUndefined(name) && name !== ""
);

export function isNumberNotZero(obj: any | undefined): obj is number {
  return !isNullOrUndefined(obj) && isNumber(obj) && obj > 0;
}

export function isOrderUidShortValid(obj: any | undefined): boolean {
  return !isNullOrUndefined(obj) && obj.length === 6;
}

export const isOwnerTypeValid = (request: IRentSpaceRequest) => (
  request && !isNullOrUndefined(request.PropertyRights) && (
    request.PropertyRights === "owner" || request.PropertyRights === "tenant"
  )
);

export const isParkingSpaceValid = (request: IRentSpaceRequest) => (
  request && !isNullOrUndefined(request.ParkingSpaces) && request.ParkingSpaces > 0
);

export const isPhoneNumberValid = (request: IRentSpaceRequest) => (
  request && !isNullOrUndefined(request.PhoneNumber) && !isNaN(request.PhoneNumber) &&
  request.PhoneNumber.toString().length >= 10
);

export const isPhoneNumberValid1 = (phone: number | null): boolean => (
  !isNullOrUndefined(phone) && !isNaN(phone) &&  phone.toString().length >= 10
);

export function isPricing(obj?: any): obj is IPricing {
  return !isNullOrUndefined(obj) && isStringValid(obj.ValidFrom)
    && isNumber(obj.GSTPercentage)
    && !isArrayEmpty(obj.ParkingCharges) && obj.ParkingCharges.every(isRateCard)
    && isStatusType(obj.Status)
    && (isNullOrUndefined(obj.ValetFee) || isNumber(obj.ValetFee));
}

export function isRateCard(obj: any | undefined): obj is IRateCard {
  return !isNullOrUndefined(obj) && isNumberNotZero(obj.duration) && isNumberNotZero(obj.fare);
}

export const isRentRequestValid = (request: IRentSpaceRequest) => {
  // tslint:disable-next-line:no-console
  console.log(JSON.stringify({ isAddressValid: isAddressValid(request), isAmenityValid: isAmenityValid(request),
    isLocationValid: isLocationValid(request), isNameValid: isNameValid(request),
    isOwnerTypeValid: isOwnerTypeValid(request),
    isParkingSpaceValid: isParkingSpaceValid(request), isPhoneNumberValid: isPhoneNumberValid(request), request  }));
  return (request && isAddressValid(request) && isLocationValid(request)
      && isNameValid(request) && isOwnerTypeValid(request) && isParkingSpaceValid(request)
      && isPhoneNumberValid(request) && isAmenityValid(request)
    );
};

export function isServiceOrderActive(obj: IServiceOrder): boolean {
  return !isNullOrUndefined(obj) && obj.Status === StatusEnum.Active;
}

export function isServiceOrderCompleted(obj: IServiceOrder): boolean {
  return !isNullOrUndefined(obj) && obj.Status === StatusEnum.Completed;
}

export function isStatusType(obj: any | undefined): obj is StatusType {
  return !isNullOrUndefined(obj)
            && (obj === "Active" || obj === "Cancelled" || obj === "Completed" || obj === "Rejected");
}

export function isStringValid(obj: any | undefined): obj is string {
  return !isNullOrUndefined(obj) && ((obj as string).replace(" ", "") !== "");
}

export function isVehicleRegValid(obj?: string | null): boolean {
  const regResult = new RegExp("^[A-Z]{2}[ -]?[0-9]{1,2}(?:[ ]?[A-Z])?(?:[ ]?[A-Z]*)?[ ]?[0-9]{1,4}$", "g");
  const exec = !isNullOrUndefined(obj) ? regResult.exec(obj) : null;
  return !isNullOrUndefined(exec);
}

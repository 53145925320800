// tslint:disable: object-literal-sort-keys

import { container, grayColor, title } from "../../../themes/jss/material-kit-react";

const tncPageStyle = {
  container: {
    zIndex: "12",
    ...container
  },
  center: {
    textAlign: "center",
  },
  description: {
    color: grayColor,
    overflow: "hidden",
    marginTop: "0px",
    fontSize: "14px"
  },
  main: {
    background: "#FFFFFF",
    marginTop: 90,
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  mainTitle: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  paperBase: {
    borderColor: "rgba(0, 0, 0, 0)"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px autos 0"
  },
  subDescription: {
    color: grayColor,
    fontSize: "14px",
    marginLeft: "16px",
    marginTop: "0px",
    overflow: "hidden",
  },
  title
};

export default tncPageStyle;

import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";
import { IGoBumprPackage } from "../interfaces/GoBumprPackageInterface";

const { Types, Creators } = createActions({
  saveLead: ["data"],
  setIsFetching: ["isFetching"],
  setIsSaveSuccessful: ["isSaveSuccessful"],
  setIsSaving: ["isSaving"],
  setSelectedPackage: ["selectedPackage"]
});

export const GoBumprLeadReduxTypes = Types;
export default Creators;

export interface IGoBumprLeadReduxState {
  isFetching: boolean;
  isSaveSuccessful: boolean;
  isSaving: boolean;
  selectedPackage: IGoBumprPackage | null;
}

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  isFetching: false,
  isSaveSuccessful: false,
  isSaving: false,
  selectedPackage: null,
} as IGoBumprLeadReduxState);

/* ------------- Reducers ------------- */
export const setIsFetching = (state: Immutable.ImmutableObject<IGoBumprLeadReduxState>,
                              { isFetching }: {isFetching: boolean}) => (
state.merge({ isFetching })
);

export const setIsSaveSuccessful = (state: Immutable.ImmutableObject<IGoBumprLeadReduxState>,
                                    { isSaveSuccessful }: {isSaveSuccessful: boolean}) => (
  state.merge({ isSaveSuccessful })
);

export const setIsSaving = (state: Immutable.ImmutableObject<IGoBumprLeadReduxState>,
                            { isSaving }: {isSaving: boolean}) => (
  state.merge({ isSaving })
);

export const setSelectedPackage = (state: Immutable.ImmutableObject<IGoBumprLeadReduxState>,
                                   { selectedPackage }: {selectedPackage: IGoBumprPackage | null}) => (
  state.merge({ selectedPackage })
);

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<Immutable.ImmutableObject<IGoBumprLeadReduxState>, any>(INITIAL_STATE, {
  [Types.SET_IS_FETCHING]: setIsFetching,
  [Types.SET_IS_SAVE_SUCCESSFUL]: setIsSaveSuccessful,
  [Types.SET_IS_SAVING]: setIsSaving,
  [Types.SET_SELECTED_PACKAGE]: setSelectedPackage,
});

import { withStyles } from "@material-ui/core";
import React from "react";
import withRoot from "../../../../WithRoot";
import termsAndConditionsPageStyle from "../../styles/TermsAndConditionsStyle";

interface IUserLiabilityProps {
  classes?: any;
}
class UserLiabilityPage extends React.Component<IUserLiabilityProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4 className={classes.title}>12. Limitation of Liability</h4>
        <p className={classes.description}>
          (a) In no circumstances whatsoever shall WannaPark.in be liable to you for any:
          <p className={classes.subDescription}>
            (i) lost profits;
          </p>
          <p className={classes.subDescription}>
            (ii) loss of data;
          </p>
          <p className={classes.subDescription}>
            (iii) any claim or demand against you by the Owner, unless as a result of our negligence
            or breach of contract;
          </p>
          <p className={classes.subDescription}>
            (iv) any loss arising from or connected with the actions of the Owner, including if the Parking Space
            is unavailable, any damage caused to your vehicle by the Owner, or any personal injury suffered by you
            or any other person whilst using the Parking Space;
          </p>
          <p className={classes.subDescription}>
            (v) any loss or damage which is not foreseeable. Loss or damage is foreseeable if they were an obvious
            consequence of our breach or if they were contemplated by both of us at the time we entered into
            this Agreement;
          </p>
          <p className={classes.subDescription}>
            (vi) errors, mistakes, or inaccuracies of the Site or Service;
          </p>
          <p className={classes.subDescription}>
            (vii) personal injury or property damage of any nature whatsoever resulting from your access to and use
            of the Site or Service or any Booking with the Owner;
          </p>
          <p className={classes.subDescription}>
            (viii) any unauthorised access to or use of our secure servers and/or any and all personal, institutional,
            technical or other information stored therein;
          </p>
          <p className={classes.subDescription}>
            (ix) any interruption or cessation of transmission to or from the Site; or
          </p>
          <p className={classes.subDescription}>
            (x) any bugs, viruses, Trojan horses, or the like, which may be transmitted to or through the Site by
            any third party.
          </p>
        </p>
        <p className={classes.description}>
          (b) Our maximum liability to you in respect of any claim under or in connection with this Agreement,
          howsoever arising, shall be limited to the aggregate of all fees paid by you for all Bookings you have
          made using our Site in the 12 months immediately prior to the date upon which the act, error or omission
          giving rise to the claim occurred or (as the case may be) first occurred
        </p>
        <p className={classes.description}>
          (c) Nothing in this agreement limits or excludes our liability for death or personal injury arising as a
          result of our negligence or the negligence of our employees, agents or self-employed contractors or for
          fraud or fraudulent misrepresentation, or for any other liability which cannot be excluded
          as a matter of law.
        </p>
        <p className={classes.description}>
          (d) This clause 12 shall survive the termination of this Agreement.
        </p>
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(UserLiabilityPage as any));

import { withStyles } from "@material-ui/core";
import React from "react";
import withRoot from "../../../../WithRoot";
import termsAndConditionsPageStyle from "../../styles/TermsAndConditionsStyle";

interface IApprovalsProps {
  classes?: any;
}
class ApprovalsPage extends React.Component<IApprovalsProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <h4 className={classes.title}>6. Approvals</h4>
        <p className={classes.description}>
        (a) You represent and warrant that you have all necessary regulatory and planning approvals
        and insurances to grant a licence and charge for the use of the Parking Space and that the
        licence to use the Parking Space will comply with all applicable laws, tax requirements and
        rules and regulations that may apply to the Parking Space, including but not limited to
        zoning laws and laws governing rental of or licences to use residential and other properties.
        </p>
        <p className={classes.description}>
        (b) You agree that we shall not be liable to you in any way whatsoever if you suffer any loss
        as a result of any governmental authority or any other relevant public authority bringing proceedings
        against you or taking any other action against you as a result of your Advert on the Site.
        </p>
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(ApprovalsPage as any));

import { Typography, withStyles } from "@material-ui/core";
import classNames from "clsx";
import React from "react";
import { LinksEnum } from "../../literals/Enums";
import withRoot from "../../WithRoot";
import Approvals from "./Sections/Owner/Approvals";
import BookingsPayments from "./Sections/Owner/BookingsPayments";
import Cancellation from "./Sections/Owner/Cancellation";
import ComplaintsDisputes from "./Sections/Owner/ComplaintsDisputes";
import ListingServices from "./Sections/Owner/ListingServices";
import OurLiability from "./Sections/Owner/OurLiability";
import OwnerTerms from "./Sections/Owner/OwnerTerms";
import ParkingSpace from "./Sections/Owner/ParkingSpace";
import RestrictionsOnYou from "./Sections/Owner/RestrictionsOnYou";
import Termination from "./Sections/Owner/Termination";
import YourLiability from "./Sections/Owner/YourLiability";
import YourObligations from "./Sections/Owner/YourObligations";
import termsAndConditionsPageStyle from "./styles/TermsAndConditionsStyle";

interface IOwnerAgreementProps {
  classes?: any;
}
class OwnerAgreementPage extends React.Component<IOwnerAgreementProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <Typography className={classes.mainTitle} variant="h5" component="h3">
            Owner Agreement
          </Typography>
          <Typography component="p">
            This Agreement sets out the terms and conditions (“the Terms”) that apply to the service provided
            directly by WannaPark Technologies Private Limited (trading as www.wannapark.in) to you namely allowing
            you to advertise a parking space and accepting and processing bookings for that parking space
            (“the Service”). This Agreement should be read in conjunction with the relevant Agreements,
            <a href={LinksEnum.terms}> Terms and Conditions </a> and
            <a href={LinksEnum.privacyPolicy}> Privacy Policy</a> on this Website (“the Site”)
            and any additional terms referred to herein. By using the Service you are agreeing to abide by the
            Terms as set out below. If you do not accept these Terms do not use the Service.
          </Typography>
          <OwnerTerms />
          <ListingServices />
          <BookingsPayments />
          <Cancellation />
          <ParkingSpace />
          <Approvals />
          <YourObligations />
          <ComplaintsDisputes />
          <Termination />
          <YourLiability />
          <OurLiability />
          <RestrictionsOnYou />
          <br />
        </div>
      </div>
    );
  }
}

export default withRoot(withStyles(termsAndConditionsPageStyle as any)(OwnerAgreementPage as any));

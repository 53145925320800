import { map } from "ramda";
import { channel } from "redux-saga";
import { call, put, select, take } from "redux-saga/effects";
import { isNullOrUndefined } from "util";
import firebase from "../config/FirebaseConfig";
import { IServiceOrder } from "../interfaces/ServiceOrderInterface";
import * as Selectors from "../redux/Selectors";
import TrackCarActions from "../redux/TrackCarRedux";
import { debugLog } from "../services/LogService";

const trackingChannel = channel();

export function * trackCarLocationChannel() {
  while (true) {
    const action = yield take(trackingChannel);
    yield put(action);
  }
}

export function * trackCar(payload: any) {
  try {
    debugLog({class: "TrackCarSaga", method: "trackCar", payload});
    const firestoreDB = firebase.firestore();
    if (payload.orderUid) {
      yield call(unTrackCar);
      yield put(TrackCarActions.setTrackingError(null));
      yield put(TrackCarActions.setServiceOrder(null));
      yield put(TrackCarActions.setIsLoading(true));
      const orderSnapshot: firebase.firestore.DocumentSnapshot = yield firestoreDB.collection("ServiceOrders")
                                                                                      .doc(payload.orderUid).get();
      const serviceOrder = orderSnapshot.exists ? orderSnapshot.data() : null;
      if (serviceOrder && Object.keys(serviceOrder).length > 0) {
        debugLog({class: "TrackCarSaga", method: "trackCarIN", serviceOrder});
        if (serviceOrder) {
          yield put(TrackCarActions.setServiceOrder(serviceOrder));
        }
        yield put(TrackCarActions.setIsLoading(false));
        yield put(TrackCarActions.setIsTracking(true));
        const subscriber = firestoreDB.collection("ServiceOrders").doc(serviceOrder.Uid)
          .onSnapshot((doc: firebase.firestore.DocumentSnapshot) => {
            const data = doc.data();
            debugLog({class: "TrackCarSaga", method: "onTrackCarSnapshot", value: {data}});
            if (data && data.CarLocation) {
              trackingChannel.put(TrackCarActions.setServiceOrder(data));
            }
          }, (error: Error) => {
            debugLog({class: "TrackCarSaga", method: "onTrackCarSnapshot", error});
            trackingChannel.put(TrackCarActions.setIsTracking(false));
          });
        yield put(TrackCarActions.setSnapshotTracker(subscriber));
      }
    }
  } catch (ex) {
    if (!isNullOrUndefined(ex) && !ex.IsSuccess && !isNullOrUndefined(ex.Error)) {
      yield put(TrackCarActions.setLocationError(ex));
    }
    yield put(TrackCarActions.setIsLoading(false));
    yield put(TrackCarActions.setIsTracking(false));
    debugLog({class: "TrackCarSaga", method: "trackCarException", value: {ex}});
  }
}

export function * trackCarByUid(payload: any) {
  try {
    debugLog({class: "TrackCarSaga", method: "trackCarByUid", payload});
    if (payload.orderUidShort) {
      const firestoreDB = firebase.firestore();
      yield call(unTrackCar);
      yield put(TrackCarActions.setTrackingError(null));
      yield put(TrackCarActions.setServiceOrder(null));
      yield put(TrackCarActions.setIsLoading(true));
      const orderSnapshot: firebase.firestore.QuerySnapshot = yield firestoreDB.collection("ServiceOrders")
                                              .where("UidShort", "==", payload.orderUidShort).get();
      const serviceOrders = orderSnapshot
                            ? map((snapshot) => (snapshot.data() || {}) as IServiceOrder, orderSnapshot.docs) : [];
      if (serviceOrders && serviceOrders.length > 0) {
        const serviceOrder = serviceOrders[0];
        debugLog({class: "TrackCarSaga", method: "trackCarByUidIN", serviceOrder});
        if (serviceOrder) {
          yield put(TrackCarActions.setServiceOrder(serviceOrder));
        }
        yield put(TrackCarActions.setIsLoading(false));
        yield put(TrackCarActions.setIsTracking(true));
        const subscriber = firestoreDB.collection("ServiceOrders").doc(serviceOrder.Uid)
          .onSnapshot((doc: firebase.firestore.DocumentSnapshot) => {
            const data = doc.data();
            debugLog({class: "TrackCarSaga", method: "onTrackCarByUidSnapshot", value: {data}});
            if (data) {
              trackingChannel.put(TrackCarActions.setServiceOrder(data));
            }
          }, (error: Error) => {
            debugLog({class: "TrackCarSaga", method: "onTrackCarByUidSnapshot", error});
            trackingChannel.put(TrackCarActions.setIsTracking(false));
          });
        yield put(TrackCarActions.setSnapshotTracker(subscriber));
      } else {
        debugLog({class: "TrackCarSaga", method: "trackCarByUidOUT", value: {serviceOrders}});
        yield put(TrackCarActions.setServiceOrder(null));
        yield put(TrackCarActions.setIsLoading(false));
      }
    }
  } catch (ex) {
    if (!isNullOrUndefined(ex) && !ex.IsSuccess && !isNullOrUndefined(ex.Error)) {
      yield put(TrackCarActions.setLocationError(ex));
    }
    yield put(TrackCarActions.setIsLoading(false));
    yield put(TrackCarActions.setIsTracking(false));
    debugLog({class: "TrackCarSaga", method: "trackCarByUidException", value: {ex}});
  }
}

export function * unTrackCar() {
  try {
    const carTracker = yield select(Selectors.carTracker);
    debugLog({name: "TrackCarSaga", preview: "unTrackCar", value: {carTracker}});
    if (!isNullOrUndefined(carTracker)) {
      yield call(carTracker);
    }
    yield put(TrackCarActions.setIsTracking(false));
  } catch (ex) {
    yield put(TrackCarActions.setIsTracking(false));
    debugLog({class: "TrackCarSaga", method: "unTrackCar", ex});
  }
}
